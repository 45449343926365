import { sendRequest } from 'utils/utils'

const resetPushNotificationsDeclineDate = () => localStorage.setItem('pushNotificationsDeclineDate', '')
const setPushNotificationsDeclineDate = () => localStorage.setItem('pushNotificationsDeclineDate', String(+new Date()))
const getPushNotificationsDeclineDate = () => localStorage.getItem('pushNotificationsDeclineDate')

const setPushNotificationsStatus = (value: string) => localStorage.setItem('pushNotificationsStatus', value)
const getPushNotificationsStatus = () => localStorage.getItem('pushNotificationsStatus')

const setPushNotificationsToken = (value: string) => localStorage.setItem('pushNotificationsToken', value)
const getPushNotificationsToken = () => localStorage.getItem('pushNotificationsToken')

const getSessionId = () => localStorage.getItem('sessionId')

const sendRequestDependency = sendRequest

export default {
	resetPushNotificationsDeclineDate,
	setPushNotificationsDeclineDate,
	getPushNotificationsDeclineDate,
	setPushNotificationsStatus,
	getPushNotificationsStatus,
	setPushNotificationsToken,
	getPushNotificationsToken,
	getSessionId,
	sendRequestDependency,
}
