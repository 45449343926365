// @ts-nocheck
import React from 'react'
import Typography from '@material-ui/core/Typography'
import InfoIcon from '@material-ui/icons/Info'
import styled from 'styled-components'

import { getTranslatedTextByKey } from 'utils/utils'
import { sendCustomEvent } from 'utils/analytics/analytics'

const StyledWarning = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`

const StyledWarningTitle = styled.span`
	margin-inline-start: 5px;
`

export const itemNotFoundPopUp = (Infra) => {
	const _title = getTranslatedTextByKey('webviewFlow.itemNotFound')
	const _message = getTranslatedTextByKey('webviewFlow.selectedDealIsNotAvailable')

	sendCustomEvent({
		category: 'error',
		action: 'notification',
		label: _title,
		message: _message,
	})

	Infra.setNotification({
		title: (
			<StyledWarning>
				<InfoIcon key="1" color="secondary" />
				<StyledWarningTitle key="2">{_title}</StyledWarningTitle>
			</StyledWarning>
		),
		message: (
			<Typography variant="caption">
				<span key="1">{_message} </span>
			</Typography>
		),
		okAction: () => {
			history.replaceState(null, null, ' ')
			Infra.closeNotification()
		},
	})
}
