// @ts-nocheck
import { observable, action, computed, toJS } from 'mobx'
import Big from 'big.js'
import { debugMobX } from 'utils/utils'
import calculateTotalCostFromVariations from 'utils/cartUtils/calculateTotalCostFromVariations'
import nestedProperty from 'nested-property'
import ItemAdditionsDependencies from './ItemAdditionsDependencies'

/**
 * Keep state for additions added to a menu item eg optional additions such as which drink, add-ons such as more fries
 */
class ItemAdditions {
	dependencies = null

	@observable itemId = 0

	/**
	 * An item addition can have an option of 'CHIPS' with the addition being 'REGULAR CHIPS' so we store this as follows:
	 *
	 * Where additions = {
	 *     <option-id_index> : {
	 *         <additionId>: {
	 *         		name: 'x',
	 *         		price: y,
	 *         		id: z
	 *         }
	 *     }
	 * }
	 *
	 * So it's an associative array of eg
	 * {
	 * 		<Twister 1 id _ index> : {
	 *     		<classic twister id> : {id, name, price}
	 * 		},
	 * 		<Twister 2 id _ index>: {
	 * 		    <sweet chili twister id> : {id, name, price}
	 * 		},
	 * 		<side id _ index>: {
	 * 			<actual option id> : {id, name, price}
	 * 		},
	 * 		// NB this is a varaint
	 * 		<chips id _ new index since is a new MenuItemPageSections component> : {
	 * 			<large chips id> :{id, name, price}
	 * 		}>
	 * 	}
	 *
	 *
	 * Eg
	 *		// 1st Twister option set to 'classic twister'
	 * 		'71d8677d-a2ba-f6d3-f196-194fb93bd754_0' : {
	 * 		 	   '8052575f-4101-76eb-0d52-35e981373ae3': {
	 * 		 	   		id:'8052575f-4101-76eb-0d52-35e981373ae3', name:'CLASSIC TWISTER', price:0}
	 * 		},

	 *
	 * 		// 2nd twister option set to 'classic twister'
	 * 		'7fe37486-e531-f311-8fcd-006a975ae55a_2' : {
	 * 			'8052575f-4101-76eb-0d52-35e981373ae3': {id:'8052575f-4101-76eb-0d52-35e981373ae3', name:'CLASSIC TWISTER', price:0}
	 * 		}
	 *
	 * 		// 1st Burger Add Extras To Your Burger > Extra Colonel Sauce BWP +$2.00
	 * 		'617bfc32-30c7-5c69-0997-adc1573d2d83_1' : {
	 * 		 	'de7c6897-188a-1900-783f-db4f9ec20eef': { id: ''de7c6897-188a-1900-783f-db4f9ec20eef', name: 'EXTRA COLONEL SAUCE', price 200}
	 * 		}
	 *
	 * 	Adding the index to the end of the key allows us to check the value stored for a specific option when the same option
	 * 	appears twice in an item (eg 2 twisters appear in item 7fe37486-e531-f311-8fcd-006a975ae55a, '2 TWISTERS & LARGE CHIPS'
	 *
	 * @type {{}}
	 */
	// @observable additions = {}
	// @observable total = 0
	total = 0

	/**
	 * Map of variation title name that consists of: {
	 *     	minNumAllorwed: integer - min. num of items that an be selected,
	 *     	maxNumAllowed: integer - max. num of items that can be selected,
			selectedVariationIds: Set of the selected variation-ids,
	 * },
	 * @type {{}}
	 */
	@observable requiredSelections = {}

	@observable allRequiredSelectionsProvided = true

	@observable additionsNew = {}

	/**
	 * A comment is provided as a menu-item and passed to the nodejs as an item!!! It's an object that has {text, itemid}
	 * - where itemId is optional and only used for a comment on an item NOT used for a comment on a payment
	 *
	 * @type {{}}
	 */
	@observable comment = { text: '', itemId: null }

	/**
	 * The customer can buy > 1 of the comination of additions that they have selected.
	 *
	 * @type {number}
	 */
	@observable quantity = 1

	/**
	 * An item can exist in multiple stores. In each store its 'id' can be different BUT it's en_ID will be the same across ALL stores
	 * @type {null}
	 */
	en_ID = null

	constructor(dependencies) {
		this.dependencies = dependencies
	}

	@action reset = (item) => {
		// this.additions = {}
		this.additionsNew = {}
		this.total = 0
		// this.totalNew = new Big(0)
		this.itemId = 0
		this.comment = { text: '', itemId: null }
		this.quantity = 1
		this.requiredSelections = {}
	}

	@action postInit = (item, cart = {}) => {
		this.total = JSON.parse(JSON.stringify(item.price))
		// this.totalNew = new Big(JSON.parse(JSON.stringify(item.price)))
		this.itemId = JSON.parse(JSON.stringify(item.id))
		this.en_ID = JSON.parse(JSON.stringify(item.description?.en_ID))
		// console.log(`postInit itemAdditions`)

		if (cart.editItem) {
			// editing an item
			const _editItem = JSON.parse(JSON.stringify(cart.items[item.id][cart.editCartItemIdx]))
			this.comment = _editItem.comment || { text: '', itemId: item.id }
			// this.additions = _editItem.additions
			this.additionsNew = _editItem.additionsNew
			this.quantity = _editItem.quantity
		}
	}

	/**
	 *
	 * @param parentIdIndexPath eg '<item-id>.variationsChoices.0'
	 * @param addition is the object of the addition being added eg large-chips
	 */
	@action addAdditionNew = (parentIdIndexPath, addition) => {
		// const _parentIdIndexPathElements = parentIdIndexPath.split('.')

		const _clone = JSON.parse(JSON.stringify(this.additionsNew))
		// parentIdIndexPath += `.${addition.id}`

		nestedProperty.set(_clone, `${parentIdIndexPath}.${addition.id}`, addition)

		this.additionsNew = _clone
		this.updateRequiredSelection(parentIdIndexPath, addition.id, false)

		// console.log('****** new json ******')
		// console.log(_clone)

		// const _quantity = addition.quantity || 1

		// console.log(`added cost: ${addition.price`)
		// this.totalNew = this.totalNew.add(new Big(addition.price).times(_quantity))

		debugMobX(this.additionsNew)
	}

	@action removeAdditionNew = (parentPath, additionId) => {
		const pathIsValid = nestedProperty.has(this.additionsNew, parentPath)
		if (pathIsValid) {
			const _clone = JSON.parse(JSON.stringify(this.additionsNew))
			const parent = nestedProperty.get(_clone, parentPath)
			if (parent && parent[additionId]) {
				// it's possible the parent doesn't exist for a variation that is required but has no default
				delete parent[additionId]

				this.additionsNew = _clone
				// console.log('************** deleted *************')
				console.log(_clone)
				debugMobX(this.additionsNew)

				// remove this parent option from the  'required' object
				this.removedRedundantItemParentsFromRequiredObj(`${parentPath}.${additionId}`)
			} else {
				console.log(`Addition Id: ${additionId} not present on this path so nothing to delete`)
			}

			this.updateRequiredSelection(parentPath, additionId, true)
		} else {
			console.log(`Parent path: ${parentPath} not present so nothing to remove`)
		}
	}

	@action getItem = () => {
		// debugMobX(this.additions)

		const _item = {
			itemId: this.itemId,
			// additions: this.additions,
			additionsNew: this.additionsNew,
			total: parseInt(this.totalCost),
			// totalNew: this.totalNew,
			price: this.total,
			quantity: this.quantity,
			en_ID: this.en_ID,
		}

		if (this.comment.itemId) {
			_item.comment = this.comment
		}

		// console.log(`***************** item cost: ${_item.total}`)

		return _item
	}

	/**
	 * A comment is configured as an item with the text 'TYPE INSTRUCTIONS' !! and it's passed via the /webview API
	 * to the nodejs server with its itemId!
	 *
	 * @param comment
	 * @param itemIds - associative array, take 1st element
	 */
	@action setComment = (comment, itemId) => {
		this.comment = { text: comment, itemId }
	}

	@action setQuantity = (quantity) => {
		this.quantity = quantity
	}

	@computed get numberOfAdditions() {
		return Object.keys(this.additions).length
	}

	/**
	 * Computed values can be used to derive information from other observables. They evaluate lazily, caching their
	 * output and only recomputing if one of the underlying observables has changed. If they are not observed by
	 * anything, they suspend entirely.
	 * - see https://mobx.js.org/computeds.html
	 *
	 * @returns {string}
	 */
	@computed get totalCost() {
		let bigTotal = new Big(this.total)
		if (this.additionsNew?.[this.itemId]) {
			bigTotal = calculateTotalCostFromVariations(toJS(this.additionsNew[this.itemId].variationsChoices), bigTotal)
		}

		const bigTotalWithQuantities = bigTotal.times(this.quantity)
		return bigTotalWithQuantities.toString() // formatter which returns a string
	}

	/**
	 * This is called when adding a MenuItemPageSection and there's no default selected variation and minNumAllowed > 0
	 *
	 * @param menuItemOptionTitle - is unique eg 'half-and-half-bc497cd6-079c-0e51-9959-537f40e19cf2.variationsChoices.0.var-hh-pizza-size-1-c2974066-575f-18b1-19a0-6ffb422e944d.variationsChoices.0.var-hh-pizza-type-1-3-cd881ac8-9b3f-3582-f95d-fcce531f0c5f.variationsChoices.0'
	 */
	@action addRequiredSelection = (menuItemOptionTitle, minNumAllowed, maxNumAllowed = 1, isQuantitySelection = false) => {
		// this.removedRedundantItemParentsFromRequiredObj(menuItemOptionTitle)

		if (this.requiredSelections[menuItemOptionTitle]) {
			// this may be called more than once ??
			// this.requiredSelections[menuItemOptionTitle].minNumAllowed++
			// this.requiredSelections[menuItemOptionTitle].maxNumAllowed++
			console.log('trying to add the same key again - why!?')
		} else {
			this.requiredSelections[menuItemOptionTitle] = {
				isQuantitySelection,
				minNumAllowed,
				maxNumAllowed,
				selectedVariationIds: new Set(), // contains the variations for this section
			}

			if (isQuantitySelection) {
				// set the max required number of variations for this Quantity Selection section (several related ItemQuantity components)
				this.requiredSelections[menuItemOptionTitle].requiredTotalVariationQuantity = maxNumAllowed
			}
		}
	}

	/**
	 * now remove any 'menuItemOptionTitle' (DOM Ids) that no longer exist in the DOM. This can happen eg
	 * 'Half & Half' (PH Lima) has a 1st variation: Grande, Familiar, Mediana.
	 * Each of these variations has their own required child variations. So if Grande is seleced then its child
	 * variations are added to the this.requiredSelections obj. Then Fammiliar is selected and its child variations
	 * are added to this.requiredSelections BUT Grande's child selections are NOT removed!! This is a bug.
	 * So we check now for any child variations' menuItemOptionTitle DOM Ids in the DOM, if they exist, they are still
	 * valid. If not, they are removed from this obj.
	 *
	 * Problem with this solution is that the this code runs BEFORE the DOM has rendered! Evevn though it's called
	 * from a useEffect onMount!
	 *
	 * So instead I just check if the existing parentIds are contained within the new parentId. If they are, they are
	 * kept in the obj.
	 *
	 * */
	@action removedRedundantItemParentsFromRequiredObj = (menuItemOptionTitleToDelete) => {
		for (const _key in this.requiredSelections) {
			if (_key.includes(menuItemOptionTitleToDelete)) {
				delete this.requiredSelections[_key]
				console.log(`deleted menu item option titled '${_key} due to the user unselecting this variation'`)
			}
		}
	}

	/**
	 * The requiredSeelections can have a key of eg '7ba2f27a-cb23-7372-22f3-1adee4fb22d3.variationsChoices.2.8a1273b3-6ede-de77-aa29-d75e9b6b050b.variationsChoices.0'
	 * So if the parentId does not match the stored key, combine the parnetId.requiredSelectionId and see if this matches part of the stored key, if so, delete it.
	 *
	 * @param parentId eg '7ba2f27a-cb23-7372-22f3-1adee4fb22d3.variationsChoices.2'
	 * @param requiredSelectionId eg '8a1273b3-6ede-de77-aa29-d75e9b6b050b'
	 * @param remove
	 */
	@action updateRequiredSelection = (parentId, requiredSelectionId, remove) => {
		// 1. see if the parentId exists (there is a mandatory variation that needs a selected option) and the user has
		// removed a mandatory selection from its Set of selected options
		const _parentWithMandatoryChildrenOptions = this.requiredSelections[parentId]

		let _mandatoryParent = null

		if (!_parentWithMandatoryChildrenOptions) {
			// 2. see if the entire mandatory variation needs to be removed since the user replaced it with an option
			// that doesn't have any mandatory variations
			const _keyPrefix = `${parentId}.${requiredSelectionId}`
			for (const _fullKey in this.requiredSelections) {
				if (_fullKey.indexOf(_keyPrefix) > -1) {
					_mandatoryParent = _fullKey
				}
			}
		}

		if (_parentWithMandatoryChildrenOptions || _mandatoryParent) {
			if (remove) {
				if (_parentWithMandatoryChildrenOptions) {
					// 1. delete the mandatory option but leave the mandatory parent
					_parentWithMandatoryChildrenOptions.selectedVariationIds?.delete(requiredSelectionId)
				} else if (_mandatoryParent) {
					// 2. delete the entire mandatory parent since it's been swapped for an optional variation
					delete this.requiredSelections[_mandatoryParent]
				}
			} else {
				_parentWithMandatoryChildrenOptions?.selectedVariationIds?.add(requiredSelectionId)
			}

			// check if all required selections have been made
			const _allRequiredSelectionsProvided = this.validateAllRequiredSelectionsProvided

			if (_allRequiredSelectionsProvided.invalidIds.length === 0) {
				// there are no mandatory sections since the user unselected the last mandatory section
				const _variation = document.getElementById(parentId)
				const _parentHtmlElemById = _variation.closest('.required')
				// const _parentHtmlElemById = _variation.closest('.menuItemPageSection.root')

				if (_parentHtmlElemById) {
					// there is a highlighted mandatory option in the page that can be unhighlighted so clear its invalid css styling
					_parentHtmlElemById.classList.remove('invalid')
				}
			}

			if (_allRequiredSelectionsProvided.validIds.length > 0) {
				// clear any 'invalid' styling now the selections have all been made
				for (let i = 0; i < _allRequiredSelectionsProvided.validIds.length; i++) {
					const _variation = document.getElementById(_allRequiredSelectionsProvided.validIds[i])

					if (_variation) {
						const _parentHtmlElemById = _variation.closest('.required')
						// const _parentHtmlElemById = _variation.closest('.menuItemPageSection.root')
						// const _parentHtmlElemById = document.getElementById(_allRequiredSelectionsProvided.validIds[i])

						if (_parentHtmlElemById) {
							// there were unselected options that are now selected so clear any unselected css styling
							_parentHtmlElemById.classList.remove('invalid')
						}
					} else {
						console.log(
							`valid variation with id: '${_allRequiredSelectionsProvided.validIds[i]}' does NOT exist in the DOM so may have been removed due to it belonging to a different parent variation that has been UNSELECTED`
						)
					}
				}
			}

			if (_allRequiredSelectionsProvided.invalidIds.length > 0) {
				// clear any 'invalid' styling now the selections have all been made
				for (let i = 0; i < _allRequiredSelectionsProvided.invalidIds.length; i++) {
					const _variation = document.getElementById(_allRequiredSelectionsProvided.invalidIds[i])

					if (_variation) {
						const _parentHtmlElemById = _variation.closest('.required')
						// const _parentHtmlElemById = _variation.closest('.menuItemPageSection.root')
						// const _parentHtmlElemById = document.getElementById(_allRequiredSelectionsProvided.invalidIds[i])

						if (_parentHtmlElemById) {
							// there were unselected options that are now selected so clear any unselected css styling
							_parentHtmlElemById.classList.add('invalid')
						}
					} else {
						console.log(
							`invalid variation with id: '${_allRequiredSelectionsProvided.invalidIds[i]}' does NOT exist in the DOM so may have been removed due to it belonging to a different parent variation that has been UNSELECTED`
						)
					}
				}
			}
		}
	}

	@computed get validateAllRequiredSelectionsProvided() {
		const validParentIds = []
		const invalidParentIds = []

		const _clone = JSON.parse(JSON.stringify(this.additionsNew))

		for (const parentPath in this.requiredSelections) {
			if (this.requiredSelections[parentPath].isQuantitySelection) {
				// quantity select
				const pathIsValid = nestedProperty.has(this.additionsNew, parentPath)
				if (pathIsValid) {
					const _parentWithMandatoryChildrenOptions = this.requiredSelections[parentPath]
					const _setAsArray = Array.from(_parentWithMandatoryChildrenOptions.selectedVariationIds)
					const _totalQuantitySelectedSoFar = _setAsArray.reduce((accumulator, _variationId) => {
						const parent = nestedProperty.get(_clone, parentPath)
						const _itemQuantityVariation = parent[_variationId]
						return accumulator + _itemQuantityVariation.quantity
					}, 0)
					if (_totalQuantitySelectedSoFar === _parentWithMandatoryChildrenOptions.requiredTotalVariationQuantity) {
						validParentIds.push(parentPath)
					} else {
						invalidParentIds.push(parentPath)
					}
				} else {
					invalidParentIds.push(parentPath)
				}
			} else {
				// multi-select
				if (
					this.requiredSelections[parentPath].selectedVariationIds.size >= this.requiredSelections[parentPath].minNumAllowed &&
					this.requiredSelections[parentPath].selectedVariationIds.size <= this.requiredSelections[parentPath].maxNumAllowed
				) {
					// the user has selected the required number of variations so this parent/section/variation is now valid
					validParentIds.push(parentPath)
				} else {
					// return the key so the calling code can scroll to the relevant html's position
					invalidParentIds.push(parentPath)
				}
			}
		}

		const _response = {
			validIds: validParentIds,
			invalidIds: invalidParentIds,
		}

		return _response
	}

	getNutrientsInformation = (nutrientId) => this.dependencies.getNutrientsInformation(nutrientId)
}

export default new ItemAdditions(ItemAdditionsDependencies)
