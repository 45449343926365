// @ts-nocheck
import reactNativeComms from '../../reactNativeComms'

export async function init(params) {
	console.warn(`TODO - pass params to native app...`)
}

export function sendCustomEvent(data) {
	// send the data as-is to the native layer and the analytics SDK will handle mapping the data to its requried format
	reactNativeComms.sendMessage.sendAnalyticsEvent('CUSTOM_EVENT', 'N/A', data)
}

export function sendEnhancedEcommerceEvent(eventName, ...args) {
	// send the data as-is to the native layer and the analytics SDK will handle mapping the data to its requried format
	reactNativeComms.sendMessage.sendAnalyticsEvent('ENHANCED_ECOMMERCE_EVENT', eventName, ...args)
}

export function sendPageViewEvent(data) {
	// send the data as-is to the native layer and the analytics SDK will handle mapping the data to its requried format
	reactNativeComms.sendMessage.sendAnalyticsEvent('PAGE_VIEW_EVENT', 'N/A', data)
}
