// @ts-nocheck
import green from '@material-ui/core/colors/green'

// see https://material-ui.com/customization/default-theme/#default-theme for what can be overridden

const muiComponentTheme = (brandTheme) => ({
	palette: {
		primary: {
			main: brandTheme?.palette?.first,
			background: '#FFFFFF',
			anotherColor: brandTheme?.palette?.approved,
		},
		secondary: {
			main: '#0E731E',
		},
		neutral: {
			main: 'grey',
		},
	},
	props: {
		// Name of the component
		MuiButtonBase: {
			// The properties to apply
			disableRipple: true, // No more ripple, on the whole application
		},
	},
	overrides: {
		MuiIconButton: {
			root: {
				'&.Mui-disabled': {
					color: '#DBDBDB',
				},
			},
		},
		MuiFormLabel: {
			root: {
				fontSize: '14px',
				lineHeight: '0',
			},
		},
		MuiInputLabel: {
			outlined: {
				transform: 'translate(9px, 16px) scale(1);',
				'&.MuiInputLabel-shrink': {
					transform: 'translate(14px, 0px) scale(0.75);',
				},
			},
		},
		MuiOutlinedInput: {
			input: {
				padding: '6px',
				fontSize: '13px',
			},
		},
		// global override for TextField's label and bottom-border color.
		MuiTextField: {
			root: {
				'& .MuiFormLabel-root': {
					color: '#000000',
				},
				'& .MuiOutlinedInput-notchedOutline': {
					borderColor: '#000000',
				},
				'& label.Mui-focused': {
					color: '#000000',
				},
				'& .Mui-focused.Mui-error': {
					color: '#f44336', // red
				},
				'& .MuiInput-underline:after': {
					borderBottomColor: 'green',
				},
				'& .MuiInput-underline.Mui-error:after': {
					borderBottomColor: '#f44336', // red
				},
				// for variant='outlined' textfield
				'& .MuiOutlinedInput-root': {
					// fontSize: '12px',
					/* '& fieldset': {
                            borderColor: 'red',    //normal outline (not focussed)
                         }, */
					/* '&:hover fieldset': {
                            borderColor: 'yellow',
                         }, */
					'&.Mui-focused.Mui-error fieldset': {
						borderColor: '#f44336', // red outline
					},
				},
				'& .Mui-focused': {
					'& .MuiOutlinedInput-notchedOutline': {
						borderColor: '#000000',
					},
				},
				'&:hover': {
					// '& .MuiOutlinedInput-notchedOutline': {
					// 	borderColor: '#000000',
					// },
					'& label': {
						color: '#000000',
					},
				},
			},
		},
	},
	/*		typography: {
			fontFamily: "'Work Sans', sans-serif;",
			subtitle2: {
				fontWeight: 700,
				color: 'black',
				lineHeight: 1.1,
			},
			caption: {
				lineHeight: 1.2,
				fontSize: '14px',
			},
		}, */
})

export default muiComponentTheme
