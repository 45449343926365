const getTestModeFlag = (flag: string) => sessionStorage.getItem(flag)

const setTestModeFlag = (flag: string, value: any) => sessionStorage.setItem(flag, value.toString())

const getTranslationsFlag = () => localStorage.getItem('translate')

export default {
	getTestModeFlag,
	setTestModeFlag,
	getTranslationsFlag,
}
