const android = 'android'
const ios = 'ios'

export const getMobileOperatingSystem = () => {
	const userAgent = navigator.userAgent || window.opera
	if (/android/i.test(userAgent)) {
		return android
	}

	if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
		return ios
	}

	return 'unknown'
}

export const isValidVersion = (versionString: string) => /^\d+\.\d+\.\d+$/.test(versionString)

// It returns vA < vB
export const applicationRequiresUpdate = (currentVersion: string, minimalRequiredVersion: string) => {
	try {
		if (!isValidVersion(currentVersion) || !isValidVersion(minimalRequiredVersion)) {
			throw new Error(`Invalid version strings, got ${currentVersion} and ${minimalRequiredVersion}`)
		}

		const currentVersionParts = currentVersion.split('.')
		const minimalRequiredVersionParts = minimalRequiredVersion.split('.')

		for (let i = 0; i < currentVersionParts.length; i += 1) {
			const currentPart = Number(currentVersionParts[i])
			const minimalPart = Number(minimalRequiredVersionParts[i])

			if (Number.isNaN(currentPart) || Number.isNaN(minimalPart)) {
				throw new Error('Invalid version strings')
			}

			if (currentPart !== minimalPart) {
				return currentPart < minimalPart
			}
		}

		return false
	} catch (error) {
		console.error('Error while comparing versions:', error)
		return false
	}
}

// It returns vA >= vB
export const showUpdateLinkFromVersionButton = (version: string, showUpdateLinkFromVersion: string): boolean =>
	!applicationRequiresUpdate(version, showUpdateLinkFromVersion)
