// Store the access token in localStorage
const getNamespaceKey = (namespace: string) => (key: string) => `${namespace}${namespace ? '-' : ''}${key}`

export const storage = (namespace = '') => ({
	getKey: getNamespaceKey(namespace),
	set(key: string, value: unknown) {
		localStorage.setItem(this.getKey(key), JSON.stringify(value))
	},
	remove(key: string) {
		localStorage.removeItem(this.getKey(key))
	},
	get(key: string) {
		try {
			const value: string | null = localStorage.getItem(this.getKey(key))
			return value ? JSON.parse(value) : null
		} catch (error) {
			return null
		}
	},
})

export default storage()
