// @ts-nocheck
import React, { forwardRef } from 'react'
import styled from 'styled-components'
import CardContent from './CardContent'
import { getStorePath } from '.'
import ButtonBase from 'components/common/ButtonBase'
import { getStoreStatus, getOrderActionText } from 'utils/availabilityUtils'
import { STORE_STATUS } from 'utils/constants'
import { getLocaleStr } from 'utils/utils'
import { getUserLanguage } from 'utils/language'

const Container = styled.a`
	background-color: var(--clear);
	box-shadow: var(--defaultShadow1);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 15px 10px;
	border-radius: var(--borderRadiusFrame);
`

const ButtonContainer = styled.div`
	cursor: auto;
	margin-top: 20px;
`

const CardContentContainer = styled.div`
	cursor: ${({ $isClickable }) => ($isClickable ? 'pointer' : 'auto')};
`

const Card = forwardRef(
	({ data, locale, className, onSelectStore, rank, isNearbyStores, showButton = true, hasStores, buttonProps, testId }, ref) => {
		const storeStatus = getStoreStatus(data.availability)
		const orderButtonText = getOrderActionText(storeStatus)
		const lang = getUserLanguage()
		const storeName = getLocaleStr(data.title, locale)

		return (
			<Container href={getStorePath(lang, storeName)} onClick={(e) => e.preventDefault()} className={className} ref={ref} data-testid={testId}>
				<CardContentContainer
					onClick={onSelectStore ? () => onSelectStore(data) : null}
					$isClickable={!!onSelectStore}
					role={onSelectStore ? 'button' : ''}
				>
					<CardContent data={data} locale={locale} rank={rank} isNearbyStores={isNearbyStores} hasStores={hasStores} />
				</CardContentContainer>
				{showButton && (
					<ButtonContainer>
						<ButtonBase {...buttonProps} $type={storeStatus === STORE_STATUS.UNAVAILABLE ? 'secondary' : 'primary'}>
							{orderButtonText}
						</ButtonBase>
					</ButtonContainer>
				)}
			</Container>
		)
	}
)

export default Card
