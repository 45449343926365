import { get } from 'lodash-es'
import User from 'mobx/User'
import oStorage from './o-storage'
import { CONSTANTS } from './constants'
import Infra from 'mobx/Infra'

export const localeToCode = {
	en_US: 'en',
	he_IL: 'iw',
	ar_IL: 'ar',
	ru_RU: 'ru',
	pt_PT: 'pt',
	es_ES: 'es',
	fr_FR: 'fr',
	pap_AW: 'pap',
	de_DE: 'de',
	fi_FI: 'fi',
} as const

export type LanguageLocale = keyof typeof localeToCode
export type LanguageCode = typeof localeToCode[LanguageLocale]
export interface LocaleObject {
	msg: LanguageLocale
	region: LanguageCode
	key?: string
}

export const codeToLocale: Record<LanguageCode, LanguageLocale> = {
	en: 'en_US',
	iw: 'he_IL',
	ar: 'ar_IL',
	ru: 'ru_RU',
	pt: 'pt_PT',
	es: 'es_ES',
	fr: 'fr_FR',
	pap: 'pap_AW',
	de: 'de_DE',
	fi: 'fi_FI',
} as const

export const localeToDir: Record<LanguageLocale, 'ltr' | 'rtl'> = {
	en_US: 'ltr',
	he_IL: 'rtl',
	ar_IL: 'rtl',
	ru_RU: 'ltr',
	pt_PT: 'ltr',
	es_ES: 'ltr',
	fr_FR: 'ltr',
	pap_AW: 'ltr',
	de_DE: 'ltr',
	fi_FI: 'ltr',
} as const

// This can be used to get values from an object mapped by language codes or locales:   e.g. { en: <value>, de: <value, es: <value> }
export const getValueByLang = <T extends string>(map: Partial<Record<T, string>>, locale: T) =>
	map?.[locale] || map?.[(Object.keys(map ?? {}) as T[])[0]] || ''

export const getLocaleStr = (map: Partial<Record<LanguageLocale, string>>, locale: LanguageLocale) => getValueByLang<LanguageLocale>(map, locale)

const placeholderRegex = /{([\w$]*)}/g
const objectPlaceholderRegex = /{{([\w$]*)}}/g

/**
 * This function formats a string by replacing placeholders with values from the arguments list. The placeholders are defined using curly braces {}, and can optionally specify an index (e.g `{0}`) to pick a specific argument from the list to be used.
 * @example
 * const str = "Hello {0}, today is {1}."
 * const formatted = format(str, "world", "Monday")
 * console.log(formatted) // Output: "Hello world, today is Monday."
 *
 * @param str The string to be formatted.
 * @param args The list of arguments to replace the placeholders in the string.
 * @returns {string} The formatted string.
 */
export const format = (str: string, ...args: unknown[]): string => {
	let counter = 0
	return str.replace(placeholderRegex, (match, key) => {
		const parsed = parseInt(key, 10)
		const index = Number.isNaN(parsed) ? counter : parsed
		counter += 1

		return index < args.length ? `${args[index]}` : match
	})
}

/**
 * This function formats a string by replacing placeholders with values from an object. The placeholders are defined using double curly braces {{}}, and must specify a key in them (e.g. {{name}}).
 * @example
 * const str = "Hello {{name}}, today is {{day}}."
 * const args = { name: "world", day: "Monday" }
 * const formatted = formatWithObject(str, args)
 * console.log(formatted) // Output: "Hello world, today is Monday."
 *
 * @param {string} str The string to be formatted.
 * @param {Record<string, unknown>} args The object containing key-value pairs to replace the placeholders in the string.
 * @returns {string} The formatted string.
 */
export const formatWithObject = (str: string, args: Record<string, unknown>): string =>
	str.replace(objectPlaceholderRegex, (match, key) => (Object.keys(args).includes(key) ? `${args[key]}` : match))

/**
 * get the translated text by providing the key path as string,
 * @param path (string) - the path of the key that needs to be provided as string
 * @param fallback (string) - a string to return in case the key path was not found
 * @param template an object that contains translation for template keys (e.g. {{variable}})
 * @returns string
 *
 * @example
 * const key = 'eCommerce.message.helloWorld'
 * const invalidKey = 'blabla'
 * const templateKey = 'eCommerce.template.welcome'
 *
 * // Get translation for valid key;
 * getTranslatedTextByKey(key) // "Hello world"
 *
 * // Get translation for valid key;
 * getTranslatedTextByKey(invalidKey) // undefined
 * // Use fallback in case no translation exists
 * getTranslatedTextByKey(invalidKey, 'fallback translation') // "fallback translation"
 *
 * // Get translation for translation with a template:
 * getTranslatedTextByKey(templateKey) // "Hey, {{name}}. Welcome to eCommerce!"
 * getTranslatedTextByKey(templateKey, 'fallback translation') // "Hey, {{name}}. Welcome to eCommerce!"
 * // We can pass an object that defines what the template variable should be replaced with
 * getTranslatedTextByKey(templateKey, 'fallback translation', { name: 'David' }) // "Hey, David. Welcome to eCommerce!"
 * // This works for fallback as well!
 * getTranslatedTextByKey(invalidKey, 'Hello, {{name}}!', { name: 'David' }) // "Hello, David!"
 */
export const getTranslatedTextByKey = <F extends string | undefined = ''>(
	path: string,
	fallback?: F,
	template?: Record<string, unknown>
): string | F => {
	const result = get(User.userLocaleLanguage, path, fallback ?? '')
	return template && Object.keys(template).length > 0 ? formatWithObject(result, template) : result
}

// Return the current user language, by checking possible sources it can be set in, returning a fallback if needed.
export function getUserLanguage(locale = '') {
	let storageLang
	// Browser environment
	if (typeof window === 'object') {
		if (!locale && Object.hasOwn(window, 'navigator')) {
			locale = navigator.languages?.[0] || navigator.language
		}
		storageLang = Object.hasOwn(window, 'localStorage') && oStorage.get(CONSTANTS.USER.PREFERRED_LANGUAGE)
	}
	return storageLang || User.preferredLanguage || locale.substring(locale.indexOf('_') + 1) || Infra.appParams.l
}
