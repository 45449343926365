// @ts-nocheck
import React from 'react'
import { CONSTANTS, HTTP_CODES } from 'utils/constants'
import GenericErrorPageNextJS from './GenericErrorPageNextJS'
import ChatSessionExpiredErrorPage from './ChatSessionExpiredErrorPage'
import ChatGenericErrorPage from './ChatGenericErrorPage'

/**
 * Function that from the error type redirect to the relevant UI page.
 * Can be redirected to generic error page, session expired page or 404 page.
 * @param errorType: string, origin: string, language: string, isEcommerce: boolean, url:string
 * return void
 * */

export default class ErrorBoundary extends React.Component<React.PropsWithChildren> {
	constructor(props: React.PropsWithChildren) {
		super(props)
		this.state = {
			hasError: false,
		}
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true, error }
	}

	componentDidCatch(error, errorInfo) {
		console.error(error, errorInfo)
	}

	render() {
		if (this.state.hasError) {
			const queryParams = new URLSearchParams(window.location.search)
			const isChatApp = [CONSTANTS.APP.TYPES.WHATSAPP, CONSTANTS.APP.TYPES.MESSENGER, CONSTANTS.APP.TYPES.TELEGRAM].includes(
				parseInt(queryParams.get('app'))
			)

			if (isChatApp) {
				const appId = queryParams.get('app')
				const targetChatUrl = queryParams.get('path')

				if (this.state.error.type === HTTP_CODES.SESSION_EXPIRED) {
					return <ChatSessionExpiredErrorPage targetChatUrl={targetChatUrl} appId={appId} />
				}
				return <ChatGenericErrorPage targetChatUrl={targetChatUrl} appId={appId} />
			}

			return <GenericErrorPageNextJS />
		}
		return this.props.children
	}
}
