// @ts-nocheck
import React from 'react'
import { inject, observer } from 'mobx-react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { isNodeJsEnv } from 'utils/utils'
import { getThemeUrl } from 'utils/theme/themeManager'
import { THEME_STRATEGY } from 'constants/theme'
import { useClientSideRendering } from 'hooks/useClientSideRendering'
import type { InfraProps } from 'mobx/Infra/Infra.type'

if (!isNodeJsEnv) {
	/**
	 * Currently css/scss files MUST be imported dynamically in order not to crash the node js server react rendering
	 * TODO-SERGEY: the long term solution is https://coderedirect.com/questions/239528/how-can-i-use-style-loader-in-react-ssr-server-side-rendering
	 * Do NOT remove the comment inside the import! for more info: https://webpack.js.org/api/module-methods/#magic-comments
	 */
	import(/* webpackMode: "eager" */ 'react-lazy-load-image-component/src/effects/blur.css')
}

interface Props {
	className?: string
	alt: string
	testId?: string
	assetFullUrl?: string
	asset?: string
}

/**
 * This component takes a className, alt and an 'asset' parameters (ex: /icons/cart.png) or a 'fullAssetUrl'(ex: https://abc.com/cart.png).
 *
 * This is how to define an image:
 *    const ImageName = (props) => <ImageComponent asset="/images/imageName.png" alt="failed to load" {...props} />

 * This is how we style it:
 *    const StyledHouseImage = styled(HouseImage)`width: 30px; height: 30px`
 * 
 * This component is basically the same as IconComponent just for images
 */

const ImageComponent = inject('Infra')(
	observer((props: Props & { Infra?: InfraProps }) => {
		const { Infra } = props
		const isClientSideRendering = useClientSideRendering()
		const themeStrategy = Infra.appParams.theme || THEME_STRATEGY.GENERIC
		const chainId = Infra?.appParams?.c
		const brand = Infra?.appParams?.brand

		const imgPath = `${getThemeUrl(themeStrategy, chainId, brand)}/assets`

		const assetUrl = props.assetFullUrl || `${imgPath}${props.asset}`

		const handleError = (e) => {
			e.target.onerror = null // Prevents an infinite loop in case the fallback image also fails
			e.target.src = Infra?.appParams?.logo
		}

		return isClientSideRendering ? (
			<LazyLoadImage
				alt={props.alt}
				effect="blur"
				src={assetUrl}
				className={props.className}
				width="100%"
				height="100%"
				data-testid={props.testId}
				onError={props.onError ? props.onError : handleError}
			/>
		) : null
	})
)

export default ImageComponent

ImageComponent.defaultProps = {
	alt: 'failed to load image',
	className: '',
}
