// @ts-nocheck
import React, { useEffect, useContext } from 'react'
import { codeToLocale, formatPrice, getTranslatedTextByKey } from '../../../utils/utils'
import MenuItemPageHeader from './MenuItemPageHeader'
import MenuItemPageSections from './MenuItemPageSections'
import MenuItemPageFooter from './MenuItemPageFooter'
import { StoreContext } from '../../../contexts/StoreContext'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import styled from 'styled-components/macro'
import CloseMenuItem from './CloseMenuItem'
import classnames from 'classnames'
import TypographyPro from '../../../themes/TypographyPro'

const Container = styled.div`
	&.menuItemPage {
		&.root {
			height: 100%;
			overscroll-behavior-x: contain;
			padding: 0 0 0 26px;
			overflow-y: auto;

			&.itemHasNoVariations {
				padding: 0;
			}

			@media (max-width: 1280px) {
				padding: 0;
			}
		}
		.sections {
			padding-bottom: 100px;
			@media (min-width: 1280px) {
				padding-bottom: 80px;
			}
		}

		.menuItemPageSections {
			&.root {
				width: 100%;
				margin-bottom: 20px;
			}
		}

		.iphoneX {
			padding-bottom: 20px;
		}
	}
`

const Desktop = styled.div`
	display: flex;
	justify-content: space-between;
	height: 100%;
`

const DesktopSectionsHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	padding: 0 0 22px 22px;
	@media (min-width: 577px) {
		padding: 18px 22px;
	}
`

const StyledSectionsTitle = styled(TypographyPro)`
	line-height: 33px;
	color: var(--footerAndDarkBackgrounds);
	text-transform: uppercase;
`

const StyledHeaderBox = styled.div`
	flex-basis: 414px;
	flex-shrink: 0;
	padding-top: 18px;
	overflow-x: auto;
`

const StyledOptionsBox = styled.div`
	flex-grow: 1;
	display: flex;
`

interface MenuItemPageProps {
	Cart: any
	ItemAdditions: any
	User: any
	Infra: any
	itemHasNoVariations?: boolean
	closeModal?: () => void
}

const MenuItemPage = ({ Cart, ItemAdditions, User, Infra, itemHasNoVariations, closeModal }: MenuItemPageProps) => {
	const { store } = useContext(StoreContext)
	const Restaurant = store.data

	const locale = User.preferredLanguage ? (codeToLocale as any)[User.preferredLanguage] : Restaurant.locale

	// const windowHeight = `${window.innerHeight - TOP_BAR_HEIGHT - BOTTOM_BAR_HEIGHT - ITEM_HEADER - PADDING}px`
	// const classes = useStyles({ height: windowHeight })
	// const classes = useStyles()
	const item = Restaurant.items[Cart.menuItemId]

	// console.log(`MenuItemPage initialising...`)
	// ItemAdditions.postInit(item, Cart)

	// runs once on mount and onunmout due to 2nd param []
	// since there's componentWillMount effect equivalent, I reset the ItemAdditions store on unmounting this component
	// see https://stackoverflow.com/questions/53464595/how-to-use-componentwillmount-in-react-hooks
	useEffect(() => {
		const targetElement = document.getElementById('menuItemOverlayContent') as HTMLElement | null
		if (targetElement) {
			disableBodyScroll(targetElement, {
				reserveScrollBarGap: true,
			})
		}

		return () => {
			if (targetElement) {
				enableBodyScroll(targetElement)
			}

			ItemAdditions.reset() // NB these are per item
		}
	}, [])

	const formattedPrice = formatPrice(item.price, Restaurant.currency, Restaurant.countryCode)
	const formattedStrikeThuPrice = item.strikeoutPrice && formatPrice(item.strikeoutPrice, Restaurant.currency, Restaurant.countryCode)
	// const formattedDisplayPrice = item.description?.displayPrice && formatPrice(item.description.displayPrice, Restaurant.currency, Restaurant.countryCode)

	const _showDesktopView = window.innerWidth >= 1280

	return (
		<Container
			className={classnames('menuItemPage root', itemHasNoVariations ? 'itemHasNoVariations' : '')}
			id="menuItemOverlayContent"
			data-testid="menu-item-page"
		>
			{!_showDesktopView && (
				<>
					<MenuItemPageHeader
						item={item}
						formattedPrice={formattedPrice}
						currency={Restaurant.currency}
						locale={locale}
						quantity={ItemAdditions.quantity}
						ItemAdditions={ItemAdditions}
						formattedStrikeThuPrice={formattedStrikeThuPrice}
						closeModal={closeModal}
						itemHasNoVariations={itemHasNoVariations}
						formattedDisplayPrice={item.description?.displayPrice}
						Infra={Infra}
					/>

					<div id="menuItemPageSections" className="menuItemPage sections" style={{ display: Cart.menuItemOpen ? 'block' : 'none' }}>
						{item.variations && <MenuItemPageSections itemId={item.id} visibleVariationCounter={0} />}
					</div>

					{!store?.metaData?.closed && <MenuItemPageFooter currency={Restaurant.currency} itemHasNoVariations={itemHasNoVariations} />}
				</>
			)}

			{_showDesktopView && !itemHasNoVariations && (
				<Desktop>
					<StyledHeaderBox>
						<MenuItemPageHeader
							item={item}
							formattedPrice={formattedPrice}
							currency={Restaurant.currency}
							locale={locale}
							quantity={ItemAdditions.quantity}
							ItemAdditions={ItemAdditions}
							formattedStrikeThuPrice={formattedStrikeThuPrice}
							closeModal={closeModal}
							formattedDisplayPrice={item.description?.displayPrice}
							Infra={Infra}
						/>
					</StyledHeaderBox>

					<StyledOptionsBox>
						<div
							id="menuItemPageSections"
							className="menuItemPage sections"
							style={{
								display: Cart.menuItemOpen ? 'block' : 'none',
								height: '100%',
								overflowY: 'auto',
								width: '100%',
								boxShadow: '3px 3px 3px 6px rgba(0, 0, 0, 0.1)',
							}}
						>
							<DesktopSectionsHeader>
								<StyledSectionsTitle variant="h4">{getTranslatedTextByKey('webviewFlow.personalizeYourItem')}</StyledSectionsTitle>
								<CloseMenuItem closeItem={closeModal} />
							</DesktopSectionsHeader>

							{item.variations && <MenuItemPageSections itemId={item.id} visibleVariationCounter={0} />}

							{!store?.metaData?.closed && <MenuItemPageFooter currency={Restaurant.currency} />}
						</div>
					</StyledOptionsBox>
				</Desktop>
			)}

			{_showDesktopView && itemHasNoVariations && (
				<>
					<MenuItemPageHeader
						item={item}
						formattedPrice={formattedPrice}
						currency={Restaurant.currency}
						locale={locale}
						quantity={ItemAdditions.quantity}
						ItemAdditions={ItemAdditions}
						formattedStrikeThuPrice={formattedStrikeThuPrice}
						closeModal={closeModal}
						itemHasNoVariations={itemHasNoVariations}
						Infra={Infra}
					/>

					<div id="menuItemPageSections" className="menuItemPage sections" style={{ display: Cart.menuItemOpen ? 'block' : 'none' }}>
						{item.variations && <MenuItemPageSections itemId={item.id} visibleVariationCounter={0} />}
					</div>

					{!store?.metaData?.closed && <MenuItemPageFooter currency={Restaurant.currency} itemHasNoVariations={itemHasNoVariations} />}
				</>
			)}
		</Container>
	)
}
export default MenuItemPage
