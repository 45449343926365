import { CONSTANTS } from '../utils/constants'

type OrderType = string

// @warning: We might have other values, do not rely on this list
type OrderTypeFromQS = string // examples: 'peakup-class' | 'delivery-class'

function isOrderTypeValid(input: unknown): input is OrderType {
	if (typeof input !== 'string') {
		return false
	}
	return [...Object.values(CONSTANTS.ORDER_TYPE), 'all'].includes(input)
}

export { type OrderType, type OrderTypeFromQS, isOrderTypeValid }
