// @ts-nocheck
import React from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import AssetsIcon from './AssetsIcon'
import TypographyPro from 'themes/TypographyPro'
import StoreAvailabilityInformation from './StoreAvailabilityInformation'
import { getUserLanguage, getValueByLang } from 'utils/language'
import { getTranslatedTextByKey } from 'utils/utils'

// assets
const PhoneBaseIcon = (props) => <AssetsIcon asset="/icons/phone.svg" {...props} />
const DirectionBaseIcon = (props) => <AssetsIcon asset="/icons/direction.svg" {...props} />

const PhoneIcon = styled(PhoneBaseIcon)`
	@media (max-width: 576px) {
		margin-top: 5px;
	}
`

const Section = styled.div`
	display: flex;
	color: #686767;
	font-size: 18px;
	font-family: Work Sans;
	flex-wrap: wrap;
`

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	color: var(--strokeMenuTitle);
`

const PhoneAndDirectionsContainer = styled(Section)`
	display: flex;
	word-break: break-word;
`

const StoreName = styled(TypographyPro)`
	${({ theme }) => theme.typography.h4};
	color: var(--strokeMenuTitle);
`

const Distance = styled.div`
	color: var(--primary);
	${({ theme }) => theme.typography.BodySmall};
`

const SectionContent = styled.div`
	color: var(--fonts);
	display: flex;
	align-items: center;
	margin-top: 5px;
	gap: 5px;

	:first-child {
		margin-inline-end: 50px;
	}
`

const Link = styled(TypographyPro).attrs({
	component: 'a',
	variant: 'BodyRegularClickable',
})`
	color: var(--fonts);
	text-transform: capitalize;
`

const AddressText = styled(TypographyPro)`
	color: var(--fonts);
	margin: 10px 0 5px 0;
`

const LinkToExternal = styled(Link)`
	text-decoration: underline;
`

const Rank = styled(TypographyPro)`
	color: var(--secondary);
	position: absolute;
	top: 5px;
	width: 20px;
	text-align: center;
	left: 5px;
`

const MarkerLogo = styled(AssetsIcon)`
	width: 100%;
`

const StyledGrid = styled(Grid)`
	margin-inline-end: 15px;
	position: relative;
	height: 38px;
`

const CardContent = (props) => {
	const { data, locale, isFullList, isNearbyStores, rank, hasStores } = props

	const stopEventPropagtion = (event) => event.stopPropagation()
	const storeTitle = getValueByLang(data.title, locale)
	const lang = getUserLanguage()

	return (
		<div>
			<Header>
				<Grid container alignItems="center">
					<StyledGrid item xs={1}>
						{!isFullList && !isNearbyStores && hasStores ? (
							<>
								<MarkerLogo asset="/icons/innerMarkerLogo.svg" useThemeDefaultColor />
								<Rank variant="BodyRegularHighlighted" $is2digits={rank > 9} data-testid="rank-text">
									{rank}
								</Rank>
							</>
						) : (
							<MarkerLogo asset="/icons/outerMarkerLogo.svg" useThemeDefaultColor testId="brand-marker" />
						)}
					</StyledGrid>
					<Grid item xs>
						<StoreName variant="h4" data-testid="store-name">
							{storeTitle}
						</StoreName>
						{!isFullList && data?.distanceNum && <Distance>{(data.distanceNum / 1000).toFixed(2)} KM</Distance>}
					</Grid>
				</Grid>
			</Header>

			<div>
				<AddressText variant="BodyRegular" data-testid="store-address">
					{data?.address?.formatted}
				</AddressText>
				<PhoneAndDirectionsContainer>
					{data?.contact?.phone && (
						<SectionContent>
							<PhoneIcon />
							<LinkToExternal
								href={`tel:${data.contact.phone}`}
								onClick={stopEventPropagtion}
								data-testid="store-phone-link"
								target="_blank"
							>
								{data.contact.phone}
							</LinkToExternal>
						</SectionContent>
					)}

					<SectionContent>
						<DirectionBaseIcon />
						<LinkToExternal
							component="a"
							target="_blank"
							href={`https://www.google.com/maps/search/?api=1&query=${data?.address?.formatted}`}
							onClick={stopEventPropagtion}
							data-testid="store-directions-link"
						>
							{getTranslatedTextByKey('eCommerce.storeLocator.directions', 'Directions')}
						</LinkToExternal>
					</SectionContent>
				</PhoneAndDirectionsContainer>
				{!isFullList && <StoreAvailabilityInformation availabilityData={data.availability} lang={lang} locale={locale} />}
			</div>
		</div>
	)
}

export default CardContent
