import type { CheckCouponOn } from 'mobx/CouponFlow'
import { OrderType } from 'types/Coupons'
import type { Flags, StrippedCoupon, StrippedIndividualCoupon } from 'types/Coupons'
import type GetGTResponse from 'types/GetGTResponse'
import type { Menu } from 'types/Menu'

export interface CouponsDependencies {
	getMyCoupons(): Promise<StrippedCoupon[]>
	getCoupon(code: string, checkCouponOn: CheckCouponOn): Promise<StrippedCoupon>
	addCouponToWallet(code: string): Promise<AddCouponToWalletResponse>
	getPrbCode(id: string): Promise<PrbCoupon>
	getGT(menu: Menu): Promise<GetGTResponse | null>
	getIgnoreOrderTypes(): OrderType[]
}

enum ExtraFilters {
	ALL = 'all',
	MY_COUPONS = 'myCoupons',
}

export const CouponFilter = { ...ExtraFilters, ...OrderType } as const
export type Filter = ExtraFilters | OrderType

// Server-Side Stuff
export interface SearchBody {
	fields: {
		name: string
		operator: 'eq' | 'gr' | 'lt' | 'in'
		value: string | string[] | number | number[]
		logicalOperator?: 'and' | 'or'
		pagination?: { offset: number; limit: number }
		sort?: { fieldName: string; direction: 'ASC' | 'DESC' }
	}[]
}

export interface PrbResponse {
	couponCode: string
	couponUrl: string | null
}

export interface PrbCoupon {
	code: string
	url: string
}

// Due to a TypeScript limitation, this has to be a type and not an interface
// Read more: https://www.typescriptlang.org/docs/handbook/2/everyday-types.html#differences-between-type-aliases-and-interfaces
// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type GetCouponQuery = {
	storeId?: string
}

export interface Feature {
	name: 'displayInCouponWallet' | 'requireLoginWallet'
	[key: string]: string
}

export interface ServerSideCoupon extends Omit<StrippedCoupon, 'flags' | 'orderTypes' | 'timestamp' | 'expiration'> {
	expiration: number
	features: Feature[]
	orderTypes: (OrderType | 'peakup' | 'curbside' | 'tableside' | 'dineIn' | 'takeAway')[]
}

export type AddCouponToWalletResponse = Pick<StrippedIndividualCoupon, 'id' | 'code' | 'parentCode' | 'status' | 'totalUses' | 'usesLeft'> &
	Pick<ServerSideCoupon, 'expiration'> & {
		metadata: Pick<ServerSideCoupon, 'title' | 'description'>
	}
