// src/utils/googleMapsUtils.ts

let mapsApiLoaded = false
let mapsApiLoadingPromise: Promise<void>
let googleMapsAPIObj: any = null

declare global {
	interface Window {
		initGoogleMapsApi: () => void
		google: any
	}
}

const apiKey = 'AIzaSyBnea1oOS9Wif_l-zV6WfxjA4sZs5kpt-k'

const loadGoogleMapsApi = () => {
	if (mapsApiLoaded) {
		return Promise.resolve()
	}
	if (mapsApiLoadingPromise) {
		return mapsApiLoadingPromise
	}

	mapsApiLoadingPromise = new Promise((resolve, reject) => {
		const script = document.createElement('script')
		script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places,drawing&callback=initGoogleMapsApi&loading=async`
		script.async = true
		script.defer = true
		document.body.appendChild(script)

		window.initGoogleMapsApi = () => {
			mapsApiLoaded = true
			googleMapsAPIObj = window.google.maps
			resolve()
		}

		script.onerror = (error) => {
			reject(error)
		}
	})

	return mapsApiLoadingPromise
}

export const getGoogleMapAPIObj = async () => {
	if (!mapsApiLoaded && !window.google) {
		await loadGoogleMapsApi()
	}
	if (window.google) {
		mapsApiLoaded = true
		googleMapsAPIObj = window.google.maps
	}
	return new Promise((resolve) => {
		resolve(googleMapsAPIObj)
	})
}
