// @ts-nocheck
import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import AddCircleIconOutlined from '@material-ui/icons/AddCircleOutline'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import PropTypes from 'prop-types'
import { formatPrice } from '../../../utils/utils'
import { isNil } from 'lodash-es'
import styled from 'styled-components/macro'
import TypographyPro from '../../../themes/TypographyPro'

const StyledRoot = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	line-height: 42px;

	@media (min-width: 1280px) {
		justify-content: flex-start;
		margin-inline-start: ${(props) => (props.$showLabel ? '0' : '-10px')};
		line-height: 48px;
	}
`

const StyledTitle = styled(TypographyPro)`
	color: var(--footerAndDarkBackgrounds);
	line-height: 15px;

	@media (min-width: 1280px) {
		line-height: 17px;
	}

	.invalid & {
		color: var(--err);
	}
`

const StyledQuantityInHeader = styled(TypographyPro)`
	text-align: center;
	color: var(--footerAndDarkBackgrounds);
	line-height: 23px;
	width: 23px;

	@media (min-width: 1280px) {
		width: 46px;
	}
`

const StyledQuantity = styled(TypographyPro)`
	text-align: center;
	color: var(--footerAndDarkBackgrounds);
	line-height: 8px;
	width: 26px;

	&:after {
		font-size: 9px;
		font-weight: 400;
		content: '\\a${(props) => props.word}';
		white-space: pre;
	}
`

const StyledPieces = styled.span`
	font-size: 8px;
	font-weight: 400;
	text-align: center;
`

const StyledAddCircleIcon = styled(AddCircleIcon)`
	font-size: ${({ $showLabel }) => ($showLabel ? '32px' : '35px')};

	@media (min-width: 1280px) {
		font-size: ${({ $showLabel }) => ($showLabel ? '35px' : '58px')};
	}
`

const StyledAddCircleIconOutlined = styled(AddCircleIconOutlined)`
	font-size: ${({ $showLabel }) => ($showLabel ? '32px' : '35px')};
	// color: ${(props) => props.theme.palette.first};

	@media (min-width: 1280px) {
		font-size: ${({ $showLabel }) => ($showLabel ? '35px' : '58px')};
	}
`

const StyledRemoveCircleOutlineIcon = styled(RemoveCircleOutlineIcon)`
	font-size: ${({ $showLabel }) => ($showLabel ? '32px' : '35px')};

	@media (min-width: 1280px) {
		font-size: ${({ $showLabel }) => ($showLabel ? '35px' : '58px')};
	}
`

const StyledTypographyPrice = styled(TypographyPro)`
	color: var(--disable);
`

/**
 * It displays the item's name a minus icon, current number, a plus icon.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ItemQuantity = (props) => {
	const handleIncrement = () => {
		// if (props.quantity < props.max && props.allowPlus) {
		if (props.quantity < props.max) {
			setUpdateBy(1)
		}
	}

	// const _min = props.min || 0

	const handleDecrement = () => {
		if (props.quantity > 0) {
			setUpdateBy(-1)
		}
	}

	const setUpdateBy = (increment) => {
		if (props.manageQuantities) {
			if (props.variations) {
				// TODO - for now all prices are 0 but there may be a bug if different quantities of this item cost different prices!
				const [nonDefaultVariationId] = props.variations[0].itemIds
				const price = props.price || props.variations[0].prices[nonDefaultVariationId]
				props.manageQuantities({ id: props.id, quantity: increment, name: props.label, price })
			} else {
				// used in MenuItemPageHeader
				props.manageQuantities(increment)
			}
		}
	}

	return (
		<StyledRoot $showLabel={props.showLabel}>
			{props.showLabel && (
				<div style={{ flexGrow: 1 }}>
					<StyledTitle variant="BodyRegular" component="span">
						{props.label}
					</StyledTitle>
					{!isNil(props.price) && props.price > 0 && (
						<StyledTypographyPrice variant="BodySmall" component="span">
							&nbsp;{formatPrice(props.price, props.currency, props.countryCode)}
						</StyledTypographyPrice>
					)}
				</div>
			)}

			{props.showLabel && (
				<>
					<IconButton onClick={handleDecrement} size="small" data-testid="item-quantity-dec-btn" disabled={props.isDecDisabled}>
						<StyledRemoveCircleOutlineIcon $showLabel={props.showLabel} />
					</IconButton>
					<StyledQuantity component="p" variant="BodyRegular" data-testid="item-quantity-text">
						{props.quantity}
					</StyledQuantity>
					<IconButton onClick={handleIncrement} size="small" data-testid="item-quantity-add-btn" disabled={props.isIncDisabled}>
						<StyledAddCircleIconOutlined $showLabel={props.showLabel} />
					</IconButton>
				</>
			)}

			{!props.showLabel && (
				<>
					<IconButton onClick={handleDecrement} size="small" data-testid="item-quantity-dec-btn" disabled={props.isDecDisabled}>
						<StyledRemoveCircleOutlineIcon $showLabel={props.showLabel} />
					</IconButton>
					<StyledQuantityInHeader component="div" variant="Subhead" data-testid="item-quantity-text">
						{props.quantity}
					</StyledQuantityInHeader>
					<IconButton
						onClick={handleIncrement}
						color="primary"
						size="small"
						data-testid="item-quantity-add-btn"
						disabled={props.isIncDisabled}
					>
						<StyledAddCircleIcon $showLabel={props.showLabel} />
					</IconButton>
				</>
			)}
		</StyledRoot>
	)
}

// cannot use memo since manageQuantities is always different and i can't useCallback on this method since it takes a param!
export default ItemQuantity

ItemQuantity.propTypes = {
	max: PropTypes.number.isRequired,
	label: PropTypes.string,
	manageQuantities: PropTypes.func,
	// allowPlus: PropTypes.bool,
	showLabel: PropTypes.bool.isRequired,
	defaultValue: PropTypes.number,
}
