// @ts-nocheck
import { observable, action, computed } from 'mobx'
import Big from 'big.js'
import {
	updateItemENIDPerOrderType,
	sendRequest,
	getDomainByEnv,
	isItemValidForReorder,
	getLocaleStr,
	initOrUpdateSession,
	getStore,
	setRequestInCookie,
	checkRequest,
	codeToLocale,
	getGT,
	getTranslatedTextByKey,
	replaceTokenInText,
	isMobile,
	setItemsAPI,
	getStoreName,
} from 'utils/utils'
import calculateTotalCostFromVariations from 'utils/cartUtils/calculateTotalCostFromVariations'
import React from 'react'
import { datadogRum } from '@datadog/browser-rum'
import queryString from 'query-string'
import { sendCustomEvent } from 'utils/analytics/analytics'
import { isEmpty, uniq } from 'lodash-es'
import { CONSTANTS, ORDER_TYPES } from 'utils/constants'
import couponUtils from 'utils/coupon/couponUtils'
import loadMenu from 'utils/api/loadMenu/loadMenu'
import CartDependencies from './CartDependencies'
import TypographyPro from 'themes/TypographyPro'
import styled from 'styled-components'
import type ServerCharge from 'types/ServerCharge'
import type { CouponToApply } from 'mobx/CouponFlow'
import type { Coupon } from 'types/Coupons'

const ReOrderDialogTitle = styled(TypographyPro)`
	color: var(--footerAndDarkBackgrounds);
`

// import { RouterStore } from 'mobx-react-router'
// TODO - see https://mobx.js.org/configuration.html to make it ES5 compatible

const calculateTotalFromVariations = (variationsChoices, initialTotal, quantity) => {
	let calculatedTotal = new Big(initialTotal)
	calculatedTotal = calculateTotalCostFromVariations(variationsChoices, calculatedTotal)
	return calculatedTotal.times(quantity).toNumber()
}

/** Given an array of variation objects, extract an object of itemId:price of all variations -
 *  to be used a price single source of truth for variations
 */
const getVariationPrices = (variations) => Object.assign({}, ...variations.flatMap((variation) => ({ ...variation.prices })))

/**
 * Keep state for all menu items.
 */
class Cart {
	dependencies = null

	/**
	 * The same item can be selected more than once with different sub-item selections
	 *
	 * contains: {
	 *     <item-id>: [
	 *     	   0: {
	 *     	       additions: {
	 *					<item.id _ index> = {
	 *						<additionId> {
	 *							id: <additionId>
	 *							name: item.title.en_US,
	 *							price: item.price,
	 *						}
	 *					}
	 *     	       },
	 *     	       total: 19990,
	 *     	       item-id: '05565a6c-9910-2665-efde-41d4d3ec4e10',
	 *     	       comment: '',
	 *     	       price: <price not including any additions>
	 *     	   }
	 *    ]
	 *    Eg
	 *    '617bfc32-30c7-5c69-0997-adc1573d2d83': [
	 *     	   0: {
	 *     	       additions: {
	 *					<32d77a1c-4774-f9fd-9326-20c1c1964944_9> = {
	 *						<b1d9b663-8d94-aaaa-09d3-b3ee37cf40e5> {
	 *							id: <b1d9b663-8d94-aaaa-09d3-b3ee37cf40e5>
	 *							name: 'LARGE CHIPS',
	 *							price: 300,
	 *						}
	 *					}
	 *     	       },
	 *     	       total: 21980,
	 *     	       item-id: '617bfc32-30c7-5c69-0997-adc1573d2d83',
	 *     	       comment: ''
	 *     	   },
	 *     ]
	 * }
	 *
	 * Eg to access 'burger meal' > chips > large chips
	 * Cart.items['617bfc32-30c7-5c69-0997-adc1573d2d83'][0].additions['32d77a1c-4774-f9fd-9326-20c1c1964944_9']["b1d9b663-8d94-aaaa-09d3-b3ee37cf40e5"].name
	 * - returns 'LARGE CHIPS'
	 *
	 * @type {{}}
	 */
	@observable items = {}

	/**
	 * A count of items (including the same item added several times)
	 *
	 * @type {number}
	 */
	@observable numberOfItems = 0

	/**
	 * Is the total price of all items in the cart
	 *
	 * @type {number}
	 */
	@observable total = 0

	@observable editItem = false

	/**
	 * The same item can be added to the Cart more than once. This var. keeps track of which of a multiple of items is being edited
	 *
	 * @type {number}
	 */
	@observable editCartItemIdx = -1

	/**
	 * Can apply multiple discounts to a Cart
	 *
	 * @type {*[]}
	 */
	@observable discounts = {}

	/**
	 * Server-calculated total (includes discounts etc.)
	 *
	 * @type {number}
	 */
	@observable serverGrandTotal = 0

	/**
	 * provided by API call getGT()
	 *
	 * @type {*[]} array of charges including tax, discounts
	 */
	@observable serverCharges: ServerCharge[] = []

	/**
	 * provided by API call getGT()
	 *
	 * @type {*{}} array of charges including tax, discounts
	 */
	@observable deliveryInfo = {}

	/**
	 * provided by API call getGT()
	 *
	 * @type {*[]} array of items added by a discount code
	 */
	@observable addedItemsFromDiscounts = []

	@observable serverDisableCouponsField = false

	@observable serverDisableSpecialDiscounts = false

	@observable serverWarningsToDisplay = []

	@observable serverSpecialDiscountsIdLimit = 0

	@observable appliedDiscounts = []

	safetyNet = 0

	constructor(dependencies) {
		this.dependencies = dependencies
	}

	@action setServerDisableCouponsField = (disable = false) => {
		this.serverDisableCouponsField = disable
	}

	@action setServerDisableSpecialDiscounts = (disable = false) => {
		this.serverDisableSpecialDiscounts = disable
	}

	@action setServerWarningsToDisplay = (warnings = []) => {
		this.serverWarningsToDisplay = warnings
	}

	@action setServerSpecialDiscountsIdLimit = (idLimit = 0) => {
		this.serverSpecialDiscountsIdLimit = idLimit
	}

	@action setAppliedDiscounts = (discounts = []) => {
		this.appliedDiscounts = discounts
	}

	@action setServerSpecialDiscount = ({ specialDiscountsIdLimit, disableSpecialDiscounts, disableCouponsField, warnings, appliedDiscounts }) => {
		this.setServerDisableCouponsField(!!disableCouponsField)
		this.setServerDisableSpecialDiscounts(!!disableSpecialDiscounts)
		this.setServerWarningsToDisplay(warnings || [])
		this.setServerSpecialDiscountsIdLimit(specialDiscountsIdLimit)
		this.setAppliedDiscounts(appliedDiscounts || [])
	}

	/**
	 *
	 * @param item = {
	 *     	itemId,
	 *		additions: [item.id] = {
	 *			name: item.title.en_US,
	 *			price: item.price,
	 *		},
	 *		total,
	 * 	}
	 *
	 *  @param addToStorage = true - add, = false - don't add since added from home-page and there's no store yet
	 */
	@action addItem = (item, reset, storage?: any = null, orderType? = CONSTANTS.ORDER_TYPE.DELIVERY) => {
		let index = 0

		if (this.items[item.itemId]) {
			// the user can add the same item more than once so:
			this.items[item.itemId].push(item)
			index = this.items[item.itemId].length - 1
		} else {
			this.items[item.itemId] = [item]
		}

		this.numberOfItems++

		// without this, the Cart total in the MenuItemPageFooter is too high since the item total has been added to the
		// cart total and again by the ItemAddition total
		reset()

		if (storage) {
			// when the item was added from a store, add to storage (eg from /menu) but when added from /home-page, do not add it since there's no store until the user selects a store
			storage.setStorage({ items: this.items }, orderType)
		}

		return index
	}

	/**
	 * A user can select the same item more than once (eg with different options) so when removing the item, we need
	 * the index of the item since the items obect is {<item_id>: [<1st occurrence of this item>, <2nd occurrence
	 * of this item>...]}
	 *
	 * @param id
	 * @param index
	 */
	@action removeItem = (id, index, storage) => {
		if (this.items[id]) {
			if (this.items[id].length > 1) {
				if (this.items[id][index]) {
					this.items[id].splice(index, 1)
				}
			} else {
				delete this.items[id]
			}
			this.numberOfItems--

			storage.setStorage({ items: this.items })

			if (this.calcNumberOfItems === 0) {
				this.serverGrandTotal = 0
			}
		} else {
			console.error(`cart item: ${id} does not exist in the cart so cannot remove it!`)
		}
	}

	@action removeAllItems = () => {
		this.items = {}
		this.numberOfItems = 0
		this.total = 0
		this.serverGrandTotal = 0
		this.serverCharges = []
	}

	@action setItems = (items, storage) => {
		this.items = items
		this.numberOfItems = Object.keys(items).length
		storage.setStorage({ items })
	}

	/**
	 * After editing an item, replace it in the array.
	 * @param id - existing id
	 * @param index - existing index
	 * @param item - detached item
	 */
	@action replaceItem = (id, index, item, storage) => {
		if (this.items[id]) {
			if (this.items[id].length > 0) {
				if (this.items[id][index]) {
					this.items[id][index] = item
					storage.setStorage({ items: this.items })
				}
			} else {
				console.log(`The Cart does not contain an item with id: ${id} at index: ${index} so cannot replace it!`)
			}
		}
	}

	@action getItems = () => this.items

	/**
	 * Get an upsell:
	 * items - menu items
	 * upsells - upsells object
	 *
	 * Choose a random upsell from "upsellEligibleItems" object, which also exists in the menu and return it
	 * if no upsell was found, just don't return anything
	 */
	@action generateUpsellsArray = ({ sections, upsells }) => {
		if (isEmpty(upsells)) {
			return []
		}

		// Current cart
		// todo: this should be tested as well, currently no test is checking for cart side effects
		const cartItems = Object.values(this.items).flat()

		// List of possible upsells to pick from
		const upsellsItems = new Set()

		// Add upsells that are for the whole menu
		;([...upsells.onMenu] || []).forEach(upsellsItems.add, upsellsItems)

		// Add upsells that are relevant for sections in the cart
		const upsellsSectionKeys = Object.keys(upsells?.onSections || [])
		let upsellsItemsOfSection = []
		if (!isEmpty(upsellsSectionKeys)) {
			const sectionItems = upsellsSectionKeys.map((sectionKey) => {
				const { itemIds } = sections.find(({ id }) => sectionKey === id) ?? { itemIds: [] }
				return {
					sectionKey,
					itemIds,
				}
			})

			const sectionKeysForCartItems = uniq(
				cartItems.reduce((acc, { itemId: cartItemId }) => {
					const secObj = sectionItems.find(({ sectionKey, itemIds }) => itemIds.includes(cartItemId))
					if (secObj) {
						acc.push(secObj.sectionKey)
					}
					return acc
				}, [])
			)
			upsellsItemsOfSection = sectionKeysForCartItems.flatMap((secId) => upsells.onSections[secId])
		}

		// Add upsells that are relevant for specific items in the cart
		const specificItems = cartItems.flatMap(({ itemId }) => upsells?.onItems?.[itemId] || [])
		;[...specificItems, ...upsellsItemsOfSection].forEach(upsellsItems.add, upsellsItems)

		return Array.from(upsellsItems)
	}

	@action getUpsellInTheCart({ items: menuItems }) {
		const item = Object.values(this.items)
			.flat()
			.find(({ itemId }) => !!menuItems[itemId]?.isUpsell)
		if (item) {
			return menuItems[item.itemId]
		}
	}

	@action getUpsell = (menu) => {
		const uniqueUpsellsItems = this.generateUpsellsArray(menu)
		while (!isEmpty(uniqueUpsellsItems)) {
			const randomIndex = Math.floor(Math.random() * uniqueUpsellsItems.length)
			const item = uniqueUpsellsItems[randomIndex]

			if (menu.items[item]) {
				return menu.items[item]
			}
			uniqueUpsellsItems.splice(randomIndex, 1)
		}
	}

	getItemQuantityById = function (id) {
		// There is an array of the same item since the user can add the item several times (each with their own quantity
		// and varying additions.
		// When the app opens, there are no added items so this will be undefined.
		const itemArray = this.items[id]

		let totalQuantity = 0

		if (itemArray) {
			itemArray.forEach((_item) => {
				totalQuantity += _item.quantity
			})
		}

		return totalQuantity
	}

	/*	getItemQuantityById(id) {
    console.error('fix me!!!')
    return 0
  } */

	/*	@computed get itemQuantityById() {
    // const item = this.items[id]
    // return item ? item.length : 0
    return createTransformer((id) => (this.items[id] ? this.items[id].length : 0))
  } */

	@computed get totalCost() {
		const bigTotal = Object.keys(this.items).reduce((acc, itemId) => {
			const itemTotal = this.items[itemId].reduce((arrayTotal, elem) => arrayTotal.add(new Big(elem.total)), new Big(0))

			return acc.add(itemTotal)
		}, new Big(0))

		// return bigTotal.toFixed(2)
		// return bigTotal.toString()
		return Number(bigTotal)
	}

	/**
	 *
	 * @param serverGrandTotalBigDecimal - - Big Decimal
	 */
	@action setServerGrandTotal = (serverGrandTotalBigDecimal) => {
		this.serverGrandTotal = serverGrandTotalBigDecimal
	}

	@action getTotalPriceInCart = () =>
		Object.values(this.items)
			.flat()
			.reduce((acc, curr) => acc + curr.total, 0)

	@action setServerDeliveryInfo = (serverDeliveryInfo = {}) => {
		this.deliveryInfo = serverDeliveryInfo
	}

	@action setServerCharges = (serverCharges: ServerCharge[] = [], merge = false) => {
		if (merge) {
			this.serverCharges = this.serverCharges.filter((_charge) => {
				// remove any existing charges that contain a key of 'deliveryFee' or 'subTotal' since these will be newly added below
				if (_charge.type === 'delivery') {
					return false
				}

				if (_charge.subTotal) {
					return false
				}

				return true
			})

			// now add the delivery fee and subtotal charges (now that we have the delivery address)
			this.serverCharges.push(serverCharges)
		} else {
			this.serverCharges = serverCharges || []
		}
	}

	@action setServerAddedItemsFromDiscounts = (addedItemsFromDiscounts = []) => {
		this.addedItemsFromDiscounts = addedItemsFromDiscounts || []
	}

	// use this instead of the primitive numberOfItems since it is sometimes wrong!
	@computed get calcNumberOfItems() {
		const num = Object.keys(this.items).reduce((acc, itemId) => {
			const numOfSameItem = this.items[itemId].length
			return acc + numOfSameItem
		}, 0)

		return num
	}

	// --------------------------------

	@observable menuItemOpen = false // seperate to id below so can gracefully close the menu-item popup without the item instantly vanishing on closing it

	@observable menuItemId = null // will be a String

	@action openMenuItem = (item, postInit, cartItemIdx = -1) => {
		this.editItem = cartItemIdx !== -1
		this.menuItemId = item.id
		this.editCartItemIdx = cartItemIdx

		// init the ItemAdditions store for editing
		postInit(item, this)

		this.menuItemOpen = true // trigger overlay to open and show menu-item page
	}

	@action emptyMenuItem = () => {
		this.menuItemId = null
		this.editItem = false
		this.editCartItemIdx = -1
	}

	@action closeMenuItem = (historyGoBack) => {
		this.menuItemOpen = false
		if (historyGoBack) {
			history.back()
		}
	}

	/**
	 * Discount can be added from a user entering a coupon/discount code OR if the user reloads the page and their
	 * server session is valid then the server calcGT will provide a list of 'charges' that will be applied to the
	 * user's cart.
	 *
	 * I use both options to show the user what discounts will be applied to their cart in the UI.
	 *
	 * To ensure I dont add the same discount twice I store them as a map where the discount's code is the key and the
	 * discount is the value.
	 *
	 * @param discount
	 */
	@action addDiscount = (discount, storage) => {
		if (discount?.code) {
			this.discounts[discount.code] = discount
			storage.setStorage({ discounts: this.discounts })
		} else {
			console.error('Discount has no code!')
		}
	}

	@action removeDiscount = (discount, storage) => {
		if (discount?.code) {
			delete this.discounts[discount.code]
			storage.setStorage({ discounts: this.discounts })
		} else {
			console.error('Discount has no code!')
		}
	}

	@action applyDiscount = (couponToApply: CouponToApply, menu, storage) => {
		couponUtils.setCart(this)
		couponUtils.apply(couponToApply, menu, storage)
	}

	@action async unapplyDiscount(couponToUnapply: Coupon, menu, storage) {
		couponUtils.setCart(this)
		return couponUtils.unapply(couponToUnapply, menu, storage)
	}

	@action removeAllDiscounts = (storage) => {
		this.discounts = {}
		storage.removeProp('discounts')
		this.dependencies.removeSpecialCoupons()
	}

	/**
	 *  If there's a cart in local storage then check the items exist in the selected store since the user
	 *  may have added items to their cart and then changed store.
	 *
	 * @param oldCartItems - the Cart's items from local storage from the user's previous selection session
	 * @param newMenu - the current store's menu
	 */
	@action filterCartBySelectedStoreMenu = (oldCart, newMenu, oldChainId, newChainId, newOrderType, oldOrderType, cartRelocationEnabled) => {
		if (oldChainId && oldChainId !== newChainId) {
			// there was a previous chain and the user has changed chains so invalidate the cart (this happens during testing)
			oldCart.items = null
			return oldCart
		}

		if (oldCart.storeId === newMenu.id && newOrderType === oldOrderType) {
			// a)
			console.log(
				`The store ${oldCart.storeId} and orderType: ${newOrderType} has not changed between menu reloads so no cart-item filtering needed`
			)
			return oldCart
		}

		if (cartRelocationEnabled) {
			// b)
			console.log(`The store has changed from ${oldCart.storeId} to ${newMenu.id} so filtering cart items...`)
			console.log(`The store has changed from ${oldOrderType} to ${newOrderType} so filtering cart items...`)
			const oldCartItems = oldCart.items
			const newCartItems = {}

			// c) loop over the associative array of items in the cart
			if (Object.keys(oldCartItems || {}).length) {
				Object.keys(oldCartItems).map((_itemId, idx) => {
					const arrayOfThisItem = []
					let newCartItemId = 0

					// d) now loop over the array of THIS item (NB the user can add >1 of the same item)
					oldCartItems[_itemId].map((_oldCartItem, cartItemIdx) => {
						let isNewItemValid = true
						if (_oldCartItem.en_ID) {
							const _newCartItemEN_ID = updateItemENIDPerOrderType(_oldCartItem.en_ID, newOrderType)
							newCartItemId = newMenu.codesMap[_newCartItemEN_ID]

							if (newCartItemId) {
								const newFullCartItem = newMenu.items[newCartItemId]

								if (!newFullCartItem) {
									return
								}

								const totalWithoutDecimalPlaces = new Big(newFullCartItem.price).times(_oldCartItem.quantity)
								const total = Number(totalWithoutDecimalPlaces) // convert back to a number

								const newCartItem = {
									itemId: newCartItemId,
									price: newFullCartItem.price,
									quantity: _oldCartItem.quantity,
									total,
									en_ID: _newCartItemEN_ID,
									isUpsell: newFullCartItem?.isUpsell,
								}

								if (
									_oldCartItem.additionsNew &&
									_oldCartItem.additionsNew[_itemId] &&
									_oldCartItem.additionsNew[_itemId].variationsChoices.length > 0
								) {
									const newItemVariationPrices = getVariationPrices(newFullCartItem.variations)
									const newVariationsChoices = this.buildNestedNewCartVariationsChoices(
										_oldCartItem.additionsNew[_itemId].variationsChoices,
										newMenu,
										newCartItemId,
										newOrderType,
										newItemVariationPrices
									)
									if (
										newVariationsChoices &&
										newVariationsChoices.length === _oldCartItem.additionsNew[_itemId].variationsChoices.length
									) {
										if (newCartItem.additionsNew) {
											newCartItem.additionsNew[newCartItemId].variationsChoices = newVariationsChoices
										} else {
											newCartItem.additionsNew = {
												[newCartItemId]: {
													variationsChoices: newVariationsChoices,
												},
											}
										}

										// Calculate total, taking into account selected variations:
										const calculatedTotal = calculateTotalFromVariations(
											newCartItem.additionsNew[newCartItemId].variationsChoices,
											newCartItem.price,
											newCartItem.quantity
										)
										newCartItem.total = calculatedTotal
									} else {
										const _newVariationsChoicesLength = newVariationsChoices ? newVariationsChoices.length : 0
										console.warn(
											`The old item with en_ID: '${_oldCartItem.en_ID}' has ${_oldCartItem.additionsNew[_itemId].variationsChoices.length} variationsChoices BUT the new menu's item has ${_newVariationsChoicesLength} variationsChoices so REMOVING this item from the user's cart for the new store!`
										)
										isNewItemValid = false
									}
								} else if (_oldCartItem.additionsNew && _oldCartItem.additionsNew[_itemId] === undefined) {
									// this item has additionsNew = {} ie empty ie no additions eg coleslaw, mash, gravy
									newCartItem.additionsNew = {}
								} else {
									// defensie code - this item has no additions eg coleslaw, mash, gravy
									newCartItem.additionsNew = {}
								} /* else 	{
								// this item has no additions eg coleslaw, mash, gravy
								if (newCartItem.additionsNew) {
									newCartItem.additionsNew[newCartItemId].variationsChoices = {}
								} else {
									newCartItem.additionsNew = {
										[newCartItemId]: {
											variationsChoices: {},
										},
									}
								}
							} */

								if (isNewItemValid) {
									arrayOfThisItem.push(newCartItem)
								} else {
									oldCart.newCartHasBeenAltered = true
								}
							} else {
								// the old item does not have an equivalent in the new menu
								oldCart.newCartHasBeenAltered = true
							}
						}
					})

					if (newCartItemId !== 0 && arrayOfThisItem.length > 0) {
						// NB if the old item's variationsChoices length is different to the new menu item's variationsChoices length, then we are excluding it
						newCartItems[newCartItemId] = arrayOfThisItem
					}
				})
			}

			// the oldCart has items, storeId, storageDate, version. So we just need to replace the items object
			oldCart.items = newCartItems
			return oldCart
		}
		oldCart.items = {}
		return oldCart
	}

	/**
	 * From the old cart stored in local storage which is in the web-app JSON format (not the server's courseList
	 * format), build its variationsChoices.
	 *
	 * @param oldVariationsChoices
	 * @param newMenu
	 * @param newCartItemId
	 * @param newOrderType
	 * @param newItemVariationPrices
	 * @returns {*[]}
	 */
	buildNestedNewCartVariationsChoices = (oldVariationsChoices, newMenu, newCartItemId, newOrderType, newItemVariationPrices) => {
		this.safetyNet++

		const result = []

		if (this.safetyNet < 10) {
			// a) loop over array of variations/additions
			for (let _choiceIdx = 0; _choiceIdx < oldVariationsChoices.length; _choiceIdx++) {
				const _oldVariationsAssociatedArray = oldVariationsChoices[_choiceIdx]
				// oldVariationsChoices.forEach((_oldVariationsAssociatedArray, _choiceIdx) => {
				if (_oldVariationsAssociatedArray) {
					const multiSelectVariations = {}
					// b) loop over associated array of items (group them in a single array for all of the associated array items under this array

					for (const [_variationId, _oldVariation] of Object.entries(_oldVariationsAssociatedArray)) {
						// try and find the new menu's equivalent variation (eg 'choose a side' and chips is the variation selected)
						const _newMenuVariation = this.convertOldCartVariationIntoNewCartVariation(
							_oldVariation,
							newMenu,
							newOrderType,
							newItemVariationPrices
						)

						if (_newMenuVariation) {
							if (_oldVariation.quantity) {
								// Tomer said this variation can be left the same since the quantity's id is meaningless
								// so no variations to add for a quantity
								console.log('is a quantity variation so it has no variations itself so do NOTHING!')
							} else if (_oldVariation.variationsChoices) {
								// trigger recursion
								const _newMenuVariationsChoices = this.buildNestedNewCartVariationsChoices(
									_newMenuVariation.variationsChoices,
									newMenu,
									newCartItemId,
									newOrderType,
									newItemVariationPrices
								)

								_newMenuVariation.variationsChoices = _newMenuVariationsChoices
							}

							multiSelectVariations[_newMenuVariation.id] = _newMenuVariation
						} else {
							console.log(
								`no equivalent variation exists in the new menu for en_ID: '${_oldVariation.en_ID}' so removing this entire item from the user's cart`
							)
							return
						}
						// })
					}

					result.push(multiSelectVariations)
				} else {
					// value is null (eg an item has 5 options, they are all mandatory and have default options except for the 4th option.
					// so the 4th option is not added to the ItemAdditions. But when the 5th option is added to the array, they 4th element
					// is added automatically with a value of null.
					result.push(null)
				}
				// })
			}
		} else {
			console.error(`breaking out of recursion!!!`)
		}

		this.safetyNet = 0

		return result
	}

	convertOldCartVariationIntoNewCartVariation = (oldCartVariation, newMenu, newOrderType, newItemVariationPrices) => {
		// this is the equivalent variation that we are looking for in the new menu
		let newFullVariation = null

		try {
			// check the en_ID exists and it's not an empty string
			if (oldCartVariation.en_ID && oldCartVariation.en_ID !== '') {
				// a) find by en_ID
				const _newItemEN_ID = updateItemENIDPerOrderType(oldCartVariation.en_ID, newOrderType)
				const newVariationId = newMenu.codesMap[_newItemEN_ID]
				newFullVariation = newMenu.items[newVariationId]
			}
			if (newFullVariation) {
				// we only need the new variation's id and price, everything else we take from our variation object
				const newVariationItem = {
					...oldCartVariation,
					en_ID: newFullVariation.description?.en_ID,
					id: newFullVariation.id,
					// The source of truth for the price of a variation is always the prices property on variations on the data of the item itself. TBE confirmed this.
					price: newItemVariationPrices[newFullVariation.id] || 0,
				}
				return newVariationItem
			}

			// no equivalent variation was found in the new menu
			return null
		} catch (e) {
			// the old variation may have a different number of choices compared to the new variation so a lookup of a non-existing index may cause an error
			console.error(e)
			return null
		}
	}

	@action restoreFromLocalStorage = (storage) => {
		if (storage?.items) {
			this.items = storage.items
			this.numberOfItems = (Object.keys(storage.items) ?? '').length
		}

		if (storage?.discounts) {
			this.discounts = storage.discounts
		}

		console.log('Restored Cart from storage')
	}

	@action setHistory(history) {
		this.history = history
	}

	performReOrder = (storage) => async (oldOrder, courseList, storeId, menu, setStore, router, Infra, User, MobileApplication, ItemAdditions) => {
		storage.clearItems(storeId)
		this.buildCartFromServerCourseList(courseList, ItemAdditions.reset, User.preferredLanguage, storage, oldOrder.orderType)
		await this.startNewReOrderSession(menu, storeId, setStore, router, Infra, User, MobileApplication, oldOrder.orderType, storage)

		// update the server with either a) the updated cart with some items OR b) a cart with no items
		getGT(this.items, menu.items, false, (gt, charges, addedItemsFromDiscounts, deliveryInfo, response, removeDiscountByCalcGt) => {
			removeDiscountByCalcGt(this, response.appliedDiscounts)
			this.setServerGrandTotal(gt)
			this.setServerCharges(charges)
			this.setServerAddedItemsFromDiscounts(addedItemsFromDiscounts)
			this.setServerDeliveryInfo(deliveryInfo)
			this.setServerSpecialDiscount(response)
		})

		Infra.showSnackbar({
			// snackId: 'cart',
			message: replaceTokenInText(getTranslatedTextByKey('webviewFlow.reorder.addedToCart'), { orderId: oldOrder._id }),
			status: 'success',
			isAttachedToElement: false,
		})
	}

	@action async reOrder(oldOrder, locale, setStore, router, reset, Infra, User, isMobileApp, MobileApplication, ItemAdditions, storage, store) {
		let canReOrder = true
		let partItemsUnAvailable = false
		let invalidReason = null
		const storeId = oldOrder.restid
		const oldOrderList = []

		// a) check if the store is open
		if (oldOrder.orderType === ORDER_TYPES.DELIVERY) {
			canReOrder = await this.isOldOrdersDeliveryStoreOpen(storeId, oldOrder.address, Infra.appParams.c, User.preferredLanguage)
			if (!canReOrder) {
				invalidReason = replaceTokenInText(getTranslatedTextByKey('webviewFlow.reorder.storeClosed'), { storeName: oldOrder.restName })
			}
		} else if (oldOrder.orderType === ORDER_TYPES.PEAKUP) {
			canReOrder = await this.isOldOrdersPickupStoreOpen(storeId, Infra.appParams.c)
			if (!canReOrder) {
				invalidReason = replaceTokenInText(getTranslatedTextByKey('webviewFlow.reorder.storeClosed'), { storeName: oldOrder.restName })
			}
		} else {
			console.error(`re-order - unknown order type: ${oldOrder.orderType}`)
			canReOrder = false
			invalidReason = `Unknown order type: ${oldOrder.orderType}`
		}

		let menu = null

		if (canReOrder) {
			const appIdWebOrMobileApp = isMobileApp ? CONSTANTS.APP.TYPES.IOS_APP : CONSTANTS.APP.TYPES.WEB
			menu = await loadMenu({
				chainId: Infra.appParams.c,
				storeId,
				orderTypeFromQS: oldOrder.orderType,
				appId: appIdWebOrMobileApp,
				stopLoading: true,
				storage,
			})

			// b) validate each item exists in the menu json and the price has not changed and check each item's variations exist and the price has not changed
			for (let i = 0; i < oldOrder.courseList.length; i++) {
				if (!isItemValidForReorder(oldOrder.courseList[i], menu, true)) {
					partItemsUnAvailable = true
				} else {
					oldOrderList.push(oldOrder.courseList[i])
				}
			}

			if (oldOrderList.length === 0) {
				invalidReason = getTranslatedTextByKey(
					'webviewFlow.reorder.allItemsUnavailable',
					'All the items in this order are currently unavailable'
				)
				canReOrder = false
				partItemsUnAvailable = false
			}
		}

		// NB ignores coupon codes

		if (partItemsUnAvailable) {
			const title = getTranslatedTextByKey('webviewFlow.reorder.someItemsUnavailable', 'Some deals are currently unavailable')
			const message = getTranslatedTextByKey('webviewFlow.reorder.partItemsAvailableMessage', 'Would you like to proceed without them?')
			Infra.setNotification({
				title: <ReOrderDialogTitle variant="h4">{title}</ReOrderDialogTitle>,
				okText: getTranslatedTextByKey('webviewFlow.reorder.proceed', 'Proceed'),
				cancelText: getTranslatedTextByKey('webviewFlow.reorder.goBack', 'Go Back'),
				customButtonType: 'secondary',
				message: <ReOrderDialogTitle variant="BodyRegular">{message}</ReOrderDialogTitle>,
				okAction: async () => {
					sendCustomEvent({
						category: 'account',
						action: 're-order',
						label: `some items are not available for ordering ${oldOrder._id}`,
						storeID: store?.data?.id || '',
						storeName: getStoreName(store) || '',
					})
					await this.performReOrder(storage)(
						oldOrder,
						oldOrderList,
						storeId,
						menu,
						setStore,
						router,
						Infra,
						User,
						MobileApplication,
						ItemAdditions
					)
					Infra.closeNotification()
				},
				cancelAction: isMobile()
					? false
					: () => {
							Infra.closeNotification()
					  },
				customAction: !isMobile()
					? false
					: () => {
							Infra.closeNotification()
					  },
			})

			sendCustomEvent({
				category: 'error',
				action: 'notification',
				label: title,
				message,
			})
		} else if (canReOrder) {
			sendCustomEvent({
				category: 'account',
				action: 're-order',
				label: `available for order ${oldOrder._id}`,
				storeID: store?.data?.id || '',
				storeName: getStoreName(store) || '',
			})
			await this.performReOrder(storage)(
				oldOrder,
				oldOrder.courseList,
				storeId,
				menu,
				setStore,
				router,
				Infra,
				User,
				MobileApplication,
				ItemAdditions
			)
		} else {
			// d) fire analytics event for re-order failed
			sendCustomEvent({
				category: 'account',
				action: 're-order',
				label: `unavailable for order ${oldOrder._id} - ${invalidReason}`,
				storeID: store?.data?.id || '',
				storeName: getStoreName(store) || '',
			})

			// e) if invalid, show a popup saying 'sorry, item ABC is not available as you ordered it'
			const title = getTranslatedTextByKey('webviewFlow.reorder.unavailable')
			const message = invalidReason
			Infra.setNotification({
				title: getTranslatedTextByKey('webviewFlow.reorder.unavailable'),
				message: (
					<TypographyPro variant="BodyRegular">
						<div key="1">{message}</div>
					</TypographyPro>
				),
				cancelText: getTranslatedTextByKey('webviewFlow.reorder.goBack', 'Go Back'),
				cancelAction: () => {
					Infra.closeNotification()
				},
			})

			sendCustomEvent({
				category: 'error',
				action: 'notification',
				label: title,
				message,
			})
		}
	}

	async startNewReOrderSession(menu, storeId, setStore, router, Infra, User, MobileApplication, orderType, storage) {
		const newMenuPath = await initOrUpdateSession({
			refObject: { orderType: orderType || Infra.appParams.ot },
			storeId,
			stopLoading: false,
		})
		const newMenuQueryParams = queryString.parse((newMenuPath ?? '').split('?')[1])

		// When a redirect occurs from init new session above, we get en empty newMenuPath
		// Therefore, the execution of the function can be stopped here
		if (!newMenuPath) {
			console.warn('No new menu path received')
			return
		}

		/* the code below is a part-copy from app.js for when the apps mounts with the menu/checkout page. It's hard
         to move it to a separate method since it requires component methods too eg setStore and history */
		const serverSession = await checkRequest(
			{
				wru: Infra.appParams.wru,
				request: newMenuQueryParams.request,
				cust: newMenuQueryParams.cust,
				j: newMenuQueryParams.j,
				tictuk_listener: newMenuQueryParams.tictuk_listener,
			},
			false
		)

		// if giftId is available it means that it was shared from someone else and the
		// user who got it should see it already in the cart when opening the menu
		if (serverSession?.giftId) {
			if (!storage?.getStorage()?.items[serverSession?.giftId]) {
				localStorage.setItem('giftId', serverSession?.giftId)
			}
		}

		await User.setSession(serverSession)

		const setItemsResponse = await setItemsAPI(this, menu)

		if (!setItemsResponse) {
			return
		}

		/*        if (menu.sections?.length === 0) {
            const unsupportedOrderType = Infra.appParams.ot.split('-')[0]
            const alternateType = unsupportedOrderType === 'delivery' ? 'pickup' : 'delivery'

            Infra.setNotification({
                open: true,
                title: <WarningIcon color="secondary" />,
                message: `We couldn't find any items for '${unsupportedOrderType}'. You may order '${alternateType}'`,
            })

            return
        } */

		const storeMetaData = await getStore({
			wru: Infra.appParams.wru,
			request: newMenuQueryParams.request,
			cust: newMenuQueryParams.cust,
			tictuk_listener: newMenuQueryParams.tictuk_listener,
		})

		datadogRum.setUser({
			name: storeMetaData?.orderId,
			id: serverSession.uuid,
		})

		setRequestInCookie(newMenuQueryParams.request)

		// set orderConfirmationLink in the localStorage in order to use it
		// when reloading the confirmation order page
		localStorage.setItem('orderConfirmationLink', storeMetaData?.orderConfirmationJSONLink)

		if (storeMetaData) {
			setStore((store) => ({ ...store, data: menu, metaData: storeMetaData }))

			localStorage.removeItem('orderCompleted') // start a new order, orderCompleted flag should be removed.
			// load the /menu page
			router.push(newMenuPath.replace('/menu?', '/checkout?'))
			MobileApplication.setMenuPath(newMenuPath)
		}
	}

	isOldOrdersDeliveryStoreOpen = async (storeId, address, chainId, preferredLanguage) => {
		// call getBranchByAddress and pass the old order's address. If the store's ID is in the response then the store is open
		const res = await sendRequest(
			true,
			`${getDomainByEnv()}webFlowAddress`,
			'post',
			{
				chainId,
				type: 'getBranchByAddress',
				addr: { formatted: address.formatted, lat: address.lat, lng: address.lng },
				addressVal: address.formatted,
				cust: 'openRest',
				lang: preferredLanguage,
			},
			{ 'content-type': 'application/x-www-form-urlencoded;charset=utf-8' }
		)

		console.log(res)

		if (!res.error && res.msg?.length > 0) {
			for (let i = 0; i < res.msg.length; i++) {
				const _store = res.msg[i]
				if (_store.id === storeId && _store.deliveryAllowed) {
					// store is open and allows delivery
					console.info(`store with id: ${storeId} is open and allows delivery`)
					return true
				}
			}
		}

		return false
	}

	isOldOrdersPickupStoreOpen = async (storeId, chainId) => {
		// call getBranchesList and check for the store's ID. If it's present, the store is open.
		const res = await sendRequest(
			false,
			`${getDomainByEnv()}webFlowAddress`,
			'post',
			{
				chainId,
				lang: 'en',
				type: 'getBranchesList',
				cust: 'openRest',
			},
			{
				'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
			}
		)

		// console.log(res)

		if (!res.error && res.msg?.pickupStores?.length > 0) {
			for (let i = 0; i < res.msg.pickupStores.length; i++) {
				const _store = res.msg.pickupStores[i]
				if (_store.id === storeId && _store.pickUpAllwed) {
					// store is open and allows delivery
					console.info(`store with id: ${storeId} is open and allows pickup`)
					return true
				}
			}
		}

		return false
	}

	/**
	 * Using the server's courseList which has its own JSON format, build a Cart JSON that is used by the new web-app.
	 *
	 * @param locale
	 * @param courseList
	 */
	buildCartFromServerCourseList = (courseList, reset, preferredLanguage, storage, orderType? = CONSTANTS.ORDER_TYPE.DELIVERY) => {
		console.log(courseList)

		courseList.forEach((_oldFormatCourseItem) => {
			const _additionsNew = {}
			_additionsNew[_oldFormatCourseItem.itemId] = {
				variationsChoices: [],
			}

			let recursiveVariationsChoices = null

			if (_oldFormatCourseItem.variationsChoices && _oldFormatCourseItem.variationsChoices.length > 0) {
				// top level of courseList.varicationsChoices

				recursiveVariationsChoices = this.recursivelyConvertOldVCtoNewVC(
					_oldFormatCourseItem.variationsChoices,
					_oldFormatCourseItem.variations,
					0,
					preferredLanguage
				)
			}

			const _newFormatItem = {
				itemId: _oldFormatCourseItem.itemId,
				name: getLocaleStr(_oldFormatCourseItem.title, codeToLocale[preferredLanguage]),
				total: _oldFormatCourseItem.price,
				price: _oldFormatCourseItem.price,
				quantity: _oldFormatCourseItem.count,
				en_ID: _oldFormatCourseItem.desc.en_ID || '',
				// additionsNew: recursiveVariationsChoices,
			}

			_additionsNew[_oldFormatCourseItem.itemId].variationsChoices = recursiveVariationsChoices
			_newFormatItem.additionsNew = _additionsNew

			this.addItem(_newFormatItem, reset, storage, orderType)
		})
	}

	/**
	 * The courseList JSON is from the server and contains a lot of data that is not needed in the client. Eg the
	 * client only stores the item id, price, quantity and the same for any variation choices.
	 *
	 * But the server data stores eg the description in many languages and all possible variations that can be selected!
	 *
	 * @param parentVC
	 * @param childVC
	 */
	recursivelyConvertOldVCtoNewVC = (
		oldFormatParentvariationsChoicesOuter,
		oldFormatParentVariations,
		totalVariationsChoicesPrice,
		preferredLanguage,
		childIndex
	) => {
		const childChoices = []
		const totalPrice = 0

		oldFormatParentvariationsChoicesOuter.forEach((_oldFormatCourseVariationsChoicesInner, _index) => {
			// map this array into an object which can have VCs itself

			// console.log(`********************************** _index: ${_index}, childIndex: ${childIndex}`)

			const oldFormatParentVariation = oldFormatParentVariations[_index]

			// the parent is an array (usually of 1 element)
			if (_oldFormatCourseVariationsChoicesInner.length > 0) {
				if (oldFormatParentVariation.title.quantityselection) {
					// is a quantity-select, so loop over the possible selections of quantities of options

					const vc = {}

					_oldFormatCourseVariationsChoicesInner.forEach((_oldFormatParentItemAddititionVC, _idx) => {
						const quantitySelectVC = this.formatOldQuantitySelectIntoNewFormat(_oldFormatParentItemAddititionVC, preferredLanguage)
						vc[_oldFormatParentItemAddititionVC.itemId] = quantitySelectVC
					})

					childChoices.push(vc)
				} else {
					_oldFormatCourseVariationsChoicesInner.forEach((_oldFormatParentItemAddititionVC, _idx) => {
						// const oldFormatParentVariation = oldFormatParentVariations[_index]
						// console.log(`********************************** _idx: ${_idx}`)
						const autoSelectedAndHidden =
							oldFormatParentVariation.minNumAllowed === oldFormatParentVariation.maxNumAllowed &&
							oldFormatParentVariation.defaults &&
							oldFormatParentVariation.defaults.length === oldFormatParentVariation.itemIds.length

						const vc = {}

						vc[_oldFormatParentItemAddititionVC.itemId] = {
							id: _oldFormatParentItemAddititionVC.itemId,
							name: getLocaleStr(_oldFormatParentItemAddititionVC.title, codeToLocale[preferredLanguage]),
							en_ID: _oldFormatParentItemAddititionVC.desc?.en_ID || '',
							price: _oldFormatParentItemAddititionVC.price || 0,
							// hasVariations: !!oldFormatParentVariation,
							// isVariation:
							autoSelectedAndHidden,
							// groupHasDefaults
							minNumAllowed: oldFormatParentVariation.minNumAllowed,
						}

						totalVariationsChoicesPrice += vc[_oldFormatParentItemAddititionVC.itemId].price
						// totalPrice += vc[_oldFormatParentItemAddititionVC.itemId].price

						if (_oldFormatParentItemAddititionVC.variationsChoices && _oldFormatParentItemAddititionVC.variationsChoices.length > 0) {
							const childVariationsChoices = this.recursivelyConvertOldVCtoNewVC(
								_oldFormatParentItemAddititionVC.variationsChoices,
								_oldFormatParentItemAddititionVC.variations,
								totalVariationsChoicesPrice,
								preferredLanguage,
								_index
							)

							vc[_oldFormatParentItemAddititionVC.itemId].variationsChoices = childVariationsChoices
						}

						childChoices.push(vc)
					})
				}
			} else {
				console.log(`**** pushing null to vc...`)
				childChoices.push(null)
			}
		})

		return childChoices
	}

	formatOldQuantitySelectIntoNewFormat = (_oldFormatParentItemAddititionVC, preferredLanguage) => {
		const locale = codeToLocale[preferredLanguage]
		const quantity = +_oldFormatParentItemAddititionVC.variationsChoices[0][0].title[locale]

		return {
			id: _oldFormatParentItemAddititionVC.itemId,
			name: getLocaleStr(_oldFormatParentItemAddititionVC.title, locale),
			en_ID: _oldFormatParentItemAddititionVC.desc?.en_ID || '',
			price: _oldFormatParentItemAddititionVC.price || 0,
			quantity,
		}
	}
}

export default new Cart(CartDependencies)
