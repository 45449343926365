// @ts-nocheck
// REGARDING LOADING AND PUSH NOTIFICATIONS - RJS to RN
export const WEB_VIEW_LOADED = 'WEB_VIEW_LOADED'
export const ASK_PUSH_NOTIFICATIONS = 'ASK_PUSH_NOTIFICATIONS'
export const ASK_TO_RATE_APP = 'ASK_TO_RATE_APP'

// REGARDING LOADING AND PUSH NOTIFICATIONS - RN to RJS
export const APPLICATION_STATE_CHANGED = 'APPLICATION_STATE_CHANGED'
export const SPLASH_SCREEN_HIDDEN = 'SPLASH_SCREEN_HIDDEN'
export const MOBILE_PUSH_ACCEPTED = 'MOBILE_PUSH_ACCEPTED'
export const MOBILE_PUSH_REJECTED = 'MOBILE_PUSH_REJECTED'
export const MOBILE_PUSH_CLICKED = 'MOBILE_PUSH_CLICKED'
export const PUSH_NOTIFICATION_SUPPORTED = 'PUSH_NOTIFICATION_SUPPORTED'
export const MOBILE_PUSH_ERROR = 'MOBILE_PUSH_ERROR'

// REGARDING LOCATION - RN to RJS
export const LOCATION_NOT_GRANTED = 'LOCATION_NOT_GRANTED'
export const LOCATION_GRANTED = 'LOCATION_GRANTED'

// REGARDING LOCATION - RJS to RN
export const ASK_FOR_USER_LOCATION = 'ASK_FOR_USER_LOCATION'

// OTHER - RJS TO RN
export const OPEN_NATIVE_BROWSER = 'OPEN_NATIVE_BROWSER'
export const SET_CUID = 'SET_CUID'
export const ANALYTICS_EVENT = 'ANALYTICS_EVENT'
export const INIT_EXTERNAL_URLS = 'INIT_EXTERNAL_URLS'
export const ENABLE_FIREBASE_ANALYTICS = 'ENABLE_FIREBASE_ANALYTICS'
export const UNRECOVERABLE_SERVER_ERROR = 'UNRECOVERABLE_SERVER_ERROR'
