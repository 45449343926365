import StoresContext from 'contexts/StoresContext'
import { useContext } from 'react'

export const useStores = () => {
	const context = useContext(StoresContext)
	if (!context) {
		throw new Error('useStores must be used within a StoresProvider')
	}
	return context
}
