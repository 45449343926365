// @ts-nocheck
import React from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import DoneIcon from '@material-ui/icons/Done'
import CloseIcon from '@material-ui/icons/Close'
import TypographyPro from 'themes/TypographyPro'
import { ORDER_TYPES } from 'utils/constants'
import { SUPPORTED_ORDER_TYPES } from 'utils/availabilityUtils'
import { getLocalHourString, getLocalMonthName, getLocalWeekdayName, FULL_DAY_MILLISECONDS } from 'utils/timeUtils'
import { getValueByLang, getTranslatedTextByKey } from 'utils/language'

// Information about the Availability Inormation data object returned from the backend: https://tictuk.atlassian.net/l/cp/exg1cDH2

interface DeliveryProps {
	$isAvailable?: string | number | boolean
	$status?: boolean
}

const StoreAvailabilityInformationContainer = styled.div`
	margin: 15px 0 -2px;
`
const OrderTypeRow = styled(Grid)`
	margin: 0 0 2px;
`
const DoneIconStyle = styled(DoneIcon)`
	width: 15px;
	height: 15px;
	color: var(--approved);
	margin-inline-end: 5px;
	align-self: top;
	margin-top: 2px;
`

const CloseIconStyle = styled(CloseIcon)`
	width: 12px;
	height: 12px;
	margin-inline-end: 8px;
	color: var(--disable);
	align-self: center;
`

const StatusIconContainer = styled(Grid)``
const OrderTypeStatusContainer = styled(Grid)`
	width: auto;
`
const OrderTypeContainer = styled(Grid)`
	margin-inline-end: 20px;
`
const OptionText = styled(TypographyPro)`
	white-space: nowrap;
	color: ${(props: DeliveryProps) => (props.$isAvailable ? 'var(--fonts)' : 'var(--disable)')};
	flex-direction: column;
	text-align: end;
`

const OpenClosedBanner = styled(TypographyPro)`
	padding-block-end: 5px;
	color: var(--fonts);
`

const OpenClosedCircle = styled.div<DeliveryProps>`
	background-color: ${(props: DeliveryProps) => (props.$status ? 'var(--approved)' : 'var( --err)')};
	width: 10px;
	height: 10px;
	border-radius: 50%;
	display: inline-block;
	margin-inline-end: 10px;
	position: relative;
`

interface UntilObjectStruct {
	message: string | null
	timestamp: number | null
	reopensTimestamp: number | null
}
interface OrderAvailabilityDataProps {
	availableNow: boolean
	until: Record<string, UntilObjectStruct>
}
type OrderAvailabilityData = Record<string, OrderAvailabilityDataProps>
interface StoreAvailabilityDataProps {
	open: boolean
	preOrder: boolean
}

interface StoreAvailabilityInformationProps {
	locale: string
	lang: string
	availabilityData: StoreAvailabilityDataProps & OrderAvailabilityData
}

// Given an object with UntilObjectStruct: get a compiled message to display
const getTextFromUntilObject = (untilObject: UntilObjectStruct, messagePrefix: string, open: boolean, locale: string) => {
	let message = ''
	if (untilObject.message) {
		message = untilObject.message
	} else if (untilObject.timestamp) {
		message = messagePrefix
		const date = new Date(untilObject.timestamp)
		if (!open) {
			const timespan = untilObject.timestamp - new Date().getTime()
			// If it's closed for more than a week - we should also show the month name, date-day number, and week-day name - on the information message
			if (timespan > FULL_DAY_MILLISECONDS * 7) {
				message += ` ${getLocalMonthName(date, locale)} ${date.getDate()}, ${getLocalWeekdayName(date, locale)} `
				// If it's closed for more than a day - we should also show the week-day name on the information message
			} else if (timespan > FULL_DAY_MILLISECONDS) {
				message += ` ${getLocalWeekdayName(date, locale)}`
			}
		}
		message += ` ${getLocalHourString(date, locale, false)}`
	}

	return message
}
/**
 * Returns the availability content for an order type
 * @param locale
 * @param lang
 * @param type
 * @param data
 */
const getAvailabilityContent = (locale: string, lang: string, orderType: string, availData: OrderAvailabilityData) => {
	const untilObj: UntilObjectStruct = getValueByLang(availData[orderType]?.until, lang)
	const timestamp: number | null = untilObj?.timestamp
	const reopensTimestamp: number | null = untilObj?.reopensTimestamp
	const availableNow = availData[orderType]?.availableNow
	const closesMessage: string = getTextFromUntilObject(
		untilObj,
		availableNow
			? getTranslatedTextByKey('eCommerce.storeLocator.closes', 'Closes')
			: getTranslatedTextByKey('eCommerce.storeLocator.opens', 'Opens'),
		availableNow,
		locale
	)

	if (availableNow && timestamp && reopensTimestamp) {
		const formattedReopensTime = getLocalHourString(new Date(reopensTimestamp), locale, false)
		return (
			<>
				<div>{closesMessage}</div>
				<div>
					{getTranslatedTextByKey('eCommerce.storeLocator.reopens', 'reopens')} {formattedReopensTime}
				</div>
			</>
		)
	}

	return closesMessage
}

// Return the general availability string for a store
const getStoreAvailabilityText = (locale: string, lang: string, availData: StoreAvailabilityDataProps & OrderAvailabilityData): string => {
	const { open } = availData
	const storeUntil = availData[ORDER_TYPES.STORE] ? getValueByLang(availData.store.until, lang) : null
	let untilObj = storeUntil

	// If we have delivery information
	if (Object.hasOwn(availData, ORDER_TYPES.DELIVERY)) {
		const deliveryAvailData = availData[ORDER_TYPES.DELIVERY]
		const deliveryUntil = getValueByLang(deliveryAvailData.until, lang)
		// We check if delivery availability "wins" over store availability.
		// If store is "open all day" - then it surely wins, otherwise, if delivery is available now:
		if (open && deliveryAvailData.availableNow && !storeUntil.message) {
			// If store is not "all day" but delivery is available all day (i.e. it has a message) - then it is stronger than store hours --- use it.
			if (deliveryUntil.message) {
				untilObj = deliveryUntil
			}

			// If delivery is available and ends at a later time than store hours - it wins - use it.
			if (deliveryUntil.timestamp && deliveryUntil.timestamp > storeUntil.timestamp) {
				untilObj = deliveryUntil
			}
		}
		// If open is false --- this also means that delivery is currently not available for sure (it is part of what dictates the value of that property),
		// then only check if delivery starts sooner - if it does - it wins - use it.
		else if (!open && deliveryUntil.timestamp && deliveryUntil.timestamp < storeUntil.timestamp) {
			untilObj = deliveryUntil
		}
	}

	return untilObj
		? getTextFromUntilObject(
				untilObj,
				open
					? getTranslatedTextByKey('eCommerce.storeLocator.openCloses', 'Open - Closes')
					: getTranslatedTextByKey('eCommerce.storeLocator.closedOpens', 'Closed - Opens'),
				open,
				locale
		  )
		: ''
}

const isStoreOnly = (availabilityData: OrderAvailabilityData): boolean => {
	if (!Object.hasOwn(availabilityData, ORDER_TYPES.STORE)) {
		return false
	}
	return !SUPPORTED_ORDER_TYPES.some((orderType: string) => orderType !== ORDER_TYPES.STORE && Object.hasOwn(availabilityData, orderType))
}

const StoreAvailabilityInformation = ({ lang, locale, availabilityData }: StoreAvailabilityInformationProps) => {
	locale = locale.replace('_', '-')
	if (typeof navigator !== 'undefined') {
		locale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language || locale
	}
	const getAvailabilityDetails = () =>
		SUPPORTED_ORDER_TYPES.map((orderType: string, index: number) => {
			if (availabilityData && Object.hasOwn(availabilityData, orderType)) {
				const available = availabilityData[orderType]?.availableNow
				const storeOnly = isStoreOnly(availabilityData)

				return (
					<OrderTypeRow container wrap="nowrap" key={index} data-testid="order-type-row">
						<OrderTypeStatusContainer wrap="nowrap" item container data-testid="order-type-status-container">
							<StatusIconContainer item container>
								{available ? <DoneIconStyle data-testid="check-icon" /> : <CloseIconStyle data-testid="close-icon" />}
							</StatusIconContainer>
							<OrderTypeContainer item>
								<OptionText variant="BodySmall" $isAvailable={available} data-testid="store-availability-option-text">
									{storeOnly
										? getTranslatedTextByKey('eCommerce.storeLocator.inStoreOnly', 'In store only')
										: getTranslatedTextByKey(
												`eCommerce.orderType.${orderType}`,
												`${orderType[0].toUpperCase() + orderType.substring(1)}`
										  )}
								</OptionText>
							</OrderTypeContainer>
						</OrderTypeStatusContainer>
						<Grid item xs container justifyContent="flex-end">
							{!storeOnly && (
								<OptionText variant="BodySmall" $isAvailable={available} component="div">
									{getAvailabilityContent(locale, lang, orderType, availabilityData)}
								</OptionText>
							)}
						</Grid>
					</OrderTypeRow>
				)
			}
		})

	const availabilityDetails = getAvailabilityDetails()
	const openInformationMessage: string = getStoreAvailabilityText(locale, lang, availabilityData)

	return (
		<StoreAvailabilityInformationContainer data-testid="store-availability-info-container">
			<OpenClosedBanner variant="BodyRegularHighlighted" component="div">
				<OpenClosedCircle $status={availabilityData.open} data-testid="open-closed-circle" />
				{openInformationMessage}
			</OpenClosedBanner>
			{availabilityDetails}
		</StoreAvailabilityInformationContainer>
	)
}

export default StoreAvailabilityInformation
