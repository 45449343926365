import type { CookiesStatic } from 'js-cookie'

const NEXT_COOKIE_STATE_KEY = 'nextCookiesState'

/**
 * The object nextCookiesState provides methods to manipulate a "state" from
 * both the client or the server in next.js. This state is an object stored as a string in a cookie
 * of the same name. We abstracted it so no one is tempted to use values from there.
 */
const nextCookiesState = {
	fromClient: {
		set: (key: string, value: string, Cookies: CookiesStatic) => {
			let parsedState: Record<string, string> = {}

			const rawState = Cookies.get(NEXT_COOKIE_STATE_KEY)

			if (typeof rawState === 'string' && rawState.length !== 0) {
				parsedState = JSON.parse(rawState)
			}

			parsedState[key] = value

			Cookies.set(NEXT_COOKIE_STATE_KEY, JSON.stringify(parsedState), { expires: 360 })
		},

		remove: (key: string, Cookies: CookiesStatic) => {
			let parsedState: Record<string, string> = {}

			const rawState = Cookies.get(NEXT_COOKIE_STATE_KEY)

			if (typeof rawState === 'string' && rawState.length !== 0) {
				parsedState = JSON.parse(rawState)
			}

			delete parsedState[key]

			Cookies.set(NEXT_COOKIE_STATE_KEY, JSON.stringify(parsedState), { expires: 360 })
		},

		get: (key: string, Cookies: CookiesStatic): string | undefined => {
			const rawState = Cookies.get(NEXT_COOKIE_STATE_KEY)

			if (!rawState) {
				return
			}

			const parsedState = JSON.parse(rawState)

			return parsedState[key] || null
		},
	},
}

export default nextCookiesState
