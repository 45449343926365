export const ORDERS_IDS_CONFIRMATION_SENT = 'orderIdsThatAnalyticsEventWasSentFor'
export const isOrderConfirmationAnalyticsEventSentForOrderId = (orderId: string): boolean => {
	const orderIdsLocalStorageItem = localStorage.getItem(ORDERS_IDS_CONFIRMATION_SENT)

	if (!orderIdsLocalStorageItem) {
		return false
	}

	try {
		const orderIdsThatConfirmationWasSent = JSON.parse(orderIdsLocalStorageItem)
		return orderIdsThatConfirmationWasSent.includes(orderId)
	} catch (err) {
		console.error(
			`The order IDs list in local storage that the confirmation to analytics was sent - has corrupted value: "${orderIdsLocalStorageItem}"`
		)
		localStorage.removeItem(ORDERS_IDS_CONFIRMATION_SENT)
		return false
	}
}

export const addOrderIdToListOfOrderConfirmationAnalyticsEventSent = (orderId: string): boolean => {
	const orderIdsLocalStorageItem = localStorage.getItem(ORDERS_IDS_CONFIRMATION_SENT)

	if (!orderIdsLocalStorageItem) {
		localStorage.setItem(ORDERS_IDS_CONFIRMATION_SENT, JSON.stringify([orderId]))
		return true
	}

	try {
		const orderIdsThatConfirmationWasSent = JSON.parse(orderIdsLocalStorageItem)
		orderIdsThatConfirmationWasSent.push(orderId)
		localStorage.setItem(ORDERS_IDS_CONFIRMATION_SENT, JSON.stringify(orderIdsThatConfirmationWasSent))
		return true
	} catch (err) {
		console.error(
			`The order IDs list in local storage that the confirmation to analytics was sent - has corrupted value: ß"${orderIdsLocalStorageItem}"`
		)
		localStorage.removeItem(ORDERS_IDS_CONFIRMATION_SENT)
		return false
	}
}
