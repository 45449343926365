/**
 * Require the presence of static props or functions in a class.
 * Works just like the 'implements' keyword, but for static props.
 *
 * @example
 * interface StaticProps {
 *   someStaticProp: number;
 *   someStaticFunc(num: number): string;
 * }
 *
 * // Ignore the ; it's just for fomratting in the comment
 * ;@staticImplements<StaticProps>()
 * // Error! someStaticFunc and someStaticProp are missing
 * class InvalidClass {
 *    someStaticProp: number = 5; // Notice we're missing the 'static' keyword
 * }
 *
 * // Adding the static props fixes the errors!
 * ;@staticImplements<StaticProps>()
 * class ValidClass {
 *   static someStaticProp: number = 5;
 *
 *   static someStaticFunc(num: number) {
 *     return number.toString();
 *   }
 * }
 */
export function staticImplements<StaticProps>() {
	return <U extends (new () => unknown) & StaticProps>(constructor: U) => constructor
}

/**
 * Evaluates the fields that aren't shared between the two types
 *
 * @example
 * interface Person {
 *   name: string;
 *   age: number;
 *   occupation: string;
 * }
 *
 * interface Animal {
 *   name: string;
 *   age: number;
 *   breed: string;
 * }
 *
 * type Result = UniqueFields<Person, Animal>; // 'occupation' | 'breed'
 */
export type UniqueFields<T, K> = Exclude<keyof T, keyof K> | Exclude<keyof K, keyof T>

/**
 * Merges a child type into the root type,
 * making all the fields of the child that aren't shared with the parent optional.
 *
 * @example
 * interface Person {
 *   name: string;
 *   age: number;
 *   occupation: string;
 * }
 *
 * interface Animal {
 *   name: string;
 *   age: number;
 *   breed: string;
 * }
 *
 * type Result = MergeTypesPartial<Person, Animal>; // { name: string; age: number; occupation: string; breed?: string }
 */
export type MergeTypesPartial<Root, Child> = Root & Partial<Pick<Child, Extract<UniqueFields<Root, Child>, keyof Child>>>

export type JustOneProp<T extends object> = {
	[K in keyof T]: { [P in K]: T[P] } & { [P in Exclude<keyof T, K>]?: never } extends infer O ? { [P in keyof O]: O[P] } : never
}[keyof T]
