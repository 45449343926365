// @ts-nocheck
import {
	sendRequest,
	codeToLocale,
	getGT,
	getTranslatedTextByKey,
	isMobile,
	cloneObj,
	formatPrice,
	isMenuPage,
	removeHashFromUrl,
	buildOrderItem,
	routeToPage,
} from 'utils/utils'
import { CONSTANTS, ORDER_TYPES, APPID_TO_CHANNEL_MAP, ORDER_TYPES_QUERY_PARAMS, Page } from 'utils/constants'
import Infra from 'mobx/Infra'
import User from 'mobx/User'
import queryString from 'query-string'
import { sendCustomEvent } from 'utils/analytics/analytics'
import { startCase } from 'lodash-es'
import ItemAdditions from 'mobx/ItemAdditions'
import type { CouponToApply } from 'mobx/CouponFlow'
import type { Coupon } from 'types/Coupons'
import { OfferType } from 'types/Coupons'
import CouponError, { ErrorCode } from 'mobx/Coupons/errors'

export const validateCouponChannel = (discount: unknown): boolean => {
	try {
		if (!Object.hasOwnProperty.call(discount, 'channels') || !Array.isArray(discount.channels) || discount.channels?.length === 0) {
			return true
		}

		if (discount.channels.includes(APPID_TO_CHANNEL_MAP[User.session.appid])) {
			return true
		}

		return false
	} catch (error) {
		return false
	}
}

export const validateCouponOrderType = (discount, parsedQs) => {
	try {
		if (!Object(discount).hasOwnProperty('orderTypes') || !Array.isArray(discount.orderTypes) || discount.orderTypes?.length === 0) {
			return true
		}

		if (
			(User.getOrderType() === CONSTANTS.DELIVERY_METHODS.DELIVERY || parsedQs?.ot === ORDER_TYPES_QUERY_PARAMS.DELIVERY_CLASS) &&
			discount.orderTypes.includes(ORDER_TYPES.DELIVERY)
		) {
			return true
		}

		if (
			(User.getOrderType() === CONSTANTS.DELIVERY_METHODS.PICKUP || parsedQs.ot === ORDER_TYPES_QUERY_PARAMS.PEAK_UP_CLASS) &&
			discount.orderTypes.includes(ORDER_TYPES.PEAKUP)
		) {
			return true
		}
		return false
	} catch (error) {
		return false
	}
}

export const buildOrderTypeErrorText = (discount) => {
	const { orderTypes } = discount

	const discountChannelIncludesDelivery = orderTypes.includes(ORDER_TYPES.DELIVERY)
	const discountChannelIncludesPickup = orderTypes.includes(ORDER_TYPES.PEAKUP)
	const discountChannelIncludesPickupAndDelivery = discountChannelIncludesDelivery && discountChannelIncludesPickup

	if (discountChannelIncludesPickupAndDelivery) {
		return ''
	}

	if (discountChannelIncludesDelivery) {
		return ` ${getTranslatedTextByKey(ORDER_TYPES.DELIVERY, 'Delivery')?.toLowerCase()}`
	}

	if (discountChannelIncludesPickup) {
		return ` ${getTranslatedTextByKey(ORDER_TYPES.PEAKUP, 'Pickup')?.toLowerCase()}`
	}
}

export const buildChannelsErrorText = (discount: { channels: string[] }) => {
	let message = ''
	const channelIncludesApp = discount.channels.includes('App')
	const channelIncludesWeb = discount.channels.includes('Web')
	const channelIncludesTelegram = discount.channels.includes('Telegram')
	const channelIncludesWhatsapp = discount.channels.includes('WhatsApp')
	const channelIncludesMessenger = discount.channels.includes('Messenger')
	const channelIncludesAnyChatChannel = channelIncludesTelegram || channelIncludesWhatsapp || channelIncludesMessenger

	if (channelIncludesApp && channelIncludesWeb && channelIncludesAnyChatChannel) {
		return ''
	}

	if (channelIncludesApp) {
		message += ` ${getTranslatedTextByKey('application', 'application')}`
	}

	if (channelIncludesWeb) {
		if (channelIncludesApp && !channelIncludesAnyChatChannel) {
			message += ` and`
		} else if (channelIncludesApp) {
			message += `,`
		}
		message += ` ${getTranslatedTextByKey('website', 'website')}`
	}

	if (channelIncludesTelegram) {
		if (message !== '' && !channelIncludesWhatsapp && !channelIncludesMessenger) {
			message += ` and`
		} else if (channelIncludesApp || channelIncludesWeb) {
			message += `,`
		}

		message += ` ${getTranslatedTextByKey('telegram', 'telegram')}`
	}

	if (channelIncludesWhatsapp) {
		if (message !== '' && !channelIncludesMessenger) {
			message += ` and`
		} else if (message !== '') {
			message += `,`
		}

		message += ` ${getTranslatedTextByKey('whatsapp', 'whatsapp')}`
	}

	if (channelIncludesMessenger) {
		if (message !== '') {
			message += ' and'
		}

		message += ` ${getTranslatedTextByKey('messenger', 'messenger')}`
	}

	return message
}

export const showUserErrorIfCouponOrderTypeOrChannelInvalid = (discount, validChannel, validOrderType) => {
	let message = getTranslatedTextByKey('validation.couponChannelInvalid', 'This exclusive coupon is only available through')

	if (!validOrderType) {
		const orderTypeErrorText = buildOrderTypeErrorText(discount)
		message += orderTypeErrorText
	}

	if (!validOrderType && !validChannel) {
		message += ` ${getTranslatedTextByKey('validation.couponChannelInvalidSecondPart', 'in our')}`
	} else if (!validChannel) {
		message += ` ${getTranslatedTextByKey('our', 'our')}`
	}

	if (!validChannel) {
		const channelErrorText = buildChannelsErrorText(discount)
		message += channelErrorText
	}

	const isMenu = window.location.pathname.includes('/menu') && !isMobile()
	const isSummary = window.location.pathname.includes('/summary') || (isMenu && !isMobile())
	const isDeals = window.location.pathname.includes('/deals') && !isMobile()

	Infra.showSnackbar({
		snackId: 'cart',
		message,
		status: 'error',
		isAttachedToElement: isDeals || isMenu || isSummary,
	})
}

const couponUtils = {
	cart: null,

	setCart: (cart) => {
		couponUtils.cart = cart
	},

	apply: async (couponToApply: CouponToApply, menu, storage) => {
		console.log('couponUtils.apply called(): couponToApply: ', couponToApply)
		const { coupon } = couponToApply

		if (coupon.offerType === OfferType.ITEM) {
			const codesMap = menu?.codesMap || {}
			console.log(codesMap)
			const codesMapKeyValue = Object.entries(codesMap)
			const couponCodeLowerCase = coupon.code.toLowerCase()
			console.log(couponCodeLowerCase)

			const orderType = User.getOrderType()
			console.log('orderType', orderType)
			let appendSuffix = ''
			if (orderType === CONSTANTS.DELIVERY_METHODS.DELIVERY) {
				appendSuffix = '§§d'
			} else if (orderType === CONSTANTS.DELIVERY_METHODS.PICKUP) {
				appendSuffix = '§§p'
			}

			console.log(appendSuffix)

			const [, foundCodeMap] =
				codesMapKeyValue.find(
					([code]) => code.toLowerCase() === couponCodeLowerCase || code.toLowerCase() === couponCodeLowerCase + appendSuffix
				) || []

			if (foundCodeMap) {
				console.log('foundCodeMap', foundCodeMap)
				console.log('menu.items', menu.items)
				console.log('menu.items[foundCodeMap]', menu.items[foundCodeMap])
				const item = menu.items[foundCodeMap]
				console.log(`couponUtils.apply called(): item: `, item)
				couponUtils.redeemCoupon(buildOrderItem(item), menu, storage)
			} else {
				const notFoundMsg = new CouponError('Coupon not found', ErrorCode.COUPON_NOT_FOUND).text()
				Infra.setNotification({
					open: true,
					title: notFoundMsg,
					okAction: () => {
						Infra.closeNotification()
					},
					onClose: () => {
						Infra.closeNotification()
					},
				})
			}
		} else {
			couponUtils.redeemDiscount(couponToApply, menu, storage)
		}
	},

	redeemDiscount: async (couponToApply: CouponToApply, menu, storage) => {
		console.log(`couponUtils.redeemDiscount called(): couponToApply: `, couponToApply)
		const qParams = queryString.parse(window.location.search)
		qParams.wru = qParams.wru ?? Infra.appParams?.wru ?? 'https://fb.tictuk.com/'

		const isMenu = window.location.pathname.includes('/menu') && !isMobile()
		const isDeals = window.location.pathname.includes('/deals') && !isMobile()

		const _data = {
			value: JSON.stringify({
				text: CONSTANTS.redeemDiscountKey,
				code: couponToApply.coupon.code,
			}),
			cust: qParams.cust,
			request: qParams.request,
		}

		try {
			// If the discount is internal, we receive a string
			// If the discount is from an integration, we receive a json
			const _response = await sendRequest(
				false,
				`${qParams.wru}check_field`,
				'post',
				JSON.stringify(_data),
				{
					Accept: 'application/json',
					'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
				},
				true,
				10000
			)

			const msg = _response
			let discount = {}
			if (msg && !msg.error) {
				discount = {
					...msg.discount,
				}

				sendCustomEvent({
					category: 'discount',
					action: 'redeem success',
					label: discount.code,
				})

				// now show permanent discount message
				couponUtils.cart.addDiscount(discount, storage)
				const lang = codeToLocale[User.preferredLanguage ?? 'en']
				Infra.showSnackbar({
					key: 'cart',
					snackId: 'cart',
					message: `'${couponToApply.coupon.title[lang]}' ${getTranslatedTextByKey('wasAddedToCart')}`,
					status: 'success',
					isAttachedToElement: isDeals || isMenu,
				})

				// now that a discount was added, get the new total from the server (the discount may not have been appliged pending its requirements)
				if (menu) {
					getGT(
						couponUtils.cart.items,
						menu.items,
						isMenuPage(),
						(gt, charges, addedItemsFromDiscounts, deliveryInfo, response, removeDiscountByCalcGt) => {
							removeDiscountByCalcGt(couponUtils.cart, response.appliedDiscounts)
							couponUtils.cart.setServerGrandTotal(gt)
							couponUtils.cart.setServerCharges(charges)
							couponUtils.cart.setServerAddedItemsFromDiscounts(addedItemsFromDiscounts)
							couponUtils.cart.setServerDeliveryInfo(deliveryInfo)
							couponUtils.cart.setServerSpecialDiscount(response)
						}
					)
				}
			} else if (msg && msg.msg) {
				// An error occured BUT the server returned a message
				Infra.showSnackbar({
					snackId: 'cart',
					message: typeof msg?.msg === 'string' ? msg.msg : JSON.stringify(msg?.msg ?? {}),
					status: 'warning',
					isAttachedToElement: isDeals || isMenu,
				})

				sendCustomEvent({
					category: 'coupon',
					action: 'redeem failure',
					label: msg.msg,
				})
			} else {
				// 200 status but no msg.msg
				Infra.showSnackbar({
					snackId: 'cart',
					message: getTranslatedTextByKey('webviewFlow.serverError', 'Server error! Please add your coupon again.'),
					status: 'error',
					isAttachedToElement: isDeals || isMenu,
				})
			}
		} catch (e) {
			console.error(e)
			Infra.showSnackbar({
				snackId: 'cart',
				message: getTranslatedTextByKey('webviewFlow.serverError', 'Server error! Please add your coupon again.'),
				status: 'error',
				isAttachedToElement: isDeals || isMenu,
			})
		} finally {
			removeHashFromUrl()
		}
	},

	redeemCoupon: async (couponItem, menu, storage) => {
		console.log(`couponUtils.redeemCoupon called(): couponItem: `, couponItem)
		console.log(`couponUtils.redeemCoupon called(): menu: `, menu)
		const qParams = queryString.parse(window.location.search)
		qParams.wru = qParams.wru ?? Infra.appParams?.wru ?? 'https://fb.tictuk.com/'
		const locale = couponUtils.cart.user?.preferredLanguage ? codeToLocale[couponUtils.cart.user?.preferredLanguage] : menu.locale
		const isMenu = window.location.pathname.includes('/menu') && !isMobile()
		const isDeals = window.location.pathname.includes('/deals') && !isMobile()

		try {
			const value = encodeURIComponent(JSON.stringify({ text: CONSTANTS.redeemCouponKey, orderItem: couponItem, courseList: [] }))

			const _data = `value=${value}&cust=${qParams.cust}&request=${qParams.request}`
			qParams.wru = qParams.wru.endsWith('/') ? qParams.wru : `${qParams.wru}/`

			const msg = await sendRequest(false, `${qParams.wru}check_field`, 'post', _data, {
				'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
			})

			if (msg && !msg.error) {
				console.log(msg)
				// Infra.showSnackbar({
				// 	snackId: 'cart',
				// 	message: `Coupon found! ${(couponItem?.title ?? {})[locale]} - ${formatPrice(couponItem.price, menu.currency, menu.countryCode)}`,
				// 	status: 'success',
				// 	isAttachedToElement: isDeals || isMenu,
				// })

				sendCustomEvent({
					category: 'coupon',
					action: 'redeem success',
					label: couponItem.couponCode,
				})

				// add item to Cart if it has no options OR show the menu-item page
				const item = cloneObj(menu.items[couponItem.itemId])

				if (item.variations && item.variations.length > 0) {
					// open to eg allow the user to choose their pizza toppings
					couponUtils.cart.openMenuItem(item, ItemAdditions.postInit)
					window.history.pushState(
						'object or string',
						'fast checkout',
						`${window.location.pathname}${window.location.search}${CONSTANTS.URL_QUERY_HASH_KEY.MENU_ITEM}_${item.id}`
					)
				} else {
					const cartItem = {
						itemId: item.id,
						additions: null,
						quantity: 1,
						price: parseInt(item.price),
						total: parseInt(item.price),
					}

					// TODO-SERGEY: use the addItemToCart method from cardUtils
					couponUtils.cart.addItem(cartItem, ItemAdditions.reset, storage)

					getGT(
						couponUtils.cart.items,
						menu.items,
						isMenuPage(),
						(gt, charges, addedItemsFromDiscounts, deliveryInfo, response, removeDiscountByCalcGt) => {
							removeDiscountByCalcGt(couponUtils.cart, response.appliedDiscounts)
							couponUtils.cart.setServerGrandTotal(gt)
							couponUtils.cart.setServerCharges(charges)
							couponUtils.cart.setServerAddedItemsFromDiscounts(addedItemsFromDiscounts)
							couponUtils.cart.setServerDeliveryInfo(deliveryInfo)
							couponUtils.cart.setServerSpecialDiscount(response)
						}
					)

					const title = startCase(((item.title ?? {})[locale] ?? '').toLowerCase())

					// show another message that the item was auto-added to the cart since it has no variations
					Infra.showSnackbar({
						snackId: 'cart',
						message: `'${title}' ${getTranslatedTextByKey('wasAddedToCart')}`,
						status: 'success',
						isAttachedToElement: isDeals || isMenu,
					})
				}
			} else if (msg && msg.msg) {
				Infra.showSnackbar({
					snackId: 'cart',
					message: `${msg.msg}`,
					status: 'warning',
					isAttachedToElement: isDeals || isMenu,
				})

				sendCustomEvent({
					category: 'coupon',
					action: 'redeem failure',
					label: msg.msg,
				})
			}

			Infra.setLoading(false)
		} catch (e) {
			console.error(e)
			Infra.setLoading(false)
		}
	},

	unapply: async (couponToUnapply: Coupon, menu, storage) => {
		const qParams = queryString.parse(window.location.search)
		qParams.wru = qParams.wru ?? Infra.appParams?.wru ?? 'https://fb.tictuk.com/'
		qParams.cust = qParams.cust ?? Infra.appParams?.cust
		qParams.request = qParams.request ?? localStorage.getItem('sessionId')

		const isMenu = routeToPage(window.location.pathname) === Page.MENU && !isMobile()
		const isDeals = routeToPage(window.location.pathname) === Page.DEALS && !isMobile()
		const code = couponToUnapply.parentCode || couponToUnapply.code

		const _data = {
			value: JSON.stringify({
				text: CONSTANTS.removeDiscountKey,
				code,
			}),
			cust: qParams.cust,
			request: qParams.request,
		}

		try {
			// If the discount is internal, we receive a string
			// If the discount is from an integration, we receive a json
			const _response = await sendRequest(
				false,
				`${qParams.wru}check_field`,
				'post',
				JSON.stringify(_data),
				{
					Accept: 'application/json',
					'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
				},
				true,
				3000
			)

			const msg = _response
			if (msg && !msg.error) {
				if (couponToUnapply.integrationDiscounts) {
					couponToUnapply = {
						...msg.msg,
						integrationDiscounts: true,
					}
				}

				sendCustomEvent({
					category: 'discount',
					action: 'remove success',
					label: couponToUnapply.code,
				})

				// now show permanent discount message
				couponUtils.cart.removeDiscount(
					{
						...couponToUnapply,
						code,
					},
					storage
				)

				// now that a discount was added, get the new total from the server (the discount may not have been appliged pending its requirements)
				if (menu) {
					getGT(
						couponUtils.cart.items,
						menu.items,
						isMenuPage(),
						(gt, charges, addedItemsFromDiscounts, deliveryInfo, response, removeDiscountByCalcGt) => {
							removeDiscountByCalcGt(couponUtils.cart, response.appliedDiscounts)
							couponUtils.cart.setServerGrandTotal(gt)
							couponUtils.cart.setServerCharges(charges)
							couponUtils.cart.setServerAddedItemsFromDiscounts(addedItemsFromDiscounts)
							couponUtils.cart.setServerDeliveryInfo(deliveryInfo)
							couponUtils.cart.setServerSpecialDiscount(response)
						}
					)
				}
			} else if (msg && msg.msg) {
				// An error occured BUT the server returned a message
				Infra.showSnackbar({
					snackId: 'cart',
					message: typeof msg?.msg === 'string' ? msg.msg : JSON.stringify(msg?.msg ?? {}),
					status: 'warning',
					isAttachedToElement: isDeals || isMenu,
				})

				sendCustomEvent({
					category: 'coupon',
					action: 'remove failure',
					label: msg.msg,
				})
			} else {
				// 200 status but no msg.msg
				Infra.showSnackbar({
					snackId: 'cart',
					message: getTranslatedTextByKey('webviewFlow.serverError', 'Server error! Please remove your coupon again.'),
					status: 'error',
					isAttachedToElement: isDeals || isMenu,
				})
			}
		} catch (e) {
			console.error(e)
			Infra.showSnackbar({
				snackId: 'cart',
				message: getTranslatedTextByKey('webviewFlow.serverError', 'Server error! Please remove your coupon again.'),
				status: 'error',
				isAttachedToElement: isDeals || isMenu,
			})
		}
	},

	showNotValidSnackbar: (code) => {
		const isMenu = window.location.pathname.includes('/menu') && !isMobile()
		const isSummary = window.location.pathname.includes('/summary') || (isMenu && !isMobile())
		const isDeals = window.location.pathname.includes('/deals') && !isMobile()

		Infra.showSnackbar({
			snackId: 'cart',
			message: getTranslatedTextByKey('webviewFlow.couponError'),
			status: 'error',
			isAttachedToElement: isDeals || isMenu || isSummary,
		})

		sendCustomEvent({
			category: 'coupon',
			action: 'redeem failure',
			label: code,
		})
	},
}

export default couponUtils
