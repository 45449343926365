import StoresContext from 'contexts/StoresContext'
import type { Stores } from 'contexts/StoresContext'
import React from 'react'
import type { ReactNode } from 'react'

interface StoresProviderProps {
	children: ReactNode
	stores: Stores
}

export const StoresProvider: React.FC<StoresProviderProps> = ({ children, stores }) => (
	<StoresContext.Provider value={stores}>{children}</StoresContext.Provider>
)
