import React from 'react'
import styled from 'styled-components'
import Skeleton from '@material-ui/lab/Skeleton'

interface Props {
	showCode?: boolean
	showQr?: boolean
}

const Qr = styled(Skeleton).attrs({ variant: 'rect' })`
	position: relative;
	width: 45%;
	height: 191px;
`
const Code = styled(Skeleton).attrs({ variant: 'rect' })`
	border-radius: 60px;
	width: 100%;
	height: 2.5rem;
`

const CouponCodeSkeleton: React.FC<Props> = ({ showCode = false, showQr = false }) => (
	<>
		{showCode && <Code />}
		{showQr && <Qr />}
	</>
)

export default CouponCodeSkeleton
