// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import Grid from '@material-ui/core/Grid'
import ItemQuantityForNewDataFormat from './ItemQuantityForNewDataFormat'
import { codeToLocale, getLocaleStr } from '../../../utils/utils'
import { StoreContext } from '../../../contexts/StoreContext'
import nestedProperty from 'nested-property'

/**
 * This is a renewed version of the old ItemQuantities
 * In order to avoid bugs and unnecessary problems I made a copy of the old file
 * And added customize changes to it according to the requirements
 * The most fundamental change is a new data structure that comes from the database as soon as the same version is used
 * Therefore the handling at the code level is also different
 * This is the safe way to handle it without involving the code of the old version
 */

const ItemQuantitiesForNewDataFormat = inject(
	'ItemAdditions',
	'User',
	'Cart'
)(
	observer(({ User, Cart, ItemAdditions, variation, keys, options, prices, currency }) => {
		const { store } = useContext(StoreContext)
		const [values, setValues] = useState({
			items: {
				totalQuantity: 0,
				min: 0,
				max: 0,
				items: [],
			},
		})
		const rest = store.data
		const locale = User.preferredLanguage ? codeToLocale[User.preferredLanguage] : rest.locale
		const list = variation

		useEffect(() => {
			const editMode = Cart.editItem

			/**
			 * Block contains information about the quantity select block
			 * totalQuantity is the total quantity of selected items for the block
			 * min is the minimum required amount to be selected
			 * max is the maximum amount to be selected
			 * items is the computed list of all options of the block
			 */
			let block = {
				totalQuantity: 0,
				min:
					list?.title?.minimumAmountRequired === undefined || list?.title?.minimumAmountRequired > list?.title?.maximumAmountRequired
						? 0
						: list?.title?.minimumAmountRequired,
				max: list?.title?.maximumAmountRequired === undefined ? undefined : list?.title?.maximumAmountRequired,
			}

			// This code is building the existingAdditions object. It contains all options selected by
			// the user when they open the item from the cart to edit it
			let existingAdditions = null
			const optionKeyNew = `${keys}`
			if (editMode) {
				if (nestedProperty.has(ItemAdditions.additionsNew, optionKeyNew)) {
					existingAdditions = nestedProperty.get(ItemAdditions.additionsNew, optionKeyNew)
				} else {
					console.log(`***** Item doesn't contain the key: '${optionKeyNew}'`)
				}
			}

			/**
			 list is the data we got from parent about the variation
			 this object included the default values, names, ids and etc...
			 this double question marks it׳s just to be more safe
			 if we don׳t get any data from parent so we wo׳nt break anything that way
			 all the fallbacks here are empty objects
			 */
			for (const item in ((list ?? {}).title ?? {}).itemOptions ?? {}) {
				let optionIsInAdditions = false

				if (editMode) {
					optionIsInAdditions = !!(existingAdditions ?? {})[item]
				}

				const quantityForOption = editMode
					? optionIsInAdditions
						? existingAdditions[item].quantity
						: 0
					: list?.title?.itemOptions[item]?.defaultAmount

				block = {
					...block,
					totalQuantity: block.totalQuantity + quantityForOption,
					items: {
						...block.items,
						[item]: {
							name: (options.find((a) => a.id === item)?.title ?? {})[locale],
							id: item,
							price: list?.prices[item],
							quantity: quantityForOption,
						},
					},
				}

				/** we want to add the item if it has default value on the first view, that way we handle validation */
				if (block?.items[item]?.quantity > 0) {
					ItemAdditions.addAdditionNew(keys, { ...block?.items[item] })
				}
			}

			if (block.totalQuantity > block.max) {
				block.totalQuantity = block.max
			}

			setValues(block)
		}, [])

		const manageQuantities = (itemAndQuantity) => {
			let block = {}
			let _stateClone = JSON.parse(JSON.stringify(values))
			const newTotalQuantity = _stateClone.totalQuantity + itemAndQuantity.quantity

			let _cloneItemQuantities = _stateClone.items[itemAndQuantity.id]

			if (_cloneItemQuantities) {
				_cloneItemQuantities.quantity += itemAndQuantity.quantity
				_cloneItemQuantities.price = _stateClone?.items[itemAndQuantity.id]?.price

				// 				if (_cloneItemQuantities.quantity === 0) {
				if (itemAndQuantity.quantity === -1 && _cloneItemQuantities.quantity === 0) {
					ItemAdditions.removeAdditionNew(keys, itemAndQuantity.id)
				} else {
					ItemAdditions.addAdditionNew(keys, _cloneItemQuantities)
				}
			} else {
				_cloneItemQuantities = { ...itemAndQuantity }
			}

			_stateClone = _cloneItemQuantities

			block = {
				...values,
				totalQuantity: newTotalQuantity,
				items: {
					...values.items,
					[itemAndQuantity.id]: {
						id: _stateClone.id,
						quantity: _stateClone.quantity,
						name: _stateClone.name,
						price: _stateClone.price,
					},
				},
			}

			setValues(block)
		}

		return (
			<Grid container>
				{options?.map((option, _idx) => (
					<Grid item xs={12} key={option.id} className="itemQuantities itemQuantity">
						<ItemQuantityForNewDataFormat
							{...option}
							label={getLocaleStr(option.title, locale)}
							manageQuantities={manageQuantities}
							max={values?.max}
							min={values?.min}
							showLabel
							quantity={values?.items[option.id]?.quantity}
							countryCode={rest.countryCode}
							price={prices[option.id] || 0}
							User={User}
							locale={locale}
							currency={currency}
							isIncDisabled={values?.totalQuantity >= values?.max}
							isDecDisabled={values?.items[option.id]?.quantity <= 0}
						/>
					</Grid>
				))}
			</Grid>
		)
	})
)

export default ItemQuantitiesForNewDataFormat
