// @ts-nocheck
import Infra from '../mobx/Infra'
import User from '../mobx/User'
import WarningIcon from '@material-ui/icons/Warning'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import queryString from 'query-string'
import { ECOMMERCE_USER } from '../constants/signUpProcess'
import { CONSTANTS, ORDER_TYPES, ORDER_TYPES_QUERY_PARAMS } from './constants'
import { ORDERS_IDS_CONFIRMATION_SENT } from 'utils/orderConfirmationUtils'
import { arrayWithItems, getGT, getTranslatedTextByKey, isMenuPage } from 'utils/utils'
import Cart from '../mobx/Cart/Cart'
import { sendCustomEvent } from './analytics/analytics'

export const storage = {
	storeId: 0,
	setStoreId: (storeId) => {
		storage.storeId = storeId
	},
	clearItems: (storeId) => {
		localStorage.removeItem(`storageObject${storeId}`)
	},
	removeProp: (prop: string) => {
		const currentStorage = storage.getStorage()
		if (currentStorage) {
			const { [prop]: propToRemove, ...restStorage } = currentStorage
			localStorage.setItem(`storageObject${currentStorage.storeId}`, JSON.stringify(restStorage))
		}
	},
	clearStorage: () => {
		const keysToDelete = []
		const keysToExclude = [
			ECOMMERCE_USER,
			'logo',
			CONSTANTS.LOCAL_STORAGE_ADDRESS_NAME,
			ORDERS_IDS_CONFIRMATION_SENT,
			CONSTANTS.MENU_URL,
			CONSTANTS.STORE_ID,
			'pushNotificationsDeclineDate',
			'pushNotificationsStatus',
			'pushNotificationsToken',
			'forceMobileApp',
			'deviceUniqueId',
			'userUUID',
			'currentDeliveryType',
		]
		for (let i = 0; i < localStorage.length; i++) {
			const _key = localStorage.key(i)

			// remove every key EXCEPT those specified in keysToExclude array
			if (!keysToExclude.includes(_key)) {
				keysToDelete.push(_key)
			}
		}

		keysToDelete.forEach((_key) => {
			localStorage.removeItem(_key)
		})

		console.log("cleared local storage except for 'logo' and 'eCommerceUser'")
	},
	/**
	 * Called by many Mobx stores
	 *
	 * @param storeId
	 * @param newDataJson eg {items, discounts}
	 * @param orderType
	 */
	setStorage: (newDataJson, orderType?: string) => {
		const queryParams = queryString.parse(window.location.search)
		orderType = queryParams.ot ? queryParams.ot.split('-')?.[0] || '' : orderType
		const orderTypeAsNumber = User.classifyOrderType(orderType)

		// remove Mobx proxy
		newDataJson = JSON.parse(JSON.stringify(newDataJson))

		const now = new Date()

		const storeId = storage.storeId !== 0 ? storage.storeId : localStorage.getItem('storeId')

		const genericData = {
			storageDate: now.getTime(),
			storeId,
			version: '2.0',
			orderType: orderTypeAsNumber,
		}

		let newStorageObject = { ...genericData, ...newDataJson }

		let oldStorageObject = localStorage.getItem(`storageObject${storeId}`)
		if (oldStorageObject) {
			oldStorageObject = JSON.parse(oldStorageObject)
		} else {
			oldStorageObject = {}
		}

		newStorageObject = { ...oldStorageObject, ...newStorageObject }
		localStorage.setItem(`storageObject${storeId}`, JSON.stringify(newStorageObject))
		localStorage.setItem('storeId', storeId)
	},
	isEmpty: (storageData) => {
		if (!storageData) {
			return false
		}

		const { items } = storageData
		const hasData = arrayWithItems(Object.keys(items || {}))

		return !hasData
	},
	updateItemsFromStorage: (newMenu, restoreDiscounts = true) => {
		const queryParams = queryString.parse(window.location.search)

		const newOrderTypeAsInt = User.getOrderType()
		const newOrderType = newOrderTypeAsInt === CONSTANTS.DELIVERY_METHODS.DELIVERY ? CONSTANTS.ORDER_TYPE.DELIVERY : CONSTANTS.ORDER_TYPE.PEAKUP

		const oldCartFromStorage = storage.getStorage()
		const chainId = Infra.appParams.c || Infra.appParams.pc

		if (oldCartFromStorage) {
			// Cart content has not expired yet so filter it by the selected store

			const oldChainId = localStorage.getItem('chainId')
			let filteredCartForCurrentStore = {}
			const previousDeliveryType = oldCartFromStorage.orderType

			// if there's no previous delivery type then it means it's the first time the user is here and so set it
			// to the newOrderType
			let oldDeliveryType = newOrderType
			if (previousDeliveryType !== null) {
				const parsedDeliveryType = typeof previousDeliveryType === 'string' ? parseInt(previousDeliveryType) : previousDeliveryType
				oldDeliveryType = parsedDeliveryType === CONSTANTS.DELIVERY_METHODS.DELIVERY ? ORDER_TYPES.DELIVERY : ORDER_TYPES.PEAKUP
			}

			filteredCartForCurrentStore = Cart.filterCartBySelectedStoreMenu(
				oldCartFromStorage,
				newMenu,
				oldChainId,
				queryParams.pc || chainId,
				newOrderType,
				oldDeliveryType,
				Infra.appParams?.cartRelocationEnabled
			)

			if (!storage.isEmpty(filteredCartForCurrentStore)) {
				console.log(`********************************* Your old cart has some items or discounts that are valid in your new store....`)

				if (filteredCartForCurrentStore.newCartHasBeenAltered) {
					const title = getTranslatedTextByKey('webviewFlow.cartUpdate')
					const message = getTranslatedTextByKey('webviewFlow.storeDoesntHaveAllItems')
					Infra.setNotification({
						title: `${title}`,
						message: <Typography variant="caption">{message}</Typography>,
						okAction: () => {
							Infra.closeNotification()
						},
					})

					sendCustomEvent({
						category: 'error',
						action: 'notification',
						label: title,
						message,
					})
				}

				const oldStoreId = localStorage.getItem('storeId')

				// remove the existing Cart
				localStorage.removeItem(`storageObject${oldStoreId}`)

				storage.setStoreId(newMenu.id)

				// store the newly filtered Cart for the new store
				const discounts =
					restoreDiscounts && filteredCartForCurrentStore.discounts ? { discounts: filteredCartForCurrentStore.discounts } : {}
				const items = filteredCartForCurrentStore.items ? { items: filteredCartForCurrentStore.items } : {}

				const dataToStoreInLS = { ...items, ...discounts, orderType: newOrderTypeAsInt }
				storage.setStorage(dataToStoreInLS)

				Cart.restoreFromLocalStorage(dataToStoreInLS)
				// async call - not showing a loading icon, let the user continue using the web-app
				/* getGT(Cart.items, menu.items, (gt, charges, addedItemsFromDiscounts) => {
					Cart.setServerGrandTotal(gt)
					Cart.setServerCharges(charges)
					Cart.setServerAddedItemsFromDiscounts(addedItemsFromDiscounts)
				}) */
			} else {
				// there are no valid items in local storage so clear it
				/* getGT(Cart.items, menu.items, (gt, charges, addedItemsFromDiscounts) => {
					Cart.setServerGrandTotal(gt)
					Cart.setServerCharges(charges)
					Cart.setServerAddedItemsFromDiscounts(addedItemsFromDiscounts)
				}) */
				const title = getTranslatedTextByKey('webviewFlow.cartUpdate')
				const message = getTranslatedTextByKey('webviewFlow.storeDoesntHaveAnyItems')
				Infra.setNotification({
					title: `${title}`,
					message: <Typography variant="caption">{message}</Typography>,
					okAction: () => {
						Infra.closeNotification()
					},
				})

				sendCustomEvent({
					category: 'error',
					action: 'notification',
					label: title,
					message,
				})

				// storage.clearStorage()
				const oldStoreId = localStorage.getItem('storeId')

				// remove the existing Cart
				localStorage.removeItem(`storageObject${oldStoreId}`)

				storage.setStoreId(newMenu.id)

				// store the newly filtered Cart for the new store
				// storage.setStorage({ items: filteredCartForCurrentStore.items })

				// reset the Cart and total to $0.00
				Cart.removeAllItems()
			}

			// update the server with either a) the updated cart with some items OR b) a cart with no items
			getGT(Cart.items, newMenu.items, isMenuPage(), (gt, charges, addedItemsFromDiscounts, deliveryInfo, response, removeDiscountByCalcGt) => {
				removeDiscountByCalcGt(Cart, response.appliedDiscounts)
				Cart.setServerGrandTotal(gt)
				Cart.setServerCharges(charges)
				Cart.setServerAddedItemsFromDiscounts(addedItemsFromDiscounts)
				Cart.setServerDeliveryInfo(deliveryInfo)
				Cart.setServerSpecialDiscount(response)
			})
		}

		// now that the cart has been filtered for the new store, we can save the new store id and chain id
		localStorage.setItem('storeId', newMenu.id)
		localStorage.setItem('chainId', queryParams.pc || chainId)
	},
	storageValid: (storageDate) => {
		const now = new Date().getTime()
		storageDate = parseInt(storageDate)
		return now - storageDate < 18000000 // if its been less than 5 hours, the storage is valid.
	},
	getStorage: () => {
		try {
			const oldStoreId = localStorage.getItem('storeId')
			let storageObject = localStorage.getItem(`storageObject${oldStoreId}`)

			if (storageObject) {
				storageObject = JSON.parse(storageObject)

				if (storageObject.version) {
					if (storage.storageValid(storageObject.storageDate) && !storage.isEmpty(storageObject)) {
						return storageObject
					}
					console.log('local storage has expired OR has not items')
					storage.clearStorage()
				} else {
					console.log('old web flow so resetting storage')
					storage.clearStorage()
				}
			}

			// the storage is not valid
			return null
		} catch (e) {
			console.warn(`updateItemsFromStorage Failed: ${e}`)
			storage.clearStorage()
		}
	},
}

export default storage
