// @ts-nocheck
import React from 'react'
import TypographyPro from 'themes/TypographyPro'
import { getTranslatedTextByKey } from 'utils/utils'
import { isEmpty } from 'lodash-es'
import { ORDER_TYPES } from 'utils/constants'

const getData = () => ({
	stores: [],
	delivery: {
		addressNotFull: {
			infoTextColor: 'var(--inactive)',
			infoText: getTranslatedTextByKey('webviewFlow.localization.delivery.notification.specifyAddress', 'Seems like something is missing'),
			consideredError: true,
			informationalData: {
				iconPath: '/icons/notfound.svg',
				title: getTranslatedTextByKey('webviewFlow.localization.delivery.onTheListView.specifyAddressTitle', 'Help us find you faster!'),
				subtitle: getTranslatedTextByKey(
					'webviewFlow.localization.delivery.onTheListView.specifyAddressDescription',
					'Please type your complete address (city, street, suite/unit number)'
				),
				buttonText: getTranslatedTextByKey('btns.localization.onTheListView.deliveryIsNotAvailable.pickUp', 'Pick up'),
			},
		},
		deliveryNotAvailableInTheArea: {
			infoTextColor: 'var(--inactive)',
			infoText: getTranslatedTextByKey(
				'webviewFlow.localization.delivery.notification.deliveryIsNotAvailable',
				"Delivery isn't available in this area."
			),
			consideredError: true,
			informationalData: {
				iconPath: '/icons/notfound.svg',
				title: getTranslatedTextByKey(
					'webviewFlow.localization.delivery.onTheListView.deliveryIsNotAvailableTitle',
					'Sorry, we don’t currently deliver to your location'
				),
				subtitle: getTranslatedTextByKey(
					'webviewFlow.localization.delivery.onTheListView.deliveryIsNotAvailableDescription',
					'Click on pickup to find a store near you.'
				),
				buttonText: getTranslatedTextByKey('btns.localization.onTheListView.deliveryIsNotAvailable.pickUp', 'Pick up'),
			},
		},
		storesFoundInTheArea: {
			consideredError: false,
			infoTextColor: 'var(--inactive)',
			infoText: getTranslatedTextByKey('webviewFlow.localization.delivery.notification.storesLocated', 'Stores located'),
			informationalData: {},
		},
		storeIsOutOfDeliveryArea: {
			consideredError: false,
			infoTextColor: 'var(--err)',
			infoText: getTranslatedTextByKey(
				'webviewFlow.localization.delivery.notification.pressOnPin.storeOutOfDeliveryArea',
				'Please note that this store is out of delivery area'
			),
			informationalData: {},
			isStoreOutOfDeliveryPickupArea: true,
		},
	},
	pickup: {
		storesFoundInTheArea: {
			consideredError: false,
			infoTextColor: 'var(--inactive)',
			infoText: (numberOfStoresFound) => (
				<>
					<TypographyPro component="span" variant="BodySmallHighlighted">
						{numberOfStoresFound.length}
					</TypographyPro>
					{` `}
					<TypographyPro component="span" variant="BodySmall">
						{getTranslatedTextByKey('webviewFlow.localization.pickUp.notification.numberOfNearbyStoresFound', 'Nearby stores found')}
					</TypographyPro>
				</>
			),
			informationalData: {},
		},
		storesNotFound: {
			infoTextColor: 'var(--inactive)',
			infoText: `0 ${getTranslatedTextByKey('webviewFlow.localization.pickUp.notification.noNearbyStoresFound', 'Nearby stores found')}`,
			consideredError: true,
			informationalData: {
				iconPath: '/icons/notfound.svg',
				title: getTranslatedTextByKey(
					'webviewFlow.localization.pickUp.onTheListView.noNearbyStoresFoundTitle',
					"We couldn't locate any stores near you."
				),
				subtitle: getTranslatedTextByKey(
					'webviewFlow.localization.pickUp.onTheListView.noNearbyStoresFoundDescription',
					'Please try another address or use the map'
				),
				buttonText: getTranslatedTextByKey('btns.localization.onTheListView.noNearbyStoresFound.showMeMoreOptions', 'show me more options'),
			},
		},
		storeFarFromMyLocation: {
			infoTextColor: 'var(--err)',
			infoText: getTranslatedTextByKey(
				'webviewFlow.localization.pickUp.notification.pressOnPin.storeIsFar',
				`Please note that this store is more than 5 km \naway from your location`
			),
			consideredError: false,
			informationalData: {},
			isStoreOutOfDeliveryPickupArea: true,
		},
		storeFarFromMyLocationHandlePickupOnly: {
			infoTextColor: 'var(--err)',
			infoText: getTranslatedTextByKey(
				'webviewFlow.localization.pickUp.notification.pressOnPin.storeIsFar',
				`Please note that this store is more than 5 km \naway from your location`
			),
			consideredError: false,
			informationalData: {},
		},
		multipleStoresFarFromMyLocation: {
			infoTextColor: 'var(--err)',
			infoText: getTranslatedTextByKey(
				'webviewFlow.localization.pickUp.notification.showMoreOptions.storesAreFar',
				'Please note that these stores are more than 5 km away from your location'
			),
			consideredError: false,
			informationalData: {},
		},
	},
})

export const getListData = (
	orderType,
	stores = [],
	hasStoresAway = false,
	isMarkerSelected = false,
	isAddressFull = false,
	allStoresFarAway = false,
	callbackButtons = {}
) => {
	const data = getData()
	const isStoreNearby = stores[0]?.availability?.[orderType]?.availableForAddress
	if (orderType === ORDER_TYPES.DELIVERY) {
		if (isMarkerSelected && (!isStoreNearby || isEmpty(stores[0].availability.delivery))) {
			return data.delivery.storeIsOutOfDeliveryArea
		}
		if ((!stores.length && hasStoresAway) || allStoresFarAway) {
			data.delivery.deliveryNotAvailableInTheArea.informationalData.onClickHandler = callbackButtons.deliveryNotAvailableInTheArea
			return data.delivery.deliveryNotAvailableInTheArea
		}
		if (stores.length) {
			return data.delivery.storesFoundInTheArea
		}
		if (!isAddressFull) {
			data.delivery.addressNotFull.informationalData.onClickHandler = callbackButtons.addressNotFull
			return data.delivery.addressNotFull
		}

		data.delivery.deliveryNotAvailableInTheArea.informationalData.onClickHandler = callbackButtons.deliveryNotAvailableInTheArea
		return data.delivery.deliveryNotAvailableInTheArea
	}
	if (stores.length && !isMarkerSelected && !allStoresFarAway) {
		return {
			...data.pickup.storesFoundInTheArea,
			infoText: data.pickup.storesFoundInTheArea.infoText(stores),
		}
	}
	if (!stores.length && hasStoresAway) {
		data.pickup.storesNotFound.informationalData.onClickHandler = callbackButtons.storesNotFound
		return data.pickup.storesNotFound
	}
	if (isMarkerSelected && (!isStoreNearby || isEmpty(stores[0].availability.pickup))) {
		if (!isEmpty(stores[0].availability.delivery)) {
			return data.pickup.storeFarFromMyLocation
		}
		return data.pickup.storeFarFromMyLocationHandlePickupOnly
	}
	if (isMarkerSelected && isStoreNearby) {
		return {
			...data.pickup.storesFoundInTheArea,
			infoText: data.pickup.storesFoundInTheArea.infoText(stores),
		}
	}
	if (allStoresFarAway) {
		return data.pickup.multipleStoresFarFromMyLocation
	}
}
