import type _Infra from 'mobx/Infra'
import { sendRequest } from 'utils/utils'
import type { StoreFrontResponse } from './types'

export const getStoreInfo = async (storeId: string, Infra: typeof _Infra): Promise<StoreFrontResponse> => {
	const storeFrontUrl = Infra?.appParams.storefrontUrl
	if (!storeFrontUrl) {
		throw new Error('Storefront URL is missing')
	}

	return (await sendRequest(
		false,
		`${storeFrontUrl}/v1/stores/${storeId}?channel=WEB&%24pick=availability%2Cstatus%2CorderTypes%2Clocation%2Ctitle%2Cid`,
		'GET'
	)) as StoreFrontResponse
}
