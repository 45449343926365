import Infra from 'mobx/Infra'
import Cart from 'mobx/Cart'
import Home from 'mobx/Home'
import User from 'mobx/User'
import ItemAdditions from 'mobx/ItemAdditions'

import { CONSTANTS } from 'utils/constants'
import { sendEnhancedEcommerceEvent } from 'utils/analytics/analytics'
import storage from '../storage'
import {
	getGT,
	isMenuPage,
	isMobile,
	getLocaleStr,
	getTranslatedTextByKey,
	getSectionTitleForItem,
	getStoreName,
	removeDiscountByCalcGt,
} from 'utils/utils'

import type GetGTResponse from 'types/GetGTResponse'

const EEE = CONSTANTS.ANALYTICS.ENHANCED_ECOMMERCE_EVENTS

export default async function addItemToCart(item: any, store: any, rest: any /* TODO-SERGEY: add a proper type */): Promise<boolean> {
	const menu = store.data

	Cart.addItem(item, ItemAdditions.reset, storage)

	const { gt, chargesFromServer, response, deliveryInfo } = (await getGT(Cart.items, menu.items, isMenuPage())) ?? {}
	const title = getLocaleStr(menu.items[item.itemId].title, menu.locale)

	if (response?.error) {
		const courseListItemsNotAvailableErrors: { itemId: string; errorMessage: string }[] = Object.values(
			response?.courseListItemsNotAvailableErrors || {}
		)

		Infra.setErrorNotification(response?.errorMessage, undefined, true)

		const { items } = Cart as { items: Record<string, unknown[]> }

		if (courseListItemsNotAvailableErrors.length) {
			for (const { itemId } of courseListItemsNotAvailableErrors) {
				while (items[itemId]?.length) {
					Cart.removeItem(itemId, 0, storage)
				}
			}
		}

		return false
	}

	removeDiscountByCalcGt(Cart, response.appliedDiscounts)

	Cart.setServerGrandTotal(gt)
	Cart.setServerCharges(chargesFromServer)
	Cart.setServerAddedItemsFromDiscounts(response?.addedItemsFromDiscounts)
	Cart.setServerDeliveryInfo(deliveryInfo)
	Cart.setServerSpecialDiscount(response)

	Infra.showSnackbar({
		key: '',
		snackId: isMobile() ? 'checkout-btn' : 'cart',
		message: `'${title}' ${getTranslatedTextByKey('wasAddedToCart')}`,
		status: 'success',
	})

	const eventMetaData = {
		itemTitle: title,
		description: menu.items[item.itemId].description,
		sectionTitle: getSectionTitleForItem(item.itemId, menu.sections, menu.locale),
		currency: menu.currency,
		countryCode: menu.countryCode,
		storeName: getStoreName(store, User.getOrderType(), Home),
	}

	sendEnhancedEcommerceEvent(EEE.sendAddToCartEvent, item, eventMetaData, rest, Cart)

	return true
}
