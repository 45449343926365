import type { ButtonProps } from '@material-ui/core/Button'
import Button from '@material-ui/core/Button'
import type React from 'react'
import styled, { css } from 'styled-components'

const primary = css`
	background-color: var(--primary);
	color: var(--clear);
	border-radius: var(--borderRadiusBtn);
	border: 1px solid var(--primary);

	@media (hover: hover) {
		:hover {
			background-color: var(--secondary);
			border: 1px solid var(--secondary);
		}
	}

	:active {
		background-color: var(--primary);
		box-shadow: none;
		border: 1px solid var(--primary);
	}

	:disabled {
		background-color: var(--optionalBackground);
		color: var(--disable);
		border: 1px solid var(--optionalBackground);
	}

	@media (max-width: 576px) {
		:focus {
			background-color: var(--primary);
			border: 1px solid var(--primary);
		}
		:active {
			background-color: var(--secondary);
			border: 1px solid var(--secondary);
		}
	}
`

const secondary = css`
	border: 1px solid var(--primary);
	color: var(--primary);
	background-color: var(--clear);
	border-radius: var(--borderRadiusBtn);

	@media (hover: hover) {
		:hover {
			border: 1px solid var(--secondary);
			color: var(--secondary);
			background-color: var(--clear);
		}
	}

	:active {
		color: var(--primary);
		border: 1px solid var(--primary);
	}
	:disabled {
		border: 1px solid var(--disable);
		color: var(--disable);
	}

	@media (max-width: 576px) {
		:focus {
			border: 1px solid var(--primary);
			color: var(--primary);
			background-color: var(--clear);
		}
		:active {
			border: 1px solid var(--secondary);
			color: var(--secondary);
			background-color: var(--clear);
		}
	}
`

const optional = css`
	border: 1px solid var(--disable);
	color: var(--footerAndDarkBackgrounds);
	background-color: var(--clear);
	border-radius: var(--borderRadiusBtn);

	@media (hover: hover) {
		:hover {
			border: 1px solid var(--footerAndDarkBackgrounds);
			background-color: var(--clear);
		}
	}

	:active {
		color: var(--footerAndDarkBackgrounds);
		border: 1px solid var(--disable);
	}
	:disabled {
		border: 1px solid var(--disable);
		color: var(--disable);
	}

	@media (max-width: 576px) {
		:focus {
			border: 1px solid var(--disable);
			color: var(--footerAndDarkBackgrounds);
			background-color: var(--clear);
		}
		:active {
			background-color: var(--clear);
			border: 1px solid var(--footerAndDarkBackgrounds);
		}
	}
`

export type ButtonType = 'secondary' | 'optional' | 'primary'
export type ButtonSize = 'small' | 'big'
export type ButtonBaseProps = {
	$type?: ButtonType
	$size?: ButtonSize
	fullWidth?: boolean
} & ButtonProps

// mapping the button type to the button variant of material-ui
const getVariantByType = (type: ButtonType) => {
	switch (type) {
		case 'secondary':
		case 'optional':
			return 'outlined'
		case 'primary':
		default:
			return 'contained'
	}
}
/**
 * ButtonBase is the main reusable component for our buttons.
 * It takes a type (primary, secondary, optional)
 * It takes any props accepted by MaterialUI: https://mui.com/material-ui/api/button/
 * * */
const ButtonBase = styled(Button).attrs(({ $type = 'primary', $size = 'small', fullWidth = true }: ButtonBaseProps) => ({
	variant: getVariantByType($type),
	'data-button-type': $type,
	'data-button-size': $size,
	fullWidth,
}))`
	line-height: 100%;
	&.MuiButton-contained {
		${primary}
	}

	&.MuiButton-outlined {
		${({ $type }: ButtonBaseProps) => ($type === 'secondary' ? secondary : optional)};
	}

	box-shadow: none;
	:hover {
		box-shadow: none;
	}

	${({ theme, $size }) => {
		switch ($size) {
			case 'big':
				return css`
					height: 56px;
					padding: 0 35px;
					@media (max-width: 576px) {
						&.MuiButton-root {
							height: 45px;
						}
					}
					&.MuiButton-contained,
					&.MuiButton-outlined {
						${theme.typography.CTABig};
					}
				`
			case 'small':
			default:
				return css`
					${theme.typography.CTASmall};
					height: 40px;
					padding: 0 25px;
					@media (max-width: 576px) {
						&.MuiButton-root {
							height: 35px;
						}
					}
					&.MuiButton-contained,
					&.MuiButton-outlined {
						${theme.typography.CTASmall};
					}
				`
		}
	}};
`

export default ButtonBase as React.FC<ButtonBaseProps>
