// @ts-nocheck
import { useTheme } from '@material-ui/core/styles'
import React from 'react'
import SVG from 'react-inlinesvg'
import { inject, observer } from 'mobx-react'
import { getThemeUrl } from 'utils/theme/themeManager'
import { THEME_STRATEGY } from 'constants/theme'

/**
 * This component takes a className and: an 'asset' parameters (ex: /icons/cart.svg) or a 'fullAssetUrl'(ex: https://abc.com/cart.svg).
 *
 * If it receives and 'asset' argument and NOT a 'fullAssetUrl' parameter :
 *    It returns an icon fetched from the bucket defined in brand-theme.json > assetsUrl
 *    If the icon doesn't exist, it will use a fallback bucket to find the icon
 *    If the icon is still not found, it will return an empty component
 *
 * If it receives a 'fullAssetUrl' argument:
 *    It will return the asset specified
 *
 * If it receives a 'specialFallback' argument:
 *    It will use this fallback full url in case the provided asset does not exist
 *    Note that it should be a full url

 * NB1: All icons from the platform should be rendered through this component.
 * NB2: We can refactor the way our icons are rendered all at once by refactoring this component
 * (if we want to render svg instead of img for example

 * This is how to define an icon:
 *    const IconName = (props) => <IconComponent asset="/icons/iconName.svg" {...props} />

 * This is how we style it:
 *    const StyledHouseIcon = styled(HouseIcon)`width: 30px; height: 30px`

 * @param {object} props Contains asset='/path-inside-assets/icon-name.svg' and className
 * @returns {JSX.Element}
 */
const IconComponent = inject('Infra')(
	observer((props: any) => {
		const { Infra } = props
		const theme = useTheme()
		const { useThemeDefaultColor } = props
		const fallBackBucket = 'https://cdn.tictuk.com/defaultThemes/generic/assets'

		// we only set the SVG's color if it's non eCommerce and if a color prop is provided since NOT all icons need to be coloured
		// eg only delivery icons, user icon and map pins (empty and with a no.)
		// TODO we will need to also know if the non eCommerce has there own theme in the future.

		const _fillColor = !Infra.appParams.eCommerce && useThemeDefaultColor ? theme.palette.first : props.fillColor || undefined

		// _fillColor = "#0A8020"

		const preProcessor = _fillColor ? (code: string) => code.replace(/fill=".*?"/g, `fill="${_fillColor}"`) : undefined
		const themeStrategy = Infra.appParams.theme || THEME_STRATEGY.GENERIC
		const iconPath = `${getThemeUrl(themeStrategy, Infra.appParams.c, Infra.appParams.brand)}/assets`

		const assetUrl = props.assetFullUrl ? props.assetFullUrl : `${iconPath}${props.asset}`
		const fallBackUrl = props.specialFallback ? props.specialFallback : `${fallBackBucket}${props.asset}`

		return (
			<SVG
				id={props.id}
				key={`${assetUrl}_${_fillColor}`}
				src={assetUrl}
				cacheRequests
				onLoad={props.onLoad}
				className={props.className}
				preProcessor={preProcessor}
				onClick={props.onClick}
				data-testid={props.testId}
				width={props.width}
			>
				{/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/click-events-have-key-events */}
				<img src={fallBackUrl} className={props.className} onClick={props.onClick} alt="" />
			</SVG>
		)
	})
)

export default IconComponent
