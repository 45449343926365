// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react'
import { isMobile, isAndroid, areAllVariationsHidden } from '../../utils/utils'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import MenuItemPage from './menuItemPage/MenuItemPage'
import { finish } from '../../utils/chatAppUtils'
import queryString from 'query-string'
import classnames from 'classnames'
import { itemNotFoundPopUp } from 'utils/menu/menuUtils'

import { StoreContext } from '../../contexts/StoreContext'
import { isNextJS } from '../../../utils/nextUtils'

import { useClientSideRendering } from 'hooks/useClientSideRendering'

const Container = styled.div`
	.overlay {
		height: 100%;
		width: 100%;
		left: 0;
		right: 0;
		bottom: -150vh;
		display: flex;
		z-index: 1300;
		position: fixed;
		align-items: center;
		justify-content: center;
		background-color: rgba(0, 0, 0, 0.5);
		-webkit-tap-highlight-color: transparent;
		opacity: 1;
		&.show {
			bottom: 0;
		}
		pointer-events: unset;
		touch-action: none;
	}

	.modal {
		position: fixed;
		bottom: -150vh;
		background-color: #fff;
		left: 0;
		right: 0;
		transition: bottom 0.3s ease-out;
		&.show {
			bottom: 0;
			@media (min-width: 600px) {
				bottom: 3%;
			}
		}
		width: 100%;
		height: 100%;
		flex: 1 0 auto;
		display: flex;
		outline: 0;
		z-index: 1301;
		flex-direction: column;
		margin: 0 auto;

		&.itemHasNoVariations {
			width: 600px;
			height: 70%;
			bottom: 25%;
		}

		@media (min-width: 600px) {
			width: 600px;
			height: 90%;
		}

		@media (min-width: 1280px) {
			width: 1078px;

			height: 90%;
		}
	}
`

const MenuItemOverlay = inject(
	'Cart',
	'ItemAdditions',
	'User',
	'Infra',
	'Application'
)(
	observer((props) => {
		const _isMobile = isMobile()
		const _isAndroid = isAndroid()
		const isClientSideRendering = useClientSideRendering()

		const { store } = useContext(StoreContext)
		const rest = store.data
		const { Cart, ItemAdditions, User, Infra, Application } = props

		const _isMobileApp = Application.isMobileApp

		const [state, setState] = useState({
			displayModal: false,
			animationStyle: false,
			beenUsedOnce: false,
		})

		const closeModal = () => {
			const parsedQueryString = queryString.parse(location.search)
			if (
				(parsedQueryString.mode && parsedQueryString.mode === 'editSingle') ||
				(parsedQueryString.ais && parsedQueryString.ais === 'expressCheckout')
			) {
				// close the popup in a chat app
				finish(true)
			} else {
				if (isNextJS()) {
					Application.dependencies?.replaceHistoryState()
				}

				// 1. navigate back to the /menu without the hash
				Cart.closeMenuItem(true)

				// NB overlay opens on top of /menu or /check-out with a #menu-item added to the end of the url
				// props.history.goBack()
				// remove any hash added from a user who selected a featured item
				history.replaceState(null, null, ' ')
			}
		}

		useEffect(() => {
			const parsedQueryString = queryString.parse(location.search)
			if (Cart.menuItemOpen) {
				// prevent scrolling from working on the page under the modal
				// document.body.style.overflow = 'hidden'

				// open the white popup after 200ms which gives enough time for the grey modal to be shown and then we
				// can animate the white popup on top of it

				if (!rest?.items[Cart?.menuItemId]) {
					Cart.closeMenuItem(false)
					itemNotFoundPopUp(Infra)
					return
				}

				if (_isAndroid) {
					setTimeout(() => {
						setState({
							displayModal: true,
							animationStyle: true,
							beenUsedOnce: true,
						})
					}, 50)
				} else {
					// setTimeout(() => {
					// setAnimationStyle(true)
					setState({
						displayModal: true,
						animationStyle: true,
						beenUsedOnce: true,
					})

					// }, 50)
				}
			} else {
				// allow scrolling again on the body
				// document.body.style.overflow = ''

				// close the white popup now
				if (!Cart.menuItemOpen && state.beenUsedOnce) {
					if (
						(parsedQueryString.mode && parsedQueryString.mode === 'editSingle') ||
						(parsedQueryString.ais && parsedQueryString.ais === 'expressCheckout')
					) {
						// close the popup in a chat app
						finish(false, Cart.items, rest.items, true)
					} else {
						setTimeout(() => {
							setState({
								displayModal: false,
								animationStyle: false,
								beenUsedOnce: true,
							})
						}, 100)
					}
				}
			}
		}, [Cart.menuItemOpen])

		useEffect(() => {
			// console.log(`**** state: ${JSON.stringify(state)}`)
		}, [state])

		const item = rest?.items[Cart?.menuItemId]
		const itemHasNoVariations =
			(item && !item.variations) ||
			(item && item.variations.length === 0) ||
			(item && item.variations && areAllVariationsHidden(item.variations, rest))

		return (
			<Container>
				<div
					className={classnames(
						'menuItemOverlay modal',
						state.displayModal ? 'show' : '',
						state.displayModal && itemHasNoVariations && !_isMobile ? 'itemHasNoVariations' : ''
					)}
				>
					{state.displayModal && (
						<MenuItemPage
							Cart={Cart}
							ItemAdditions={ItemAdditions}
							closeModal={closeModal}
							User={User}
							Infra={Infra}
							itemHasNoVariations={itemHasNoVariations}
						/>
					)}
				</div>

				{isClientSideRendering && !_isMobile && (
					<div className={classnames('menuItemOverlay overlay', Cart.menuItemOpen || state.displayModal ? 'show' : '')} />
				)}
			</Container>
		)
	})
)

export default MenuItemOverlay
