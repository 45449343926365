import { useCallback, useState } from 'react'

/**
 * This hook returns a function that you can use to rerender the component,
 * It is usually used to rerender mutable data.
 * @param delay - set delay in ms before the re-rendered starts
 * @returns {(function(): (number|undefined))|*} - return a function to start the rerender,
 * if "delay" was passed then return the timeoutID, otherwise return undefined.
 */
function useRerender(delay: number): () => NodeJS.Timeout | undefined {
	const [, setSignal] = useState({})

	return useCallback(() => {
		if (delay) {
			return setTimeout(() => {
				setSignal({})
			}, delay)
		}

		setSignal({})
	}, [])
}

export default useRerender
