// @ts-nocheck
import React from 'react'
import styled, { css } from 'styled-components'
import { isRTL } from 'utils/utils'
import { inject, observer } from 'mobx-react'
import TypographyPro from 'themes/TypographyPro'
import ImageComponent from 'themes/ImageComponent'
import ClusterMarker from 'components/common/GoogleMapsClusterMarker'

const MarkerContainer = styled.div`
	position: relative;
	cursor: pointer;
	transform: translate(${isRTL() ? 15 : -15}px, -43px);
`

const MarkerIcon = styled(ImageComponent)`
	width: ${({ $isNearby }) => ($isNearby ? 30 : 25)}px;
	height: 50px;
	transform: ${({ $isSelected }) => ($isSelected ? 'scale(1.3) translate(2px, 1px)' : 'unset')};
	transition: transform 0.1s linear !important;
`

const DistanceRankText = styled(TypographyPro)`
	position: absolute;
	font-weight: bold;
	top: 22%;
	${isRTL()
		? css`
				right: ${({ $is2digits }) => ($is2digits ? 6 : 10.5)}px;
		  `
		: css`
				left: ${({ $is2digits }) => ($is2digits ? 6 : 10.5)}px;
		  `};
	transform: ${({ $isSelected }) => ($isSelected ? 'scale(1.3) translate(2px, 1px)' : 'unset')};
	transition: transform 0.1s linear;
	color: var(--secondary);
`
// This marker represents a single store - clicking on it will show that store details on the side list.
const StoreMarker = ({ rank, isSelected, isNearby }) => {
	if (isNearby) {
		return (
			<>
				<MarkerIcon asset="/icons/innerMarkerLogo.svg" $isSelected={isSelected} $isNearby={isNearby} testId="store-pin-nearby" />
				<DistanceRankText component="span" variant="BodySmallHighlighted" $is2digits={rank > 9} $isSelected={isSelected}>
					{rank}
				</DistanceRankText>
			</>
		)
	}
	return <MarkerIcon asset="/icons/outerMarkerLogo.svg" $isNearby={isNearby} $isSelected={isSelected} testId="store-pin-far" />
}

const Marker = inject(
	'User',
	'Infra'
)(
	observer(({ rank, onSelectStore, isSelected, isNearby, isCluster, pointCount, onClusterClick }) => (
		<MarkerContainer onClick={onSelectStore} data-testid="marker-container">
			{isCluster ? (
				<ClusterMarker pointCount={pointCount} onClusterClick={onClusterClick} />
			) : (
				<StoreMarker rank={rank} isSelected={isSelected} isNearby={isNearby} />
			)}
		</MarkerContainer>
	))
)

export default Marker
