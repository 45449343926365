// @ts-nocheck

import React, { useEffect, useState, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components/macro'
import ItemQuantity from './ItemQuantity'
import { inject, observer } from 'mobx-react'
import { StoreContext } from '../../../contexts/StoreContext'
import nestedProperty from 'nested-property'
import { codeToLocale, getLocaleStr } from '../../../utils/utils'

const StyledGrid = styled(Grid)`
	&.itemQuantities {
		&.itemQuantity {
			border-bottom: 1px solid #d4d0d0;
			padding: 2px 0 2px 4px;
		}
	}

	&.itemQuantity {
		&.root {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
`

/**
 * Used to display all items that can have a quantity > 1. Also maintain state across all the items.
 * Eg if only allowed 3 selections, ensure only a quantity of 3 is the max.
 *
 * @param props
 * @returns {*}
 * @constructor
 */

const ItemQuantities = inject(
	'Cart',
	'ItemAdditions',
	'User'
)(
	observer((props) => {
		const { store } = useContext(StoreContext)
		const rest = store.data
		const { Cart, ItemAdditions, User } = props
		// const classes = useStyles()
		const locale = User.preferredLanguage ? codeToLocale[User.preferredLanguage] : rest.locale

		// NB the useState is only called ONCE when this component 1st renders. For a re-render, the useState is NOT called again
		// So we can use it to set the default values.
		// This is better than using useEffect with [] as a dependency since the component needs to render once before the useEffect is called!
		/* const [itemQuantities, setItemQuantities] = useState(defaultItemQuantities)
		const [allowPlus, setAllowPlus] = useState(initAllowPlus)
		const [totalQuantity, setTotalQuantity] = useState(initTotalQuantity) */

		const [state, setState] = useState({
			init: false,
		})

		useEffect(() => {
			// set max number of combined selections
			let max = 0
			const min = 0

			if (
				// TODO - install plugin to use new syntax for chained/deep param checking
				props.options &&
				props.options[0] &&
				props.options[0].variations &&
				props.options[0].variations[0] &&
				props.options[0].variations[0].itemIds
			) {
				// max = props.options[0].variations[0].itemIds.length - 1
				// min = props.minNumAllowed
				max = props.maxNumAllowed
			}

			const defaultItemQuantities = {}
			let initTotalQuantity = 0
			let _nonDefaultVariationMax = null
			// let initAllowPlus = true

			if (Cart.editItem) {
				console.log('editing item quantities')

				// const optionKey = `${props.optionId}_${props.index}`
				// const existingAdditions = ItemAdditions.additions[optionKey]

				let existingAdditions = null
				const optionKeyNew = `${props.keys}`

				if (nestedProperty.has(ItemAdditions.additionsNew, optionKeyNew)) {
					// const _itemCartAdditionsNewMap = nestedProperty.get(ItemAdditions.additionsNew, optionKeyNew)
					existingAdditions = nestedProperty.get(ItemAdditions.additionsNew, optionKeyNew)
					// console.log('****** edit new map ****')
					// console.log(toJS(_itemCartAdditionsNewMap))
				} else {
					console.log(`***** Item doesn't contain the key: '${optionKeyNew}'`)
				}

				if (existingAdditions) {
					Object.keys(existingAdditions).forEach((_existingAdditionId) => {
						const _existingAddition = existingAdditions[_existingAdditionId]

						defaultItemQuantities[_existingAddition.id] = {
							id: _existingAddition.id,
							quantity: _existingAddition.quantity,
							name: _existingAddition.name,
							price: _existingAddition.price,
							en_ID: _existingAddition.description?.en_ID,
							// allowPlus: true,
						}

						initTotalQuantity += _existingAddition.quantity
					})
				}

				// now add any remaining options who will have a quantity of 0 since the edited set of options must total
				// the required max.
				props.options.forEach((_additionWithQuantity) => {
					if (
						// _additionWithQuantity.variations &&
						// _additionWithQuantity.variations[0] &&
						// _additionWithQuantity.variations[0].defaults &&
						// _additionWithQuantity?.variations[0]?.defaults?.length > 0
						_additionWithQuantity?.variations[0]
					) {
						const [_v] = _additionWithQuantity.variations

						const defaultVariationId = _v.defaults && _v.defaults[0] ? _v.defaults[0] : null

						// if there's no default then check the max length of a variation (eg a Sauce may have a array of size 11
						// which means a max of 10 is allowed of this sauce)
						_nonDefaultVariationMax = defaultVariationId ? null : _v.itemIds ? _v.itemIds.length - 1 : null

						if (!defaultItemQuantities[_additionWithQuantity.id]) {
							// this addition does not exist in the existingAdditions map so needs to be added with a quantity of 0
							const price = _additionWithQuantity.price || 0

							defaultItemQuantities[_additionWithQuantity.id] = {
								id: _additionWithQuantity.id,
								name: getLocaleStr(_additionWithQuantity.title, locale),
								quantity: 0,
								price,
								en_ID: _additionWithQuantity.description?.en_ID,
							}
						} else {
							// todo ezra: good candidate 2, addAdditionNew here will build the required object
							ItemAdditions.addAdditionNew(props.keys, defaultItemQuantities[_additionWithQuantity.id])
						}
					}
				})

				// console.log(`ItemQuantities: ${JSON.stringify(defaultItemQuantities)}`)
			} else {
				// key = item-with-quantity id, value = {id, name, quantity, price}
				props.options.forEach((_additionWithQuantity) => {
					if (
						_additionWithQuantity.variations &&
						_additionWithQuantity.variations[0]
						// _additionWithQuantity.variations[0].defaults &&
						// _additionWithQuantity?.variations[0]?.defaults?.length > 0
						// _additionWithQuantity?.variations[0]
					) {
						const [_v] = _additionWithQuantity.variations

						const defaultVariationId = _v.defaults && _v.defaults[0] ? _v.defaults[0] : null

						// if there's no default then check the max length of a variation (eg a Sauce may have a array of size 11
						// which means a max of 10 is allowed of this sauce)
						_nonDefaultVariationMax = defaultVariationId ? null : _v.itemIds ? _v.itemIds.length - 1 : null

						// if there's a default quantity then this is the max and the min for the entire set of ItemQuantity components
						const defaultQuantity = defaultVariationId ? _v.itemIds.findIndex((element) => element === defaultVariationId) : 0
						// const price = defaultVariationId ? _v.prices[defaultVariationId] : 0

						// take the price from the prices array of the parent variation
						// eg "var-11330-10602-9659b83a-f59d-cff5-3db5-248b5aea3d2dv": 590,
						const price = props.prices[_additionWithQuantity.id] || 0

						defaultItemQuantities[_additionWithQuantity.id] = {
							id: _additionWithQuantity.id,
							name: getLocaleStr(_additionWithQuantity.title, locale),
							quantity: defaultQuantity,
							price,
							en_ID: _additionWithQuantity.description?.en_ID,
						}

						if (defaultQuantity > 0) {
							ItemAdditions.addAdditionNew(props.keys, defaultItemQuantities[_additionWithQuantity.id])
						}

						initTotalQuantity += defaultQuantity
					}
				})
			}

			// NB the useState is only called ONCE when this component 1st renders. For a re-render, the useState is NOT called again
			// So we can use it to set the default values.
			// This is better than using useEffect with [] as a dependency since the component needs to render once before the useEffect is called!

			// if initTotalQuantity is > 0, then use it as the min and max. Otherwise, use the minNumAllowed and maxNumAllowed from the JSON for the item.
			setState({
				itemQuantities: defaultItemQuantities,
				totalQuantity: initTotalQuantity,
				max: props.isRequired ? (initTotalQuantity > 0 ? initTotalQuantity : _nonDefaultVariationMax || max) : _nonDefaultVariationMax || max,
				min: initTotalQuantity > 0 ? initTotalQuantity : _nonDefaultVariationMax ? 0 : max,
				// max: _nonDefaultVariationMax || max,
				// min: _nonDefaultVariationMax ? 0,
				init: true,
			})

			// Adding props.options as dependency will forces the useffect to run after a re-rendered of the component (or its parents)
			// so ItemAdditions.addAdditionNew will be called and set a default quantity on the variation, if necessary
			// it means that if MenuItemPageSections is re-render (ex: change in store.data of StoreContext), this useEffect will be called again
			// (it fixes the issue https://tictuk.atlassian.net/browse/EU-1503)
		}, [props.options])

		/**
		 * NB on each re-render of this component, this method is re-created and a new ref. is passed into each <ItemQuantity> component
		 *
		 * useCallback only works without params. Passing a param means the function is re-created if the param is different on each method call!
		 *
		 * @param itemAndQuantity is {itemId: <string>, quantity: <int>, price: <int>}
		 */
		const manageQuantities = (itemAndQuantity) => {
			const _stateClone = JSON.parse(JSON.stringify(state))
			// 1. calculate new total
			const newTotalQuantity = _stateClone.totalQuantity + itemAndQuantity.quantity

			const _cloneItemQuantities = _stateClone.itemQuantities

			_stateClone.totalQuantity = newTotalQuantity

			// 3. now update the quantity (+/-) of the selected item
			if (_cloneItemQuantities[itemAndQuantity.id]) {
				_cloneItemQuantities[itemAndQuantity.id].quantity += itemAndQuantity.quantity
				_cloneItemQuantities[itemAndQuantity.id].price = itemAndQuantity.price

				/* if (_cloneItemQuantities[itemAndQuantity.id].quantity === 0) {
					delete _cloneItemQuantities[itemAndQuantity.id]
				} */

				if (itemAndQuantity.quantity === -1 && _cloneItemQuantities[itemAndQuantity.id].quantity === 0) {
					// remove this variation from the Cart
					ItemAdditions.removeAdditionNew(props.keys, itemAndQuantity.id)
				} else {
					// for - or + add this variation since the quantity will be changed for both use-cases.
					ItemAdditions.addAdditionNew(props.keys, _stateClone.itemQuantities[itemAndQuantity.id])
				}
			} else {
				_cloneItemQuantities[itemAndQuantity.id] = itemAndQuantity
			}

			_stateClone.itemQuantities = _cloneItemQuantities
			setState(_stateClone)
			// console.log(JSON.stringify(_clone))

			// 4. add all choices to the MobX Store...
			/*			Object.keys(_stateClone.itemQuantities).forEach((_additionId, _choiceIndex) => {
				if (_stateClone.itemQuantities[_additionId].quantity > 0) {
					ItemAdditions.addAdditionNew(props.keys, _stateClone.itemQuantities[_additionId])
				}
			}) */
		}

		if (!state.init) {
			return null
		}

		return (
			<Grid container spacing={0}>
				{props.options.map((_option, _idx) => (
					<StyledGrid item xs={12} sm={12} key={_option.id} className="itemQuantities itemQuantity">
						<ItemQuantity
							{..._option}
							label={getLocaleStr(_option.title, locale)}
							manageQuantities={manageQuantities}
							max={state.max}
							min={state.min}
							// allowPlus={state.allowPlus}
							// allowPlus={state.itemQuantities[_option.id].allowPlus}
							showLabel
							quantity={state.itemQuantities[_option.id] ? state.itemQuantities[_option.id].quantity : 0}
							countryCode={rest.countryCode}
							price={(props.prices && props.prices[_option.id]) || 0}
							User={User}
							locale={locale}
							currency={props.currency}
							isIncDisabled={
								state.totalQuantity === state.max ||
								(state.itemQuantities[_option.id] ? state.itemQuantities[_option.id].quantity : 0) === state.max
							}
							isDecDisabled={state.itemQuantities[_option.id].quantity === 0}
						/>
					</StyledGrid>
				))}
			</Grid>
		)
	})
)

export default ItemQuantities
