import { sendRequest } from 'utils/utils'
import Infra from '../../mobx/Infra'
import { isObject, merge } from 'lodash-es'
import type { Brand, Theme } from './GlobalStyle'
import fallbackTheme from 'utils/theme/fallbackTheme'
import { THEME_STRATEGY } from 'constants/theme'
import { isNextJS } from '../../../utils/nextUtils'

// TODO: We should remove string type for "themeStrategy" param and use only THEME_STATEGY !
// For now we put both only because of static pages generation usage:
// There is a VALID_PARAMS_THEME_VALUES in 1. constants.js (for static pages) and 2. In template.js also that that used instead of THEME_STRATEGY
// Should talk with Sergey and fix it
interface GetThemeParams {
	chainId: string
	brand?: string
	themeStrategy?: THEME_STRATEGY | string
	isKiosk: boolean
}

export const getTheme = async ({ chainId, brand, isKiosk, themeStrategy = THEME_STRATEGY.GENERIC }: GetThemeParams) => {
	try {
		const url = `${getThemeUrl(themeStrategy, chainId, brand as Brand)}/brand-theme.json`
		const themeJSON = await sendRequest(false, url, 'get', null, null, undefined, undefined)

		if (validateThemeStructureAndBrand(themeJSON, fallbackTheme, brand)) {
			const themeJSONUpdated = isKiosk ? getKioskTheme(themeJSON) : themeJSON
			return fillMissingValuesFromDefaultJSON(themeJSONUpdated, fallbackTheme)
		}
		return fallbackTheme
	} catch (error) {
		console.error(`Failed to get a theme json. ${error}`)
		return fallbackTheme
	}
}

export const validateThemeStructureAndBrand = (themeJSON: any, fallbackTheme: Theme, brandInParamsJSON?: string) => {
	// In case the theme JSON is corrupted the sendRequest() method returns a string containing the corrupted JSON
	if (!themeJSON || typeof themeJSON === 'string') {
		return false
	}

	if (themeJSON.brand && brandInParamsJSON && themeJSON.brand !== brandInParamsJSON) {
		console.error(`Incorrect "brand" field value`)
		return false
	}

	return true
}

// TODO: We should remove string type for "themeStrategy" param and use only THEME_STATEGY !
// For now we put both only because of static pages generation usage:
// There is a VALID_PARAMS_THEME_VALUES in 1. constants.js (for static pages) and 2. In template.js also that that used instead of THEME_STRATEGY
// Should talk with Sergey and fix it
export const getThemeUrl = (themeStrategy: THEME_STRATEGY | string, chainId: string, brand?: Brand) => {
	const isProductionNext = isNextJS() && Infra?.appParams?.environment === 'PROD'
	const isProductionNode = process.env.NODE_ENV === 'production'
	const isProduction = isNextJS() ? isProductionNext : isProductionNode

	const bucketUrl = isProduction || Infra?.appParams?.useProductionTheme ? 'cdn.tictuk.com' : 'staging.tictuk.com'

	// If nextJs runs with local theme strategy, we check that NEXT_PUBLIC_LOCAL_THEME_URL is well defined
	if (isNextJS() && themeStrategy === THEME_STRATEGY.LOCAL && !process.env.NEXT_PUBLIC_LOCAL_THEME_URL) {
		throw new Error('Environment variable NEXT_PUBLIC_LOCAL_THEME_URL is not defined (mandatory for local theme strategy with NextJS)')
	}

	switch (themeStrategy) {
		case THEME_STRATEGY.CUSTOM:
			return `https://${bucketUrl}/${chainId}/theme`
		case THEME_STRATEGY.BRAND:
			return `https://${bucketUrl}/defaultThemes/brands/${brand}`
		case THEME_STRATEGY.LOCAL:
			return isNextJS() ? process.env.NEXT_PUBLIC_LOCAL_THEME_URL : `${window.location.origin}/theme`
		case THEME_STRATEGY.GENERIC:
			return `https://${bucketUrl}/defaultThemes/generic`
		default:
			console.error(`Theme strategy has wrong value ! Received "${themeStrategy}" value`)
			return `https://${bucketUrl}/defaultThemes/generic`
	}
}

export const fillMissingValuesFromDefaultJSON = (themeObj: any, fallbackTheme: Theme) => {
	fillMissingValuesFromDefaultJSONRecursive(themeObj, fallbackTheme)
	return themeObj
}

export const getKioskTheme = (themeJSON: any) => {
	if (!themeJSON.kiosk) {
		console.error(`Theme JSON for kiosk doesn't have the kiosk field !`)
		return themeJSON
	}
	const kioskThemeDiffs = themeJSON.kiosk
	delete themeJSON.kiosk

	return merge(themeJSON, kioskThemeDiffs)
}

/**
 *
 * @param themeObj - theme object received from server
 * @param defaultThemeObj - fallback theme object
 *
 * Traversing recursively defaultThemeObj keys and doing the same path on themeObj to be sure no keys missing.
 *  1. When key in defaultThemeObj's key doesn't exist on themeObj - assign the defaultThemeObj's value.
 *  2. !!! Currently if themeObj already has the defaultThemeObj's key - do not change it !!!
 *     !!! Even in the case - the value associated with specific key is a primitive in themeObj but in defaultThemeObj is an object !!!
 *  3. It ignores "overrides" section completely
 */
const fillMissingValuesFromDefaultJSONRecursive = (themeObj: any, defaultThemeObj: any) => {
	Object.keys(defaultThemeObj).forEach((key) => {
		if (themeObj[key]) {
			if (isObject(themeObj[key]) && isObject(defaultThemeObj[key])) {
				fillMissingValuesFromDefaultJSONRecursive(themeObj[key], defaultThemeObj[key])
			}
		} else {
			console.error(`Theme object missing the "${key}" key. Assigning it a "${JSON.stringify(defaultThemeObj[key])}" value.`)
			themeObj[key] = defaultThemeObj[key]
		}
	})

	return themeObj
}
