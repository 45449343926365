// @ts-nocheck
import React from 'react'
import styled from 'styled-components'
import IconComponent from 'themes/IconComponent'
import TypographyPro from 'themes/TypographyPro'
import Button from 'components/common/ButtonBase'

const Icon = styled(IconComponent)`
	margin-bottom: 40px;

	@media (max-height: 676px) {
		margin-bottom: 30px;
	}
`

const Container = styled.div`
	padding: 40px 20px;
	text-align: center;

	@media (max-height: 700px) {
		padding: 5px 20px;
	}
`

const Title = styled(TypographyPro)`
	color: var(--primary);
	margin-bottom: 5px;
`

const SubTitle = styled(TypographyPro)`
	color: var(--inactive);
	margin-bottom: 40px;

	@media (max-width: 599px) {
		margin-bottom: 20px;
	}
`

const ErrorFindingStores = ({ title, subtitle, iconPath, buttonText, onClickHandler }) => (
	<Container>
		<Icon asset={iconPath} />
		<Title variant="BodyRegularHighlighted">{title}</Title>
		<SubTitle variant="BodyRegular">{subtitle}</SubTitle>
		{buttonText && (
			<Button onClick={onClickHandler} data-testid="no-stores-found-button">
				{buttonText}
			</Button>
		)}
	</Container>
)

export default ErrorFindingStores
