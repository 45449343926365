// @ts-nocheck
import React, { useContext } from 'react'
import { formatPrice } from 'utils/utils'
import { isNil } from 'lodash-es'
import { StoreContext } from '../../../contexts/StoreContext'
import TypographyPro from '../../../themes/TypographyPro'
import Checkbox from '@material-ui/core/Checkbox'
import styled from 'styled-components/macro'

const StyledTypographyTitle = styled(TypographyPro)`
	color: var(--footerAndDarkBackgrounds);
	line-height: 15px;

	@media (min-width: 1280px) {
		line-height: 48px;
	}

	.invalid & {
		color: red;
	}

	.optional & {
		color: var(--footerAndDarkBackgrounds) !important;
	}
`

const StyledTypographyPrice = styled(TypographyPro)`
	color: var(--disable);
`

const StyledRoot = styled.div`
	width: 100%;
	margin: 0;
	padding: 0 0 0 4px;
	border-bottom: 1px solid #d4d0d0;

	&:last-child {
		padding-bottom: 0;
	}

	cursor: pointer;
	color: black;
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media (min-width: 1280px) {
		padding: 2px 0 2px 4px;
	}
`

const StyledCheckBox = styled(Checkbox)`
	transform: scale(1.2);
	border-radius: 0;
	color: black;

	&.Mui-checked {
		color: black;
	}

	@media (min-width: 1280px) {
		transform: scale(1.4);
	}
`
const MultiSelect = ({ id, price, label, isPlusIconHidden, currency, selected, handleSelectionChange }) => {
	const { store } = useContext(StoreContext)
	const rest = store.data

	const handleChange = (e) => {
		handleSelectionChange(id)
	}

	return (
		<StyledRoot onClick={handleChange} id={id} data-testid="menu-item-multi-select-row">
			<StyledTypographyTitle variant="BodyRegular" component="span">
				<span>{label}</span>

				{!isNil(price) && price > 0 && (
					<StyledTypographyPrice variant="BodySmall" component="span">
						&nbsp;
						{!isPlusIconHidden && <span>+</span>}
						{formatPrice(price, currency, rest.countryCode)}
					</StyledTypographyPrice>
				)}
			</StyledTypographyTitle>

			<StyledCheckBox
				disableRipple
				checked={selected}
				inputProps={{ 'aria-label': 'decorative checkbox', 'data-testid': 'multi-select-input' }}
			/>
		</StyledRoot>
	)
}
export default MultiSelect
