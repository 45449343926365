const isPushNotificationDeclined = () => {
	const lastDate = localStorage.getItem('pushNotificationsDeclineDate')

	if (lastDate) {
		const lastDeclineDate = new Date(parseInt(lastDate))
		const now = new Date()

		const timeDiff = now.getTime() - lastDeclineDate.getTime()
		const daysPassed = timeDiff / (1000 * 3600 * 24)

		if (daysPassed < 21) {
			return false
		}
	}

	return true
}

const getSessionId = () => localStorage.getItem('sessionId')

const getPushNotificationsStatus = () => localStorage.getItem('pushNotificationsStatus')

export default {
	isPushNotificationDeclined,
	getPushNotificationsStatus,
	getSessionId,
}
