import Check from '@material-ui/icons/Check'
import React, { useState } from 'react'
import styled from 'styled-components'
import IconComponent from 'themes/IconComponent'
import TypographyPro from 'themes/TypographyPro'
import { getTranslatedTextByKey } from 'utils/utils'

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`

const Title = styled(TypographyPro).attrs({ variant: 'BodyRegular' })`
	color: var(--fonts);
`

const CodeContainer = styled.div`
	display: flex;
	justify-content: space-between;
	background: var(--optionalBackground);
	border-radius: 60px;
	border: 1px dashed var(--inactive);
	padding: 0.5rem 0.75rem;
	align-items: center;
`

const CopyContainer = styled.div`
	display: flex;
	align-items: flex-end;
	gap: 0.25rem;
	cursor: pointer;
`

const CopyIcon = styled(IconComponent).attrs({ asset: '/icons/copy.svg' })`
	font-size: 1rem;
	width: 1rem;
`

const Text = styled(TypographyPro).attrs({ variant: 'CTATextBig' })`
	color: var(--strokeMenuTitle);
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: 95%;
	margin: 0 auto;
`

interface Props {
	code: string
	className?: string
	showButton?: boolean
}

const CHECKED_DELAY = 2 * 1000

const CopyCode = ({ code, className, showButton = false }: Props, ref: React.Ref<HTMLDivElement>) => {
	const [checked, setChecked] = useState<boolean>(false)

	const copyToClipboard = (): NodeJS.Timeout => {
		navigator.clipboard.writeText(code)
		setChecked(true)

		return setTimeout(() => setChecked(false), CHECKED_DELAY)
	}

	return (
		<Container>
			<Title>{getTranslatedTextByKey('eCommerce.coupons.modal.code.title', 'Coupon code:')}</Title>
			<CodeContainer data-testid="code-container" className={className} ref={ref}>
				<Text style={{ paddingRight: '.5em' }}>{code}</Text>
				{checked ? (
					<Check fontSize="small" color="secondary" />
				) : (
					showButton && (
						<CopyContainer onClick={copyToClipboard}>
							<CopyIcon />
							<Text>{getTranslatedTextByKey('eCommerce.coupons.modal.code.copy', 'Copy')}</Text>
						</CopyContainer>
					)
				)}
			</CodeContainer>
		</Container>
	)
}

export default React.forwardRef(CopyCode)
