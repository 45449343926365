import { action, observable } from 'mobx'
import { CheckCouponOn, type CouponFlowDependencies, type CouponToApply, type StartCouponFlowProps } from './types'
import { Page } from 'utils/constants'
import routeToPage from 'utils/routeToPage'
import type { Coupon } from 'types/Coupons'
import { Channel, CouponSource, CouponStatus, OfferType, OrderType } from 'types/Coupons'
import CouponError, { ErrorCode } from 'mobx/Coupons/errors'
import { sendCustomEvent } from 'utils/analytics/analytics'
import { buildChannelsErrorText } from 'utils/coupon/couponUtils'

export default class CouponFlowStore {
	private readonly dependencies: CouponFlowDependencies

	@observable couponToApply: CouponToApply | null = null

	@observable couponToUnapply: Coupon | null = null

	constructor(dependencies: CouponFlowDependencies) {
		this.dependencies = dependencies
	}

	@action
	setCouponToApply(couponToApply: CouponToApply): void {
		this.couponToApply = couponToApply
	}

	@action
	setCouponToUnapply(couponToUnapply: Coupon): void {
		this.couponToUnapply = couponToUnapply
	}

	@action
	isCouponToApplyAvailableFor(orderType: OrderType) {
		return this.couponToApply?.coupon?.orderTypes.includes(orderType) ?? false
	}

	@action
	clearCouponToApply(): void {
		this.couponToApply = null
	}

	@action
	clearCouponToUnapply(): void {
		this.couponToUnapply = null
	}

	@action
	async applyFromCouponModal(coupon: Coupon): Promise<boolean> {
		console.log('applyFromCouponModal', coupon)

		this.dependencies.setCouponModal(null)

		console.log('Applied coupon', coupon)

		sendCustomEvent({
			category: 'apply_from_popup',
			action: 'clicked',
			coupon_name: coupon.title,
			coupon_code: coupon.code,
			price: coupon.price,
			order_type: [...coupon.orderTypes],
			offer_type: coupon.offerType,
			page_path: window.location.pathname,
		})

		// throw errors that needs to be displayed in the modal

		if (!this.couponToApply) {
			// const { code } = coupon
			// this.start({ code, orderTypeToForce: null, openModal: false })
			const validated = this.validateCoupon(coupon)
			if (!validated) {
				return false
			}

			this.setCouponToApply({ coupon, orderTypeToForce: null, ready: false })
		}

		return this.handleLocalization()
	}

	validateCoupon(coupon: Coupon): boolean {
		if (!this.dependencies.isCouponForChannel(coupon.channels)) {
			const error = new CouponError('Coupon is not available for this channel', ErrorCode.COUPON_NOT_AVAILABLE_FOR_CHANNEL)
			const channelsText = buildChannelsErrorText(coupon)
			const errorText = error.text().replace('{{channel}}', channelsText)
			this.dependencies.showError(errorText, null)
			return false
		}
		return true
	}

	@action
	async start({ code, orderTypeToForce, openModal = true, checkCouponOn = CheckCouponOn.CHAIN }: StartCouponFlowProps): Promise<boolean> {
		const [trimmedCode] = code.split('§§')

		const loggedIn = this.dependencies.isUserLoggedIn()

		const coupon = await this.dependencies.getCoupon(trimmedCode, checkCouponOn).catch((err: CouponError) => {
			if (err.code === ErrorCode.ORG_WITH_INTEGRATION_DISCOUNTS_ERROR && checkCouponOn === CheckCouponOn.STORE) {
				return {
					code,
					title: { en_US: 'Organization with integration discounts' },
					description: { en_US: 'This organization has discounts for integration orders. Please use the integration to place your order.' },
					orderTypes: [OrderType.DELIVERY, OrderType.PICKUP],
					channels: [Channel.WEB, Channel.APP, Channel.KIOSK, Channel.MESSENGER, Channel.TELEGRAM, Channel.WHATSAPP],
					flags: {
						requireLogin: { value: false },
						wallet: { value: false },
						applied: { value: false },
					},
					offerType: OfferType.PERCENTAGE_DISCOUNT,
					price: 0,
					status: CouponStatus.AVAILABLE,
					id: code,
					img: '',
					source: CouponSource.EXTERNAL,
					timestamp: new Date(),
				} as Coupon
			}
			this.dependencies.showError(err.text(), null)
			return null
		})

		console.log('couponFlow start coupon', coupon)
		console.log('couponFlow start orderTypeToForce', orderTypeToForce)
		console.log('couponFlow start openModal', openModal)

		if (!coupon) {
			return false
		}

		const validated = this.validateCoupon(coupon)
		if (!validated) {
			return false
		}

		this.setCouponToApply({ coupon, orderTypeToForce, ready: false })

		/**
		 * Since the modal is public, we can always open it, regardless if user is logged in or not.
		 * The guard to prevent not logged-in users to start the flow should never be reached,
		 * since we always should prefer to open the login modal
		 */
		if (openModal || (coupon.flags.requireLogin?.value && !loggedIn)) {
			this.dependencies.setCouponModal(coupon)
		} else {
			if (this.couponToApply?.coupon.flags.requireLogin?.value && !loggedIn) {
				const error = new CouponError('You must be logged in to use this coupon', ErrorCode.COUPON_REQUIRES_LOGIN)
				this.dependencies.showError(error.text(), this.couponToApply.coupon)
				return false
			}

			return this.handleLocalization()
		}

		return true
	}

	@action
	async unapply(coupon: Coupon): Promise<void> {
		if (!coupon) {
			const error = new CouponError('Coupon not found', ErrorCode.COUPON_NOT_FOUND)
			this.dependencies.showError(error.text(), null)
			return
		}

		if (!this.couponToUnapply) {
			this.setCouponToUnapply(coupon)
		}
	}

	async redirectToHomeAndRestartFlow() {
		const { router } = this.dependencies
		if (!router) {
			console.error('Router is not set')
			return
		}
		console.log('redirect to home page')
		await router.push(`/home`)
		console.log('restart flow')
		await this.handleLocalization()
	}

	async resetLocalizationAndRestartFlow() {
		this.dependencies.resetLocalization()
		await this.handleLocalization()
	}

	userIsLocalizedExitPoint(menuUrl: string) {
		const { router } = this.dependencies
		if (!router) {
			console.error('Router is not set')
			return
		}
		const { pathname } = router
		const currentPage = routeToPage(pathname, true)
		const pagesWithNoRedirect = [Page.MENU, Page.CHECKOUT]

		if (!currentPage || !pagesWithNoRedirect.includes(currentPage)) {
			router?.push(`${menuUrl}`)
		}
	}

	@action
	async handleLocalization(): Promise<boolean> {
		const { isChainClosed, isLocalized, isStoreClosed, openLocalizationModal, router, isPageLegal, isUserLoggedIn } = this.dependencies

		console.log('handleLocalization')
		if (!router) {
			console.error('Router is not set')
			return false
		}

		if (!this.couponToApply) {
			const error = new CouponError('Coupons store is not set', ErrorCode.COUPON_NOT_FOUND)
			this.dependencies.showError(error.text(), null)
			return false
		}

		if (isChainClosed()) {
			const error = new CouponError('Chain is closed', ErrorCode.CHAIN_CLOSED)
			this.dependencies.showError(error.text(), this.couponToApply.coupon)
			return false
		}

		if (this.couponToApply?.coupon.flags.requireLogin?.value && !isUserLoggedIn()) {
			const error = new CouponError('You must be logged in to use this coupon', ErrorCode.COUPON_REQUIRES_LOGIN)
			this.dependencies.showError(error.text(), this.couponToApply.coupon)
			return false
		}

		// todo: check if coupon is expired etc

		if (isLocalized()) {
			console.log('handleLocalization localized')
			const storeClosed = await isStoreClosed()

			const session = await this.dependencies.getAndUpdateSession()

			// if menuUrl is null, we stop the flow here because we are on an order lock scenario that redirects to order-confirmation
			if (!session?.menuUrl) {
				return false
			}

			if (storeClosed) {
				const error = new CouponError('Your store is closed, you must choose another one', ErrorCode.STORE_CLOSED)
				this.dependencies.showError(error.text(), this.couponToApply.coupon)
				await this.resetLocalizationAndRestartFlow()
				return false
			}
			this.userIsLocalizedExitPoint(session.menuUrl)
		} else {
			console.log('handleLocalization not localized')
			const legalPage = isPageLegal(router.pathname)
			console.log('handleLocalization legalPage', legalPage)
			if (!legalPage) {
				console.log('handleLocalization calling redirectToHomeAndRestartFlow')
				await this.redirectToHomeAndRestartFlow()
				return false
			}
			console.log('handleLocalization calling openLocalizationModal')
			openLocalizationModal(this.couponToApply)
		}

		this.couponToApply.ready = true
		return true
	}
}
