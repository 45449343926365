interface Location {
	city: string
	countryCodeISO2: string
	countryCodeISO3: string
	address: string
	coordinates: {
		lat: number
		lng: number
	}
}

type StoreOrderTypePaymentMethodsEntity = Record<
	'cash' | 'creditOnline' | 'creditUponDelivery',
	{
		enabled: boolean
	}
>

interface StoreOrderTypeEntity {
	enabled: boolean
	paymentMethods: StoreOrderTypePaymentMethodsEntity
}

type StoreOrderTypesEntity = Record<'delivery' | 'peakup' | 'curbside' | 'tableside' | 'takeAway' | 'dineIn', StoreOrderTypeEntity>

export enum StoreStatus {
	Active = 'Active',
	Closed = 'Closed',
}

export interface ExceptionDate {
	year: number
	month: number
	day: number
	hour: number
	minute: number
}
export default interface AvailabilityException {
	comment?: object
	start: ExceptionDate
	end: ExceptionDate
	available: boolean
}

export interface AvailabilitySegment {
	minuteOfWeek: number
	durationMins: number
}
export interface Availability {
	weekly: AvailabilitySegment[]
	exceptions?: AvailabilityException[]
}

export interface StoreFrontResponse {
	id: string
	status: StoreStatus
	location: Location
	orderTypes: StoreOrderTypesEntity
	availability: Availability
	title: Record<string, string>
}
