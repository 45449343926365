// @ts-nocheck
import React, { useState, useEffect } from 'react'

/**
 * Hook to determine if actual rendering is on client side, and not on server side.
 * */
export function useClientSideRendering() {
	const [isClientSideRendering, setIsClientSideRendering] = useState(false)

	useEffect(() => {
		setIsClientSideRendering(true)
	}, [])

	return isClientSideRendering
}
