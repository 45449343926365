// @ts-nocheck
import { css } from 'styled-components'

export const breakpoints = {
	xxs: '320px' /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */,
	xs: '480px' /* smartphones, Android phones, landscape iPhone */,
	sm: '600px' /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */,
	md: '801px' /* tablet, landscape iPad, lo-res laptops ands desktops */,
	lg: '1025px' /* big landscape tablets, laptops, and desktops */,
	xl: '1281px' /* hi-res laptops and desktops */,
} as const

export const desktop = '(min-width: 1200px)'
export const laptop = '(min-width: 900px)'
export const tablet = '(min-width: 600px)'

export const upToDesktop = '(max-width: 1200px)'
export const upToLaptop = '(max-width: 900px)'
export const upToTablet = '(max-width: 600px)'

export const mediaQueriesBreakpoints = {
	xs: '(min-width: 320px)' /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */,
	mobile: '(min-width: 480px)' /* smartphones, Android phones, landscape iPhone */,
	sm: '(min-width: 600px)' /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */,
	md: '(min-width: 801px)' /* tablet, landscape iPad, lo-res laptops ands desktops */,
	laptop: '(min-width: 900px)',
	desktop: '(min-width: 1200px)',
	lg: '(min-width: 1025px)' /* big landscape tablets, laptops, and desktops */,
	xl: '(min-width: 1281px)' /* hi-res laptops and desktops */,
}

export const respondTo: Record<keyof typeof breakpoints, typeof css> = Object.keys(breakpoints).reduce(
	(accumulator, label) => ({
		...accumulator,
		[label]: (...args) => css`
			@media (max-width: ${breakpoints[label]}) {
				${css(...args)};
			}
		`,
	}),
	{}
)

export const respondAbove: Record<keyof typeof breakpoints, typeof css> = Object.keys(breakpoints).reduce(
	(accumulator, label) => ({
		...accumulator,
		[label]: (...args) => css`
			@media (min-width: ${breakpoints[label]}) {
				${css(...args)};
			}
		`,
	}),
	{}
)

export const respondLandscapeBelow: Record<keyof typeof breakpoints, typeof css> = Object.keys(breakpoints).reduce(
	(accumulator, label) => ({
		...accumulator,
		[label]: (...args) => css`
			@media screen and (orientation: landscape) and (max-width: ${breakpoints[label]}) {
				${css(...args)};
			}
		`,
	}),
	{}
)

export const limitTextNumberOfLines = (lines) => css`
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: ${lines};
	-webkit-box-orient: vertical;
`
