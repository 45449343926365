// @ts-nocheck
import { action, observable } from 'mobx'
import { convertGooglePlaceObjToServerObj, getTranslatedTextByKey, isJsonString, validateAddress, geoCode, initSessionAndLoadMenu } from 'utils/utils'
import { isEmpty } from 'lodash-es'
import { CONSTANTS, ORDER_TYPES } from 'utils/constants'
import AddressManagerDependencies from './AddressManagerDependencies'
import queryString from 'query-string'
import { getStoreInfo } from './utils'
import { StoreStatus } from './types'
import { isAvailableNow } from '@tictuk/item-availability'
import loadMenu from 'utils/api/loadMenu/loadMenu'
import storage from 'utils/storage'
import type { NextRouter } from 'next/router'
import type _Cart from 'mobx/Cart'
import type _Home from 'mobx/Home'
import type _Infra from 'mobx/Infra'
import type _User from 'mobx/User'
import type React from 'react'
import type { Store } from 'types/StoreContext'
import type { GeocoderResult } from 'google.maps'
import LocalizationError, { ErrorCode } from './errors'

type Dependencies = typeof AddressManagerDependencies

class AddressManager {
	dependencies: Dependencies

	@observable addressPopup = { open: false, mapButtonClicked: false }

	@observable loading = false

	@observable localizedAddress = {
		localizedOrderType: '',
		delivery: {
			geometry: {
				location: {
					lat: 0,
					lng: 0,
				},
			},
		},
		pickup: {
			geometry: {
				location: {
					lat: 0,
					lng: 0,
				},
			},
		},
	}

	// fullAddress observable is being initialized with coordinates structure
	// that is imitating the structure of google maps api coordinates
	// then we are moving to work with fullAddress which is also being saved to localStorage while tempAddress is not
	@observable tempAddress = {
		delivery: {
			geometry: {
				location: {
					lat: 0,
					lng: 0,
				},
			},
		},
		pickup: {
			geometry: {
				location: {
					lat: 0,
					lng: 0,
				},
			},
		},
	}

	@observable private _showLocalizedAddress = false

	// tempAddress is being used in the homepage for moving around address information until moving to the menu
	@observable localizedOrderType
	/* ------------------------------ fullAddress related code ------------------------------ */
	// this code is related to fullAddress which is the address that is going to be saved to localStorage and used
	// through menu and onwards

	constructor(dependencies: Dependencies) {
		this.dependencies = dependencies
	}

	@action
	autoLocalize = async (
		storeId: string,
		title: Record<string, string>,
		addressObject: GeocoderResult,
		User: typeof _User,
		Cart: typeof _User,
		Home: typeof _Home,
		router: NextRouter,
		setStore: React.Dispatch<React.SetStateAction<Store>>
	) => {
		if (!storeId || !AddressManager || !User || !Cart || !Home) {
			return
		}
		addressObject = {
			...addressObject,
			title,
		}
		const orderType = ORDER_TYPES.PEAKUP
		this.setFullAddress(addressObject, orderType, false, true)
		this.setMatchingStoreIdToAddressByOrderType(storeId, orderType)

		// We reset delivery fee for peakup
		const estimatedDeliveryCharge = {
			amount: 0,
			type: ORDER_TYPES.PEAKUP,
		}
		Cart.setServerCharges((Cart.serverCharges ?? []).filter((item) => item.type !== ORDER_TYPES.DELIVERY))
		localStorage.setItem('estimatedDeliveryFee', JSON.stringify(estimatedDeliveryCharge))

		User.setOrderType(orderType)
		await initSessionAndLoadMenu(loadMenu, storage)(false, storeId, router, setStore, Home, Cart)
		this.setShowLocalizedAddress(true)
	}

	@action
	handleForceLocalization = async (
		storeId: string,
		User: typeof _User,
		Cart: typeof _Cart,
		Home: typeof _Home,
		Infra: typeof _Infra,
		router: NextRouter,
		setStore: React.Dispatch<React.SetStateAction<Store>>
	) => {
		if (!storeId || !this.dependencies || !User || !Cart || !Home || !Infra) {
			return
		}
		console.log('AddressManager handleForceLocalization')
		const { location, status, orderTypes, availability, title, id } = await getStoreInfo(storeId, Infra)

		if (status !== StoreStatus.Active) {
			throw new LocalizationError('Store is Shut Down', ErrorCode.STORE_CLOSED)
		}

		if (!availability) {
			throw new LocalizationError('Store is missing availability', ErrorCode.STORE_CLOSED)
		}

		const storeAvailable = isAvailableNow(availability)

		if (!storeAvailable) {
			throw new LocalizationError('Store is closed', ErrorCode.STORE_CLOSED)
		}

		if (!orderTypes.peakup.enabled) {
			throw new LocalizationError(`Pickup is disabled for store ${storeId}`, ErrorCode.PICKUP_DISABLED_FOR_STORE)
		}

		if (isEmpty(location)) {
			throw new LocalizationError(`Pickup is disabled for store ${storeId}`, ErrorCode.STORE_CLOSED)
		}

		const geoCodeResult = await geoCode(location.address)

		await this.autoLocalize(id, title, geoCodeResult, User, Cart, Home, router, setStore)
	}

	@action
	clearMenuUrlCache = () => this.dependencies.deleteMenuUrl()

	@action
	setShowLocalizedAddress = (showLocalizedAddress) => {
		this._showLocalizedAddress = showLocalizedAddress
	}

	get showLocalizedAddress() {
		return this._showLocalizedAddress && this.hasMenuUrl()
	}

	/**
	 * for LastAddresses component: sets the storeId for later uses to know which store was used for this address
	 * @param storeId
	 * @param orderType
	 */
	@action setMatchingStoreIdToAddressByOrderType = (storeId, orderType) => {
		const convertedOrderType = orderType === (ORDER_TYPES.PEAKUP || ORDER_TYPES.PICKUP) ? ORDER_TYPES.PICKUP : ORDER_TYPES.DELIVERY
		const newAddressByOrderTypeObj = this.localizedAddress[convertedOrderType]
		newAddressByOrderTypeObj.storeId = storeId
		this.localizedAddress = {
			...this.localizedAddress,
			[convertedOrderType]: newAddressByOrderTypeObj,
		}
		this.setAddressToLocalStorage(this.localizedAddress)
		this.setAddressToCookies(this.localizedAddress)
	}

	/**
	 * get only the formatted address string by order type
	 * @param orderType
	 * @returns {*}
	 */
	@action getFormattedAddressByOrderType = (orderType) => this.localizedAddress[orderType].formatted_address

	/**
	 * returns a validated address response based on delivery type - the function will validate address based on the
	 * address saved in the address manager
	 * @param orderType
	 * @param showLoader
	 * @returns {Promise<*>}
	 */
	@action getValidateAddressResponseByOrderType = async (orderType = ORDER_TYPES.DELIVERY, showLoader = false) => {
		const convertedAddressObj = convertGooglePlaceObjToServerObj(this.localizedAddress[orderType])
		const validateAddressResponse = await validateAddress(convertedAddressObj, showLoader)
		return validateAddressResponse
	}

	/**
	 * return coordinates after checking for their type and returning them as floats
	 * due to different structures and google map api objects we check and return the coordinates as values
	 * @param orderType
	 * @returns {{lng: *, lat: *}}
	 */
	@action getAddressCoordinatesByOrderType = (orderType) => {
		const addressBasedOnOrderType = this.localizedAddress[orderType].geometry.location
		const lat = typeof addressBasedOnOrderType.lat === 'function' ? addressBasedOnOrderType.lat() : addressBasedOnOrderType.lat
		const lng = typeof addressBasedOnOrderType.lng === 'function' ? addressBasedOnOrderType.lng() : addressBasedOnOrderType.lng

		return { lat, lng }
	}

	/**
	 * Sets the address object as a format that the convertGooglePlaceObjToServerObj understands
	 * also saves the formatted object to LS
	 * @param addressInfo - the data of the address, usually from Google Maps API Geocode
	 * @param orderType - order type for the saved address, usually "delivery"
	 * @param fromIFrame - data coming from addressForm.html iFrame has slightly different format to handle
	 * @param removeStoreId - in homepage use cases before onSubmit we need to remove the storeId attached to the address
	 */
	@action setFullAddress = (addressInfo, orderType, fromIFrame = false, removeStoreId = false) => {
		// if orderType exist, use it to set a specific order type in the object
		if (orderType) {
			const convertedOrderType = [ORDER_TYPES.PEAKUP, ORDER_TYPES.PICKUP].includes(orderType) ? ORDER_TYPES.PICKUP : ORDER_TYPES.DELIVERY
			// if object came from addressForm.html iFrame it is in different structure then we handle it different
			const _localizedAddress = fromIFrame
				? {
						formatted_address: addressInfo.formattedAddress,
						geometry: {
							location: {
								lat: addressInfo.latitude,
								lng: addressInfo.longitude,
							},
						},
						storeId: this.localizedAddress[convertedOrderType].storeId ? this.localizedAddress[convertedOrderType].storeId : '',
						...addressInfo,
				  }
				: {
						...this.localizedAddress[convertedOrderType],
						storeId: removeStoreId ? '' : this.localizedAddress[convertedOrderType].storeId || '',
						...addressInfo,
						// removeStoreId is being used to detach an address from a store so it won't be associated with previous storeid
				  }

			this.localizedAddress[convertedOrderType] = _localizedAddress
			this.localizedOrderType = convertedOrderType
			this.localizedAddress.localizedOrderType = convertedOrderType

			// append the new orderType specific object to the existing object
			const addressObjectToSave = {
				localizedOrderType: convertedOrderType,
				...this.localizedAddress,
				[convertedOrderType]: {
					...this.localizedAddress[convertedOrderType],
				},
			}
			this.setAddressToLocalStorage(addressObjectToSave)
			this.setAddressToCookies(this.localizedAddress)
		} else {
			if (!isEmpty(addressInfo.delivery) || !isEmpty(addressInfo.pickup)) {
				// this.localizedAddress.localizedOrderType = this.getLocalizedOrderType()
				this.localizedAddress.localizedOrderType = addressInfo.localizedOrderType
			}
			// no orderType was specified then we save both delivery and pickup if exist
			if (!isEmpty(addressInfo.delivery)) {
				this.localizedAddress = {
					...this.localizedAddress,
					delivery: addressInfo.delivery,
				}
			}
			if (!isEmpty(addressInfo.pickup)) {
				this.localizedAddress = {
					...this.localizedAddress,
					pickup: addressInfo.pickup,
				}
			}
			this.setAddressToLocalStorage(this.localizedAddress)
			this.setAddressToCookies(this.localizedAddress)
		}
	}

	/**
	 * get the fullAddress object of both delivery and pickup
	 * @returns {{delivery: {geometry: {location: {lng: number, lat: number}}}, pickup: {geometry: {location: {lng: number, lat: number}}}}}
	 */
	@action getFullAddress = () => this.localizedAddress

	/**
	 * get the full address object based on provided order type
	 * @param orderType
	 * @returns {*}
	 */
	@action getFullAddressByOrderType = (orderType) => this.localizedAddress[orderType]

	@action getLocalizedOrderType = () => {
		if (this.isUserLocalized()) {
			const addressFromLS = this.getAddressFromLocaleStorage()
			if (addressFromLS.localizedOrderType) {
				this.localizedAddress.localizedOrderType = addressFromLS.localizedOrderType
			}
			return this.localizedAddress.localizedOrderType
		}
	}

	getLocalizedStoreId = () => this.dependencies.getStoreId()

	@action resetLocalization = () => {
		this.localizedAddress = {
			localizedOrderType: null,
			delivery: {
				geometry: {
					location: {
						lat: 0,
						lng: 0,
					},
				},
			},
			pickup: {
				geometry: {
					location: {
						lat: 0,
						lng: 0,
					},
				},
			},
		}
		this.setFullAddress(this.localizedAddress)
		this.setShowLocalizedAddress(false)
		this.clearMenuUrlCache()
	}

	/**
	 * only function that is allowed to save to addressObject on localStorage
	 * @param fullAddressObject
	 */
	setAddressToLocalStorage = (fullAddressObject) => {
		const _fAO = JSON.stringify(fullAddressObject)
		localStorage.setItem(CONSTANTS.LOCAL_STORAGE_ADDRESS_NAME, _fAO)
	}

	setAddressToCookies = (fullAddressObject) => {
		const deliveryLocation = fullAddressObject.delivery.geometry.location
		const pickupLocation = fullAddressObject.pickup.geometry.location

		const deliveryLocationIsFunction = typeof deliveryLocation.lat === 'function'
		const pickupLocationIsFunction = typeof pickupLocation.lat === 'function'

		const deliveryLatLngValue = deliveryLocationIsFunction
			? { lat: deliveryLocation.lat(), lng: deliveryLocation.lng() }
			: { lat: deliveryLocation.lat, lng: deliveryLocation.lng }
		const pickupLatLngValue = pickupLocationIsFunction
			? { lat: pickupLocation.lat(), lng: pickupLocation.lng() }
			: { lat: pickupLocation.lat, lng: pickupLocation.lng }

		const formattedFullAddressObject = {
			delivery: {
				geometry: {
					location: deliveryLatLngValue,
				},
				formatted_address: fullAddressObject.delivery.formatted_address,
			},
			pickup: {
				geometry: {
					location: pickupLatLngValue,
				},
				formatted_address: fullAddressObject.pickup.formatted_address,
			},
			localizedOrderType: fullAddressObject.localizedOrderType,
		}
	}

	/**
	 * only function that is allowed to get addressObject from localStorage
	 * @returns {string}
	 */
	getAddressFromLocaleStorage = () => {
		let parsedLocalStorageLocalizedAddress =
			typeof localStorage !== 'undefined' ? JSON.parse(localStorage.getItem(CONSTANTS.LOCAL_STORAGE_ADDRESS_NAME)) : {}
		if (!isEmpty(parsedLocalStorageLocalizedAddress) && !parsedLocalStorageLocalizedAddress.hasOwnProperty('localizedOrderType')) {
			this.clearAddressFormLocalStorage()
			parsedLocalStorageLocalizedAddress = null
		}
		return typeof localStorage !== 'undefined' ? parsedLocalStorageLocalizedAddress : {}
	}

	clearAddressFormLocalStorage = () => {
		localStorage.removeItem(CONSTANTS.LOCAL_STORAGE_ADDRESS_NAME)
	}
	/* ------------------------------ tempAddress related code ------------------------------ */
	// this code is related to tempAddress which is the address that is going to be used until we move to the menu
	// at this point fullAddress is being saved to localStorage and used

	/**
	 * set temporary address info to this.tempAddress for use in homepage before moving to menu
	 * @param tempAddressInfo
	 * @param orderType
	 */
	@action setTempAddressByOrderType = (tempAddressInfo, orderType) => {
		const convertedOrderType = orderType === (ORDER_TYPES.PEAKUP || ORDER_TYPES.PICKUP) ? ORDER_TYPES.PICKUP : ORDER_TYPES.DELIVERY
		if (orderType && !isEmpty(tempAddressInfo)) {
			this.tempAddress[convertedOrderType] = { ...tempAddressInfo }
		}
	}

	/**
	 * get the tempAddress formatted_address value based on orderType
	 * @param orderType
	 * @returns {*}
	 */
	@action getTempFormattedAddressByOrderType = (orderType) => {
		const convertedOrderType = orderType === (ORDER_TYPES.PEAKUP || ORDER_TYPES.PICKUP) ? ORDER_TYPES.PICKUP : ORDER_TYPES.DELIVERY
		return this.tempAddress[convertedOrderType].formatted_address
	}

	/**
	 * get the full tempAddress object based on orderType
	 * @param orderType
	 * @returns {*}
	 */
	@action getTempAddressByOrderType = (orderType) => {
		const convertedOrderType = orderType === (ORDER_TYPES.PEAKUP || ORDER_TYPES.PICKUP) ? ORDER_TYPES.PICKUP : ORDER_TYPES.DELIVERY
		return this.tempAddress[convertedOrderType]
	}

	/**
	 * get tempAddress coordinates by order type
	 * @param orderType
	 * @returns {{lng: (*|number), lat: (*|number)}}
	 */
	@action getTempAddressCoordinatesByOrderType = (orderType) => {
		const convertedOrderType = orderType === (ORDER_TYPES.PEAKUP || ORDER_TYPES.PICKUP) ? ORDER_TYPES.PICKUP : ORDER_TYPES.DELIVERY
		const addressBasedOnOrderType = this.tempAddress[convertedOrderType].geometry.location
		const lat = typeof addressBasedOnOrderType.lat === 'function' ? addressBasedOnOrderType.lat() : addressBasedOnOrderType.lat
		const lng = typeof addressBasedOnOrderType.lng === 'function' ? addressBasedOnOrderType.lng() : addressBasedOnOrderType.lng
		return { lat, lng }
	}

	@action getDeliveryAddressByOrderType = (orderType) => {
		const addressLocalStorage = this.getAddressFromLocaleStorage()
		if (!isEmpty(addressLocalStorage)) {
			if (this.isUserLocalized() && addressLocalStorage[orderType].hasOwnProperty('formatted_address')) {
				return addressLocalStorage[orderType].formatted_address
			}
		}
	}

	@action setLoading = (loading) => {
		this.loading = loading
	}

	@action setAddressPopup = (data) => {
		this.addressPopup = data
	}

	@action closeAddressPopup = () => {
		this.addressPopup = { open: false }
	}

	// TODO this method does not consider the current order-type! Eg if the user is has selected Delivery, then checking the pickip address is irrelevant!
	@action isUserLocalized = () => {
		const addressLocalStorage = this.getAddressFromLocaleStorage()
		if (!isEmpty(addressLocalStorage) && this.hasMenuUrl()) {
			return !isEmpty(addressLocalStorage?.delivery?.formatted_address) || !isEmpty(addressLocalStorage?.pickup?.formatted_address)
		}

		return false
	}

	hasMenuUrl = () => !!this.dependencies.getMenuUrl()

	// TODO - need this?
	@action isUserLocalizedByStoreIdByOrderType = (orderType) => {
		const addressLocalStorage = this.getAddressFromLocaleStorage()
		if (!isEmpty(addressLocalStorage)) {
			if (orderType === CONSTANTS.DELIVERY_METHODS.DELIVERY) {
				return !isEmpty(addressLocalStorage?.delivery?.storeId)
			}

			if (orderType === CONSTANTS.DELIVERY_METHODS.PICKUP) {
				return !isEmpty(addressLocalStorage?.pickup?.storeId)
			}
		}
		return false
	}

	@action getAddressWhenLocalized = () => {
		const addressLocalStorage = this.getAddressFromLocaleStorage()
		let address = ''
		if (!isEmpty(addressLocalStorage)) {
			if (Object(addressLocalStorage).hasOwnProperty('delivery') && Object(addressLocalStorage.delivery).hasOwnProperty('formatted_address')) {
				address = `${getTranslatedTextByKey('expressCheckout.deliveryTo')}: ${addressLocalStorage.delivery.formatted_address}`
			}
			if (Object(addressLocalStorage).hasOwnProperty('pickup') && Object(addressLocalStorage.pickup).hasOwnProperty('formatted_address')) {
				address = `${getTranslatedTextByKey('expressCheckout.pickupFrom')}: ${addressLocalStorage.pickup.formatted_address}`
			}
		}

		return address
	}

	@action storeMenuUrlToLS = (menuUrl) => this.dependencies.setMenuUrl(menuUrl)

	@action onMenuClickWhenLocalized = async () => {
		let menuUrl = this.dependencies.getMenuUrl()
		const storeId = this.dependencies.getStoreId()

		// Edge case : if url or storeId don't exist, we reset value in localstorage and redirect to home page
		// this can happen if :
		// * user did loggin, completed an order, then few days later loggin again
		// * user did loggin, completed an order, then few days later loggin on another device
		// * clear the localstorage manually
		if (!menuUrl || !storeId) {
			this.resetLocalization()
			window.location.href = '/'
			return
		}

		if (menuUrl && storeId) {
			const { wru, request, ref, app, pc, tictuk_listener, cust } = queryString.parse(menuUrl)
			const refData = JSON.parse(decodeURIComponent(ref))
			let serverSession

			try {
				serverSession = await this.dependencies.handleCheckRequest({
					wru,
					request,
					cust,
					storeId,
					tictuk_listener,
				})
				menuUrl = `/menu${menuUrl}`
			} catch (e) {
				console.error(`error on checkRequest: ${JSON.stringify(e)}`)
				menuUrl = await this.dependencies.handleInitNewSession({ ref, wru, storeId, pc })

				// if menu url is empty, it means user is redirected to order-confirmation (because of an order lock)
				if (menuUrl === '') {
					return
				}

				const newMenuQueryParams = queryString.parse((menuUrl ?? '').split('?')[1])

				serverSession = await this.dependencies.handleCheckRequest({
					wru,
					request: newMenuQueryParams.request,
					cust: newMenuQueryParams.cust,
					storeId: newMenuQueryParams.j,
					tictuk_listener: newMenuQueryParams.tictuk_listener,
				})
			}

			const menuRes = await this.dependencies.handleLoadMenu({
				chainId: pc,
				storeId,
				orderTypeFromQS: refData.orderType,
				appId: Number(app) || serverSession.appId,
				stopLoading: false,
			})

			const storeMetaData = await this.dependencies.handleGetStore({
				wru,
				request: serverSession.request,
				cust,
				tictuk_listener,
			})

			return {
				menuRes,
				storeMetaData,
				serverSession,
				menuUrl,
			}
		}
	}

	@action makeLocalizedForOrderTypeAndStoreId = async (wru, pc, orderType, storeId, ref) => {
		if (storeId) {
			const decodedRef = decodeURIComponent(ref)
			const isRefJSON = isJsonString(decodedRef)
			const refJsonString = isRefJSON
				? JSON.stringify({
						...JSON.parse(decodedRef),
						orderType,
				  })
				: JSON.stringify({ decodedRef, orderType })
			const encodedRef = encodeURIComponent(refJsonString)

			const newMenuPath = await this.dependencies.handleInitNewSession({ ref: encodedRef, wru, storeId, pc })
			const newMenuQueryParams = queryString.parse((newMenuPath ?? '').split('?')[1])

			const serverSession = await this.dependencies.handleCheckRequest({
				wru,
				request: newMenuQueryParams.request,
				cust: newMenuQueryParams.cust,
				storeId: newMenuQueryParams.j,
				tictuk_listener: newMenuQueryParams.tictuk_listener,
			})

			const menuRes = await this.dependencies.handleLoadMenu({
				chainId: pc,
				storeId,
				orderTypeFromQS: orderType,
				appId: serverSession.appid,
				stopLoading: true,
			})

			const storeMetaData = await this.dependencies.handleGetStore({
				wru,
				request: serverSession.request,
				cust: newMenuQueryParams.cust,
				tictuk_listener: newMenuQueryParams.tictuk_listener,
			})

			return {
				newMenuPath,
				menuRes,
				storeMetaData,
				serverSession,
			}
		}
	}
}

export default new AddressManager(AddressManagerDependencies)
