export enum CouponStatus {
	REDEEMED = 'REDEEMED',
	EXPIRED = 'EXPIRED',
	AVAILABLE = 'AVAILABLE',
}

export enum OrderType {
	IN_STORE = 'inStore',
	DELIVERY = 'delivery',
	PICKUP = 'pickup',
	DRIVETHROUGH = 'driveThru',
}

export const IN_STORE_ORDER_TYPES: readonly OrderType[] = [OrderType.IN_STORE, OrderType.DRIVETHROUGH]

export enum Channel {
	WHATSAPP = 'WhatsApp',
	MESSENGER = 'Messenger',
	WEB = 'Web',
	APP = 'App',
	TELEGRAM = 'Telegram',
	KIOSK = 'Kiosk',
}

export enum OfferType {
	ITEM = 'item',
	FIXED_DISCOUNT = 'fixedDiscount',
	PERCENTAGE_DISCOUNT = 'percentageDiscount',
	ITEM_DISCOUNT = 'itemDiscount',
}

export enum CouponSource {
	INTERNAL = 'internal',
	EXTERNAL = 'external',
	INDIVIDUAL = 'individual',
}

interface Flag<T> {
	value: T
}

export interface Flags {
	// Server-Side Flags
	requireLogin: Flag<boolean>
	showInMyCoupons: Flag<boolean>

	// Client-Side Flags
	wallet: Flag<boolean>
	applied: Flag<boolean>
}

interface BaseCoupon {
	id: string
	title: Record<string, string>
	description: Record<string, string>
	code: string
	expiration?: Date
	formattedExpiration?: string
	img: string
	orderTypes: OrderType[]
	channels: Channel[]
	offerType: OfferType
	source: CouponSource
	status: CouponStatus
	price: number
	flags: Partial<Flags>
	timestamp: Date
}

export interface StrippedIndividualCoupon extends Omit<BaseCoupon, 'source'> {
	totalUses: number
	usesLeft: number
	parentCode: string
	source: CouponSource.INDIVIDUAL
	attachedAt?: Date
}

export interface InStoreCoupon extends BaseCoupon {
	source: CouponSource.EXTERNAL
	orderTypes:
		| [OrderType.DRIVETHROUGH]
		| [OrderType.IN_STORE]
		| [OrderType.IN_STORE, OrderType.DRIVETHROUGH]
		| [OrderType.DRIVETHROUGH, OrderType.IN_STORE]
}

export type CouponFlags = keyof Flags
/**
 * Virtual fields that add shortcuts to access flag values
 * @example
 * // Assume we have a flag called 'wallet':
 * flags: {
 *   wallet?: {
 *     value: boolean
 *   }
 * }
 *
 * // We can access it in two ways:
 * coupon.flags.wallet?.value
 *
 * // or, using 'virtuals',
 * coupon.wallet // under the hood, calls coupon.flags.wallet?.value
 */
export type FlagShortcuts = Partial<{ [Key in CouponFlags]: Flags[Key]['value'] }>
// Make sure to update this list after adding a new flag
export const couponFlags = ['applied', 'requireLogin', 'wallet'] as const

export type StrippedCoupon = StrippedIndividualCoupon | BaseCoupon
export type Coupon = StrippedCoupon // & FlagShortcuts
export type IndividualCoupon = StrippedIndividualCoupon // & FlagShortcuts

/**
 * A coupon whose title and description are localized,
 * i.e. are in a specific language instead of containing all locale variants
 */
export interface LocalizedCoupon extends Omit<Coupon, 'title' | 'description'> {
	title: string
	description: string
}

export function isIndividual(coupon: Pick<Coupon, 'source'>): coupon is IndividualCoupon {
	return coupon.source === CouponSource.INDIVIDUAL
}

export function isInStore(coupon: Pick<Coupon, 'orderTypes'>): coupon is InStoreCoupon {
	return IN_STORE_ORDER_TYPES.some((orderType) => coupon.orderTypes.includes(orderType))
}
