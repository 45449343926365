// @ts-nocheck
import React from 'react'
import { usePlacesWidget } from 'react-google-autocomplete'
import { GOOGLE_MAPS_AUTOCOMPLETE_OPTIONS, GOOGLE_API_KEY } from 'utils/constants'

const GooglePlacesAutoComplete = ({ apiKey = GOOGLE_API_KEY, region, onPlaceSelected, renderTextField, ...rest }) => {
	const { ref } = usePlacesWidget({
		apiKey,
		googleMapsScriptBaseUrl: `https://maps.googleapis.com/maps/api/js`,
		onPlaceSelected,
		options: {
			types: GOOGLE_MAPS_AUTOCOMPLETE_OPTIONS,
			componentRestrictions: { country: region || '' },
		},
		...rest,
	})

	return renderTextField(ref)
}

export default GooglePlacesAutoComplete
