// @ts-nocheck
import React, { useEffect, useState } from 'react'
import StoresMap from 'components/home/ModalContent/StoresMap'
import StoresList from 'components/home/ModalContent/StoresList'
import Modal from '@material-ui/core/Modal'
import styled from 'styled-components/macro'
import { geoCode, getLocaleStr, getTranslatedTextByKey, localeToDir } from 'utils/utils'
import { inject, observer } from 'mobx-react'
import { CONSTANTS, ORDER_TYPES } from 'utils/constants'
import TypographyPro from 'themes/TypographyPro'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { isEmpty } from 'lodash-es'
import IconComponent from 'themes/IconComponent'
import Skeleton from '@material-ui/lab/Skeleton'
import { Card, Grid } from '@material-ui/core'
import { useCurrentUrlInfo } from 'hooks/useCurrentUrlInfo'

const BrandLogo = styled.div`
	background-image: url(${({ $logoUrl }) => $logoUrl});
	background-size: contain;
	height: 40px;
	flex: 1;
	background-repeat: no-repeat;
	background-position: center;
`

const ModalContainer = styled.div`
	outline: none;
	background-color: white;
	position: absolute;
	transform: translate(-50%, -50%);
	left: 50%;
	top: 50%;
	max-width: 1100px;
	border-radius: 15px;
	margin-top: ${(props) => (props.isMobileApp ? '60px' : '0')};

	@media (max-width: 1024px) {
		height: 100vh;
		width: 100%;
		padding-bottom: 23px;
		border-radius: 0;
		position: unset;
		transform: unset;
		left: unset;
		right: unset;
	}
`

const ModalHeader = styled.div`
	text-align: right;
	line-height: 0;
	height: 48px;
	padding: 0 16px;
	display: flex;
	align-items: center;
	background-color: var(--footerAndDarkBackgrounds);
`

const ModalBody = styled.div`
	height: 100%;
`

const MapAndStoresContainer = styled.div`
	background-color: white;
	width: 100%;
	height: 43vh;
	min-height: 450px;
	display: flex;

	&:focus {
		outline: none;
	}

	@media (max-width: 1024px) {
		flex-direction: column;
		height: 100%;
	}
`

const StoresDetailsContainer = styled.div`
	flex: 1;
	outline: none;
	width: 420px;
	padding-bottom: 10px;
	display: flex;
	flex-direction: column;

	@media (max-width: 1024px) {
		width: 100%;
		height: 45%;
		margin-bottom: 80px;
		order: 1;
	}

	@media (min-width: 481px) and (max-width: 1023px) {
		width: 400px;
	}

	@media (max-width: 425px) {
		height: 45%;
		margin-bottom: 80px;
	}
`

const SelectOption = styled(TypographyPro)`
	color: var(--strokeMenuTitle);
`

const SelectField = styled(Select)`
	width: 100%;
	height: 45px;
	padding: 0 20px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
	z-index: 1;

	.MuiSelect-select {
		color: var(--strokeMenuTitle);
		background-color: unset;
		${({ theme }) => theme.typography.h5};
	}

	.MuiSelect-icon {
		margin-inline-end: 23px;
	}

	@media (max-width: 600px) {
		height: 40px;
	}

	& .MuiSelect-icon.Mui-disabled {
		display: none;
	}
`

const BackArrowIconContainer = styled.div`
	cursor: pointer;
	padding: 5px 0;
`

const BackArrowIcon = styled(IconComponent)`
	width: 10px;
	height: 17px;
`

const SkeletonLoading = styled(Skeleton)`
	width: 100%;
	height: 45px;
	padding: 0 20px;
`

const ListItem = styled.div`
	margin-bottom: 10px;
	padding: 0 10px;

	@media (max-width: 768px) {
		margin: 0;
		padding: 0;
	}

	@media (min-width: 1023px) {
		width: 420px;
	}
`

const StyledCard = styled(Card)`
	padding: 15px 20px;
`

const StyledGrid = styled(Grid)`
	margin-inline-end: 15px;
	position: relative;
`

const SkeletonTextCard = styled(Skeleton)`
	margin: 10px 0 5px 0;
	width: 70%;
`

const SkeletonPhone = styled(Skeleton)`
	margin-right: 50px;
`

const PhoneAndDirectionsContainer = styled.div`
	display: flex;
	word-break: break-word;
`

const SkeletonMap = styled(Skeleton)`
	width: 600px;
	height: 450px;
	order: 1;

	@media (max-width: 1024px) {
		width: 100%;
		height: 222px;
		order: 0;
	}
`

const StoreAvailabilityInfo = styled.div`
	margin: 15px 0 -2px;
`

const StoreAvailabilityTitle = styled(Skeleton)`
	margin-bottom: 5px;
`

const StoreAvailabilityItem = styled(Skeleton)`
	margin-bottom: 2px;
`

const StyledButtonContainer = styled.div`
	margin-top: 20px;
`

const StoresDialogBox = inject(
	'Home',
	'User',
	'Application',
	'Infra',
	'AddressManager'
)(
	observer(({ Home, Application, Infra, AddressManager, User }) => {
		const { locale = {}, logo = {} } = Home
		const { addressPopup, closeAddressPopup, setAddressPopup, loading } = AddressManager

		const _isMobileApp = Application.isMobileApp
		const [selectedOrderType, setSelectedOrderType] = useState(
			User.getOrderType() === CONSTANTS.DELIVERY_METHODS.DELIVERY ? ORDER_TYPES.DELIVERY : ORDER_TYPES.PICKUP
		)
		const [shouldFilterByAvailability, setFilterByAvailability] = useState(true)
		const [selectedStoreOnMap, setSelectedStoreOnMap] = useState({})
		const [storesLists, setStoresList] = useState([])
		const { deliveryStores: deliveryEnabled, disablePickup } = Home.branchesList.msg ?? { deliveryStores: false, disablePickup: false }
		const { pathname } = useCurrentUrlInfo()

		const closeModal = () => {
			closeAddressPopup()
			if (addressPopup.afterCloseAddressPopup) {
				addressPopup.afterCloseAddressPopup()
			}
		}

		const initializeStoreList = () => {
			if (addressPopup?.mapButtonClicked) {
				setStoresList(Home.stores.filter((store) => store.availability[selectedOrderType]))
			} else if (shouldFilterByAvailability) {
				const availableStores = Home.stores.filter((store) => store.availability?.[selectedOrderType]?.availableForAddress)
				setStoresList(availableStores)
			} else {
				const availableStores = Home.stores.filter((store) => store.availability.hasOwnProperty(selectedOrderType))
				setStoresList(availableStores)
			}
		}

		useEffect(() => {
			setSelectedOrderType(User.getOrderType() === CONSTANTS.DELIVERY_METHODS.DELIVERY ? ORDER_TYPES.DELIVERY : ORDER_TYPES.PICKUP)
		}, [User.orderType])

		useEffect(() => {
			if (addressPopup?.open) {
				initializeStoreList()
			}
		}, [shouldFilterByAvailability, Home.stores, selectedOrderType, AddressManager.addressPopup])

		useEffect(
			() => () => {
				closeModal()
			},
			[pathname]
		)

		const resetStoreOnMap = () => {
			setSelectedStoreOnMap({})
		}

		const onSelectStoreOnMap = (store, rank) => {
			if (selectedStoreOnMap.id === store.id) {
				resetStoreOnMap()
				initializeStoreList()
			} else {
				setSelectedStoreOnMap(store)
				setStoresList([{ ...store, rank }])
			}
		}

		const onSelectClusterOnMap = (cluster, supercluster) => {
			const clusterChildren = supercluster.getChildren(cluster.id)
			const storeChildren = clusterChildren.map((child) => (!child.properties.cluster ? child.properties.store : undefined)).filter(Boolean)
			if (storeChildren.length) {
				setStoresList(storeChildren)
			}
		}

		const onSelectStore = async (id, orderType = selectedOrderType) => {

			// If the user address is not filled for delivery order, we stop the process here
			if(orderType === ORDER_TYPES.DELIVERY && Home.currentLocation?.address === "") {
				Infra.setNotification({
					open: true,
					title: getTranslatedTextByKey('webviewFlow.localization.delivery.addressRequired.title', 'Delivery address required'),
					message: getTranslatedTextByKey('webviewFlow.localization.delivery.addressRequired.message', 'Please add a delivery address to see the best delivery options nearby and start your order.'),
					okAction: async () => {
						Infra.closeNotification()
					},
					okText: getTranslatedTextByKey('webviewFlow.ok'),
				})
				return
			}

			User.setOrderType(orderType === ORDER_TYPES.PICKUP ? ORDER_TYPES.PICKUP : ORDER_TYPES.DELIVERY)
			const store = storesLists.find(({ id: storeId }) => storeId === id)

			if (Infra?.appParams?.features?.useNewAddressBox) {
				let geoCodeResult
				if (orderType === ORDER_TYPES.DELIVERY && AddressManager.addressPopup?.mapButtonClicked) {
					geoCodeResult = await geoCode(store.address.formatted)
				} else {
					geoCodeResult = await geoCode(orderType === ORDER_TYPES.PICKUP ? store.address.formatted : Home.currentLocation.address)
				}
				addressPopup?.onSubmit(store, geoCodeResult)
			} else {
				const storeName = getLocaleStr(store.title, locale.msg)

				if (orderType === ORDER_TYPES.PICKUP) {
					addressPopup.selectStore(id, orderType, {
						formatted: store.address.formatted,
						name: storeName || '',
						...store.address.latLng,
					})
				} else if (AddressManager.addressPopup?.mapButtonClicked) {
					const geoCodeResult = await geoCode(store.address.formatted)
					addressPopup.selectStore(id, orderType, {
						formatted: geoCodeResult?.formatted_address,
						lat: geoCodeResult.geometry.location.lat(),
						lng: geoCodeResult.geometry.location.lat(),
						addressObject: geoCodeResult,
					})
				} else {
					addressPopup.selectStore(id, orderType, {
						formatted: Home.currentLocation.address,
						lat: Home.currentLocation.lat,
						lng: Home.currentLocation.lng,
					})
				}
			}

			closeAddressPopup()
		}

		const handleChangeOrderType = (orderType) => {
			setSelectedOrderType(orderType.target.value)
			setSelectedStoreOnMap({})
		}

		return (
			<Modal open={loading || addressPopup?.open || false} disableAutoFocus disableEnforceFocus>
				<ModalContainer isMobileApp={_isMobileApp} dir={localeToDir[locale.msg]} data-testid="map-popup">
					<ModalHeader>
						<>
							<BackArrowIconContainer onClick={closeModal} role="button" data-testid="address-box-back-arrow">
								<BackArrowIcon asset="/icons/leftArrowLocalizationPopup.svg" />
							</BackArrowIconContainer>
							<BrandLogo $logoUrl={logo?.msg} />
						</>
					</ModalHeader>
					{loading && (
						<ModalBody>
							<SkeletonLoading variant="rect" height={45} animation="wave" data-testid="skeleton-map-loading" />
							<MapAndStoresContainer>
								<SkeletonMap variant="rect" animation="wave" />
								{[...Array(1)].map((_, idx) => (
									<ListItem key={idx}>
										<StyledCard>
											<Skeleton variant="rect" height={38} animation="wave" />
											<SkeletonTextCard variant="text" />
											<PhoneAndDirectionsContainer>
												<SkeletonPhone variant="text" width={120} height={31} />
												<Skeleton variant="text" width={90} height={31} />
											</PhoneAndDirectionsContainer>
											<StoreAvailabilityInfo>
												<StoreAvailabilityTitle variant="rect" width={220} height={22} />
												<StoreAvailabilityItem variant="rect" height={15} />
												<StoreAvailabilityItem variant="rect" height={15} />
												<StoreAvailabilityItem variant="rect" height={15} />
											</StoreAvailabilityInfo>
											<StyledButtonContainer>
												<Skeleton variant="rect" height={35} />
											</StyledButtonContainer>
										</StyledCard>
									</ListItem>
								))}
							</MapAndStoresContainer>
						</ModalBody>
					)}
					{!loading && (
						<ModalBody>
							<SelectField
								disabled={disablePickup || !deliveryEnabled}
								disableUnderline
								onChange={handleChangeOrderType}
								value={selectedOrderType}
								MenuProps={{
									anchorOrigin: {
										vertical: 'bottom',
										horizontal: 'left',
									},
									getContentAnchorEl: null,
								}}
								data-testid="map-popup-order-type-dropdown"
							>
								{!disablePickup && (
									<MenuItem value="pickup">
										<SelectOption variant="h5" data-testid="order-type-text">
											{getTranslatedTextByKey('webviewFlow.pickupOrder', 'pick up').toUpperCase()}
										</SelectOption>
									</MenuItem>
								)}
								{deliveryEnabled && (
									<MenuItem value="delivery">
										<SelectOption variant="h5" data-testid="order-type-text">
											{getTranslatedTextByKey('webviewFlow.deliveryOrder', 'delivery').toUpperCase()}
										</SelectOption>
									</MenuItem>
								)}
							</SelectField>

							<MapAndStoresContainer>
								<StoresDetailsContainer>
									<StoresList
										onSelectStore={onSelectStore}
										storesLists={storesLists}
										setFilterByAvailability={setFilterByAvailability}
										selectedOrderType={selectedOrderType}
										setSelectedOrderType={setSelectedOrderType}
										isStoreOnMapSelected={!isEmpty(selectedStoreOnMap)}
										allStoresFarAway={
											!addressPopup?.mapButtonClicked &&
											Home.stores.every((item) => !item.availability?.[selectedOrderType]?.availableForAddress)
										}
										selectedStoreOnMap={onSelectStoreOnMap}
									/>
								</StoresDetailsContainer>
								<StoresMap
									marker={logo.msg}
									selectStoreOnMap={onSelectStoreOnMap}
									selectClusterOnMap={onSelectClusterOnMap}
									selectedStoreOnMap={selectedStoreOnMap}
									selectedOrderType={selectedOrderType}
									resetStoreOnMap={resetStoreOnMap}
									shouldFilterByAvailability={shouldFilterByAvailability}
									setFilterByAvailability={setFilterByAvailability}
								/>
							</MapAndStoresContainer>
						</ModalBody>
					)}
				</ModalContainer>
			</Modal>
		)
	})
)

export default StoresDialogBox as () => React.ReactNode
