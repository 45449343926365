// @ts-nocheck
import React, { useContext } from 'react'
import { formatPrice } from 'utils/utils'
import { StoreContext } from 'contexts/StoreContext'
import styled from 'styled-components/macro'
import Radio from '@material-ui/core/Radio'
import { isNil } from 'lodash-es'
import TypographyPro from 'themes/TypographyPro'

const StyledTypographyTitle = styled(TypographyPro)`
	color: var(--footerAndDarkBackgrounds);
	.invalid & {
		color: var(--err);
	}
`

const StyledRoot = styled.div`
	width: 100%;
	margin: 0;
	padding: 0 0 0 4px;
	border-bottom: 1px solid #d4d0d0;

	&:last-child {
		padding-bottom: 0;
	}

	cursor: pointer;
	color: black;
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media (min-width: 1280px) {
		padding: 2px 0 2px 4px;
	}
`

const StyledRadio = styled(Radio)`
	transform: scale(1.2);
	border-radius: 0;
	color: black;

	&.Mui-checked {
		color: black;
	}

	@media (min-width: 1280px) {
		transform: scale(1.4);
	}
`

const StyledPrice = styled.span`
	color: var(--disable);
`
const SingleSelect = ({ id, label, price, currency, selected, isPlusIconHidden, handleSelectionChange }) => {
	const { store } = useContext(StoreContext)
	const rest = store.data

	const handleChange = (e) => {
		handleSelectionChange(id)
	}

	return (
		<StyledRoot onClick={handleChange} id={id} data-testid="menu-item-single-select-row">
			<StyledTypographyTitle variant="BodyRegular" component="span">
				<span>{label}</span>

				{!isNil(price) && price > 0 && (
					<StyledPrice>
						&nbsp;
						{!isPlusIconHidden && <span>+</span>}
						{formatPrice(price, currency, rest.countryCode)}
					</StyledPrice>
				)}
			</StyledTypographyTitle>

			<StyledRadio checked={selected} inputProps={{ 'data-testid': 'single-select-input' }} />
		</StyledRoot>
	)
}
export default SingleSelect
