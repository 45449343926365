// @ts-nocheck
/**
 * MenuCardMedia displays an image of an item, or a fallback if the picture is not valid
 */

import React, { useCallback, useState } from 'react'
import styled, { css } from 'styled-components'
import CardMedia from '@material-ui/core/CardMedia'

const StyledMenuCard = styled(CardMedia)`
	${({ stylecss }) =>
		css`
			${stylecss}
		`}
`

export default (props) => {
	const [imgErrors, setImgErrors] = useState(0)

	const getImgFallback = useCallback(
		(e) => {
			const defaultImage = props.imageFallback
			console.info(`Default image src is unavailable (err ${imgErrors}): '${e.target.src}'`, `setting default logo: ${defaultImage}`)
			if (imgErrors >= 2) {
				e.target.src = ''
			} else {
				const doesSrcUrlContainTictuk = e.target.src.toLowerCase().indexOf('tictuk.com') > -1
				const isDefaultImageDefined = defaultImage !== null && typeof defaultImage !== 'undefined'
				if (doesSrcUrlContainTictuk) {
					e.target.src = isDefaultImageDefined ? defaultImage : ''
				} else {
					// NB: I let this condition in case it was here for a reason
					console.info('Not setting a default logo, because logo does not come from tictuk server')
					e.target.src = isDefaultImageDefined ? defaultImage : ''
				}
				setImgErrors((ps) => ps + 1)
			}
		},
		[imgErrors]
	)

	const image = imgErrors >= 2 ? '' : props.image || props.imageFallback

	if (props.hideOnError && imgErrors >= 2) {
		return null
	}

	return (
		<StyledMenuCard
			image={image}
			title={props.title}
			onClick={props.clickHandler}
			stylecss={props.styleCss}
			id={props.id}
			onError={getImgFallback}
			component="img"
			alt={props.title}
			data-testid={props.testId}
		/>
	)
}
