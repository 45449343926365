// @ts-nocheck
import { STORE_STATUS, ORDER_TYPES } from './constants'
import { getTranslatedTextByKey } from './language'

// Utils regarding store availablity information.
// For information about the "Availability Information Object", passed in this file as availabilityObject, see: https://tictuk.atlassian.net/l/cp/exg1cDH2

export const isStoreActive = (availabilityObject) => availabilityObject?.open || availabilityObject?.preOrder

export function getStoreStatus(availabilityObject) {
	if (!availabilityObject?.orderOnline) {
		return availabilityObject.preOrder ? STORE_STATUS.PRE_ORDER : STORE_STATUS.UNAVAILABLE
	}
	return STORE_STATUS.AVAILABLE
}

// Return the relevant CTA text for "start order" buttons, based on store's availability status
export function getOrderActionText(storeStatus) {
	switch (storeStatus) {
		case STORE_STATUS.AVAILABLE:
			return getTranslatedTextByKey('eCommerce.storeLocator.startMyOrder', 'Start My Order')
		case STORE_STATUS.PRE_ORDER:
			return getTranslatedTextByKey('eCommerce.storeLocator.preOrder', 'Pre-Order')
		case STORE_STATUS.UNAVAILABLE:
			return getTranslatedTextByKey('eCommerce.storeLocator.browseMenu', 'Browse Menu')
		default:
			return getTranslatedTextByKey('eCommerce.storeLocator.browseMenu', 'Browse Menu')
	}
}

export const SUPPORTED_ORDER_TYPES = [ORDER_TYPES.DELIVERY, ORDER_TYPES.PICKUP, ORDER_TYPES.STORE]
