// @ts-nocheck
/* global MessengerExtensions */
import queryString from 'query-string'
import { isWebviewFlow, webview, isIphone, getTargetChatUrl } from './utils'
import storage from './storage'
import Infra from '../mobx/Infra'
import { CONSTANTS } from './constants'

let parsed = {}

// for Node js env
if (typeof window !== 'undefined') {
	parsed = queryString.parse(location.search)
}

// TODO - don't handle sharing an order yet
// const friendName = false

/**
 * Called when the menu is loaded from a chat app and the user clicked on 'checkout'
 *
 * @param returnNothing
 * @param cartItems
 * @param allItems
 * @param showLoader - true/false
 * @param friendName - used when sharing order with a friend (optional, can be null)
 */
export async function finish(returnNothing, cartItems, allItems, showLoader = false, friendName = false) {
	// let msg

	// returnNothing = true when the user wanted to edit a course/add a course from the history/featured and decided to go back
	// then we close the webview using FB code
	if (returnNothing) {
		closeWindow()
	} else {
		// if its a shared order and we don't have friendName, we ask the friend to add his name
		// in order to notify the admin that this friend added a course
		if (friendName) {
			// cartItems = addNameToCourses(cartItems, locale, friendName)
			// console.log(JSON.stringify(cartItems))
			// return false
			/* apiUtil.openInfoPopup(
				texts.addYourName,
				false,
				'text',
				texts.addYourNameSubtitle(firstName),
				finish,
				[returnNothing, noVariations],
				function () {
					const inputValue = $('.popupInput').val()
					if (!inputValue || inputValue === '' || inputValue.length > 20) {
						$('#validation-msg').html(texts.nicknameNotValid)
						return false
					}
					friendName = inputValue
					apiUtil.addNameToCourses(friendName)
					return true
				},
				false,
				false,
				false,
				true
			) */
		} // we return false in case nothing happened yet... this will leave us in the same screen // wix
		// TODO

		/* if (!returnNothing && !apiUtil.arrayWithItems(orderItems)) {
			return false
		} */

		if (parsed.cust === 'local') {
			if (!isWebviewFlow()) {
				closeWindow()
			} else {
				console.error('TO DO...')
				// handle display of the right div in webviewFlow
				//                    $("body").removeClass("active");

				// TODO
				// apiUtil.showWebviewDivs()

				// setting the next btn to move on after the user fill all details.
				// $('.submit').prop('onclick', null).html(apiUtil.getBtnsHTML('userDetails'))

				// apiUtil.getGrandTotal()

				// resizeFrame()
			}
		} else {
			const response = await webview(cartItems, allItems, showLoader, friendName)

			// storage.clearStorage()

			// $.post(texts.postURL, config, function (data) {
			if (!isWebviewFlow()) {
				Infra.setLoading(true) // show loading icon until WA page is loaded to prevent the user from clicking on buttons while transitioning out of the app
				storage.clearStorage()
				closeWindow()
			} else {
				// handle display of the right div in webviewFlow
				//                        $("body").removeClass("active");

				// TODO
				console.error('loadWebViewIntervalFunc')

				/* const loadWebViewIntervalFunc = setInterval(function () {
						if (takeUserDetailsReady) {
							clearInterval(loadWebViewIntervalFunc)
							$('#modal-content, #main').addClass('active')
							apiUtil.showWebviewDivs()

							// setting the next btn to move on after the user fill all details.
							$('.submit')
								.prop('onclick', null)
								.html(apiUtil.getBtnsHTML('userDetails'))
								.css('background-color', '#d0d4db')
							apiUtil.getGrandTotal()
							if (iframe.contentWindow.params.lastAddress) {
								// make sure that addresses from the past/cookie will be validated and we make sure that the rest is delivering to that address and or the minimum is met etc...
								iframe.contentWindow.figureOutAddressAndCheckField()
							}

							resizeFrame()
							document.getElementById('loader').style.display = 'none' // closing the loader
							$('#alert-background').removeClass('active')
						} else {
							loadUserDetailsTime += 500
							if (loadUserDetailsTime > 30000) {
								clearInterval(loadWebViewIntervalFunc)
								alert('This is taking too long, please refresh the page and try again')
							}
						}
					}, 500) */
			}
			// })
		}
	}
}

// the actual closing action
export function closeWindowAction() {
	// const parsed = queryString.parse(location.search)
	// parsed.tictuk_listener = parsed.tictuk_listener || parsed.wru

	// the actual code that closes the window
	// $('#variations-content').html(`<h1 class='close-window'>${texts.closeWindowManually}</h1>`)
	// close the window automatically
	const path = getTargetChatUrl(parsed.p, parsed.app)
	if (!path) {
		window.close()
	}
	if (parsed.app === CONSTANTS.APP.TYPES.MESSENGER.toString()) {
		try {
			// this FB obj. is loaded in app.js
			MessengerExtensions.requestCloseBrowser(
				() => {},
				(err) => {
					window.location.href = `https://m.me/${parsed.p}`
				}
			)
		} catch (e) {
			window.location.href = `https://m.me/${parsed.p}`
		}
	} else {
		window.location.href = path
	}

	if (parsed.app !== CONSTANTS.APP.TYPES.MESSENGER.toString() && isIphone()) {
		// window.close() does nothing on Mobile Safari so for any app (not Messenger since we can close the window on all devices)
		// we change the href to WA so the user is not confused.
		const t = setTimeout(() => {
			window.location.href = 'https://www.whatsapp.com/'
			// window.history.back() - does nothing too
		}, 300)
	}
}

// some logic before closing the window
export function closeWindow() {
	// const parsed = queryString.parse(location.search)

	// logic before closing the window after everything ended

	// we make sure to allow the user know that if the window didn't close on its own, he shall close it manually
	// $('.submit').prop('onclick', null).html('&nbsp;')

	if (parsed.shared) {
		// a Friend of the main user is doing a flow.
		// We allow him know that his pick was send successfully to his friend.
		// TODO
		// this.openInfoPopup(texts.friendReceivedYourOrder(firstName), 'success-icon-background')
		window.location = 'http://www.tictuk.com/tictuk_share.html'
	} else {
		closeWindowAction()
	}
}
