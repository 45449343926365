import type React from 'react'
import { useEffect, useContext } from 'react'
import { useStores } from 'hooks/useStores'
import { observer, inject } from 'mobx-react'
import type _Cart from 'mobx/Cart'
import storage from 'utils/storage'
import { StoreContext } from 'contexts/StoreContext'
import { useRouter } from 'next/router'
import routeToPage from 'utils/routeToPage'
import { Page } from 'utils/constants'
import { CouponStatus } from 'types/Coupons'

const CouponFlowService: React.FC<{ Cart?: typeof _Cart }> = ({ Cart }) => {
	const { pathname } = useRouter()
	const { couponFlowStore, couponsStore } = useStores()
	const { store } = useContext(StoreContext)
	const { data: menu } = store

	const { couponToApply, couponToUnapply } = couponFlowStore

	const { ready: couponToApplyReady } = couponToApply || {}

	useEffect(() => {
		const currentPage = routeToPage(pathname, true)
		const pagesToStartApply = [Page.MENU, Page.CHECKOUT]
		if (!currentPage || !pagesToStartApply.includes(currentPage)) {
			return
		}
		if (couponToApply && couponToApplyReady && menu) {
			console.log('CouponFlowService apply')
			console.log('CouponFlowService apply couponToApply', couponToApply)
			// start apply flow after localization logic
			Cart?.applyDiscount(couponToApply, menu, storage)
			couponFlowStore.clearCouponToApply()
		}
	}, [couponFlowStore, couponToApply, couponsStore, couponToApplyReady, pathname, menu])

	useEffect(() => {
		const unapply = async () => {
			if (couponToUnapply && menu) {
				console.log('CouponFlowService unapply')
				console.log('CouponFlowService unapply couponToUnapply', couponToUnapply)

				try {
					await Cart?.unapplyDiscount(couponToUnapply, menu, storage)
					couponToUnapply.status = CouponStatus.AVAILABLE
					couponToUnapply.flags.applied = { value: false }
				} catch (e) {
					console.error(`CouponFlowService unapply - error : ${e}`)
				} finally {
					// Debounce to avoid multiple clicking on coupon trash icon (from wallet and cart)
					setTimeout(() => {
						couponFlowStore.clearCouponToUnapply()
					}, 200)
				}
			}
		}
		unapply()
	}, [couponFlowStore, couponToUnapply, couponsStore, menu])

	return null // This component does not render anything
}

export default inject('Cart')(observer(CouponFlowService))
