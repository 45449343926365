import React from 'react'
import styled from 'styled-components/macro'
import { inject, observer } from 'mobx-react'
import { redirectToHomepage, isMobile } from 'utils/utils'
import type _Infra from 'mobx/Infra'
import type _Application from 'mobx/Application'
import { useRouter } from 'next/router'

const BaseStyledLogo = styled.img<{ cursorPointer?: boolean }>`
	max-width: 235px;
	height: 48px;
	cursor: ${({ cursorPointer }) => (cursorPointer ? 'pointer' : 'default')};
	flex-grow: 1;
	object-fit: contain;

	@media (max-width: 576px) {
		max-width: 105px;
		height: 43px;
	}
`

export enum LOGO_TYPE {
	HEADER = 'HEADER',
	FOOTER = 'FOOTER',
	OTHER = 'OTHER',
}

/**
 * System logo component for each brand
 * (1) E-commerce - When it is WEB or Mobile app
 * (2) Not-e-commerce - When it is Chat
 * (3) Web view - Is WEB
 * (4) Mobile view - Is Mobile
 * (5) Header - Is header
 * (6) Footer - Is Footer
 * (7) Static pages
 * (8) Other options - Is it is different source for getting the logo
 */

export const getLogoPath = (
	Infra: typeof _Infra,
	isEcommerce = false,
	isMobileView = false,
	logoType: LOGO_TYPE | null = null,
	isOther = false,
	path = ''
) => {
	const nonECommercePath = Infra?.logo?.msg
	const eCommerceStaticPath = Infra?.appParams?.logo

	const {
		logoMobileHeader: headerMobilePath,
		logoDesktopHeader: headerDesktopPath,
		logoMobileFooter: footerMobilePath,
		logoDesktopFooter: footerDesktopPath,
	} = (Infra?.appParams?.assets as
		| { logoMobileHeader: string; logoDesktopHeader: string; logoMobileFooter: string; logoDesktopFooter: string }
		| undefined) ?? {}

	switch (true) {
		case isEcommerce && logoType === LOGO_TYPE.HEADER && isMobileView:
			// Header mobile logo
			if (headerMobilePath) {
				return headerMobilePath
			}
			break
		case isEcommerce && logoType === LOGO_TYPE.HEADER:
			// Header desktop logo
			if (headerDesktopPath) {
				return headerDesktopPath
			}
			break
		case isEcommerce && logoType === LOGO_TYPE.FOOTER && isMobileView:
			// Footer mobile logo
			if (footerMobilePath) {
				return footerMobilePath
			}
			break
		case isEcommerce && logoType === LOGO_TYPE.FOOTER:
			// Footer desktop logo
			if (footerDesktopPath) {
				return footerDesktopPath
			}
			break
		case isEcommerce && isOther:
			// Get from other place as example from thumbnailLogo
			if (path) {
				return path
			}
			break
		case !isEcommerce:
			// Not e-commerce
			if (nonECommercePath) {
				return nonECommercePath
			}
			break
		default:
			// isEcommerce - Get URL from on of the params.json attributes
			return eCommerceStaticPath
	}
}

interface Props {
	onClick?: () => void
	logoType?: LOGO_TYPE
	otherPath?: string
	className?: string
}

export const Logo: React.FC<Props> = inject(
	'Infra',
	'Application'
)(
	observer((props: Props & { Infra?: typeof _Infra; Application?: typeof _Application }) => {
		const router = useRouter()
		const {
			Infra,
			Application,
			onClick = () => redirectToHomepage(router),
			logoType = LOGO_TYPE.OTHER,
			otherPath = '',
			className = undefined,
		} = props
		const isEcommerce = Infra?.appParams?.eCommerce
		const isMobileView = isMobile()
		const isOther = otherPath !== ''
		const logoUrl = getLogoPath(Infra!, isEcommerce, isMobileView, logoType, isOther, otherPath)
		// Footer logo and chat logo are not clickable and do not redirect anywhere
		const isCursorPointer = logoType !== LOGO_TYPE.FOOTER && isEcommerce
		const nonECommercePath = Infra?.logo?.msg

		return (
			<BaseStyledLogo
				data-testid={`logo-${logoType.toLowerCase()}`}
				id={`${Application!.page}-page-${logoType === LOGO_TYPE.HEADER ? '' : `${logoType.toLowerCase()}-`}logo-icon`}
				onClick={isEcommerce && logoType !== LOGO_TYPE.FOOTER ? onClick : () => {}}
				cursorPointer={isCursorPointer}
				src={isEcommerce ? logoUrl : nonECommercePath}
				alt="Logo"
				className={className}
			/>
		)
	})
)

export default Logo
