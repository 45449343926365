// @ts-nocheck
import React, { useState, useContext, useRef } from 'react'
import ItemQuantity from './ItemQuantity'
import { getLocaleStr, codeToLocale, formatPrice, redirectToHomepage } from 'utils/utils'
import { StoreContext } from 'contexts/StoreContext'
import styled from 'styled-components/macro'
import { Grid } from '@material-ui/core'
import CloseMenuItem from './CloseMenuItem'
import TypographyPro from 'themes/TypographyPro'
import { inject, observer } from 'mobx-react'
import MenuCardMedia from 'components/MenuCardMedia'
import { Logo, LOGO_TYPE } from 'components/common/Logo'
import { isEmpty } from 'lodash-es'
import Nutritionix from 'components/menu/Nutritionix'
import NutritionalInformation from 'components/menu/NutritionalInformation'

const StyledTypographyPrice = styled(TypographyPro)`
	margin-bottom: 5px;
`
const StyledTypographyDescription = styled(TypographyPro)`
	line-height: 15px;
	min-height: 30px;
	overflow-y: auto;
	color: black;

	@media (min-width: 600px) {
		line-height: 21px;
		margin-top: 20px;
	}
`

const StrikeoutPrice = styled.span`
	margin-inline-start: 5px;
	color: var(--inactive);
	text-decoration: line-through;
`

const MenuCardStyle = (itemHasNoVariations) => `
  display: block;
  height: 211px;
  object-fit: contain;
  @media (min-width: 600px) {
    height: ${itemHasNoVariations ? '316px' : '211px'};
    max-height: ${itemHasNoVariations && '43vh'};
  }
`

const ItemPageHeader = styled.div`
	height: 50px;
	position: sticky;
	top: 0;
	background-color: white;
	z-index: 2;
	padding: 8px 20px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
`

const MenuItemPageHeader = inject(
	'User',
	'Infra'
)(
	observer((props) => {
		const { store } = useContext(StoreContext)
		const rest = store.data
		const { item } = props
		const { Infra } = props
		const { ItemAdditions } = props

		const [quantity, setQuantity] = useState(props.quantity)
		const isImgLoaded = useRef({
			status: true,
			src: rest.picture,
		})

		const manageQuantities = (increment) => {
			const newQuantity = quantity + increment
			setQuantity(newQuantity)
			ItemAdditions.setQuantity(newQuantity)
		}

		const imgSrc = item.media && item.media.logo && isImgLoaded.current.status ? item.media.logo : isImgLoaded.current.src

		const onLogoClick = () => {
			closeItem()
			redirectToHomepage()
		}

		const closeItem = () => {
			props.closeModal()
		}

		const _showDesktopView = window.innerWidth >= 1280

		const getPriceFromVariationItem = () => {
			const variationsPrices = item?.variations?.[0]?.prices
			return isEmpty(variationsPrices) ? 0 : Object.values(variationsPrices).find((price) => price)
		}

		// Do not allow the customer to select multiples of hidden item (from a coupon, deal etc.) unless it's an Upsell
		const _hideQuantityOptions = item.fromHiddenSection && !item.isUpsell
		const _titleSectionGridSize = _hideQuantityOptions ? 12 : 8
		const itemTitle = getLocaleStr(item.title, codeToLocale[props.User.preferredLanguage] || rest.locale)
		return (
			<>
				{(!_showDesktopView || props.itemHasNoVariations) && (
					<ItemPageHeader>
						<Logo logoType={LOGO_TYPE.HEADER} onClick={onLogoClick} />
						<CloseMenuItem closeItem={closeItem} />
					</ItemPageHeader>
				)}

				<MenuCardMedia
					image={imgSrc}
					imageFallback={Infra.appParams.logo || rest.picture}
					title={itemTitle}
					styleCss={MenuCardStyle(!(props.itemHasNoVariations === false))}
					testId="menu-item-image"
				/>

				<Grid container spacing={0} style={{ padding: '15px 22px', boxSizing: 'border-box' }}>
					<Grid
						item
						xs={_titleSectionGridSize}
						sm={_titleSectionGridSize}
						md={_titleSectionGridSize}
						lg={props.itemHasNoVariations ? _titleSectionGridSize : 12}
						xl={props.itemHasNoVariations ? _titleSectionGridSize : 12}
					>
						<TypographyPro component="p" variant="h3" data-testid="menu-item-page-item-title">
							{itemTitle}
						</TypographyPro>
						<StyledTypographyPrice component="div" variant="Subhead" data-testid="menu-item-page-price">
							<span data-testid="menu-item-dialog-price">
								{props.formattedDisplayPrice ||
									(item.price ? props.formattedPrice : formatPrice(getPriceFromVariationItem(), rest.currency, rest.countryCode))}
							</span>
							{!!props.formattedStrikeThuPrice && (
								<StrikeoutPrice data-testid="menu-item-dialog-strikeout-price">{props.formattedStrikeThuPrice}</StrikeoutPrice>
							)}
						</StyledTypographyPrice>
					</Grid>
					{!_hideQuantityOptions && (
						<Grid item xs={4} sm={4} md={4} lg={props.itemHasNoVariations ? 4 : 12} xl={props.itemHasNoVariations ? 4 : 12}>
							<ItemQuantity
								showLabel={false}
								max={99}
								min={1}
								quantity={quantity}
								manageQuantities={manageQuantities}
								itemHasNoVariations={props.itemHasNoVariations}
								isIncDisabled={quantity === 99}
								isDecDisabled={quantity === 1}
							/>
						</Grid>
					)}
					{(!!item.description[props.locale] || Object(item.description).hasOwnProperty('en_US')) && (
						<StyledTypographyDescription component="p" variant="BodyRegular" data-testid="menu-item-dialog-description">
							{getLocaleStr(item.description, codeToLocale[props.User.preferredLanguage] || rest.locale)}
						</StyledTypographyDescription>
					)}
					{item.description?.nutrition?.type === 'nutritionix' && Infra.appParams.nutritionix && (
						<Nutritionix value={item.description.nutrition.value} />
					)}

					{item.description?.nutrientId && <NutritionalInformation id={item.description?.nutrientId} />}
				</Grid>
			</>
		)
	})
)

export default MenuItemPageHeader
