import { useEffect } from 'react'
import ReactNativeComms from 'utils/reactNativeComms'

/**
 * TopLevelServerError is meant to be displayed for critical errors that should
 * stop the execution of the request right away.
 * Examples: no params.json, no theme, no host, no environment variables...
 */
const UnrecoverableServerError = (props: { message: string }) => {
	if (props.message) {
		console.error(`[TOP_LEVEL_SERVER_ERROR]: ${props.message}`)
	} else {
		console.error('[TOP_LEVEL_SERVER_ERROR]: Unidentified high level error')
	}

	useEffect(() => {
		ReactNativeComms.sendMessage.unrecoverableServerError(`[TOP_LEVEL_SERVER_ERROR]: ${props.message || 'Unidentified high level error'}`)
	}, [])

	return (
		<div
			style={{
				width: '100vw',
				height: '100vh',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			This page cannot be loaded
		</div>
	)
}

export default UnrecoverableServerError
