import React from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { mediaQueriesBreakpoints } from '../styles/mixins'

const StyledImage = styled.img`
	position: fixed;
	right: 0;
	bottom: 30px;
	z-index: 2001;
	pointer-events: none;
	width: 95px;
	height: auto;
	@media ${mediaQueriesBreakpoints.sm} {
		width: 120px;
	}
`

const TestModeNotification = inject('Infra')(
	observer(({ Infra }: any) =>
		Object.values(Infra.testMode).some((val: any) => val === true) ? (
			<StyledImage src="https://cdn.tictuk.com/defaultThemes/shared/images/testMode.png" alt="testMode notification" />
		) : null
	)
)

export default TestModeNotification
