// @ts-nocheck
import React from 'react'
import styled from 'styled-components'
import TypographyPro from 'themes/TypographyPro'
import ButtonBase from 'components/common/ButtonBase'
import { getTargetChatUrl } from 'utils/utils'

export const GENERIC_ERROR_TITLE = 'Whooops!'
export const GENERIC_ERROR_SUBTITLE = 'The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.'
export const GENERIC_ERROR_DESCRIPTION = "Don't worry, we've got all your favorites here"
export const SESSION_EXPIRED_TITLE = 'This session has expired'
export const SESSION_EXPIRED_SUB_TITLE = 'Seems like it is been a while since we last saw you'
export const SESSION_EXPIRED_DESCRIPTION = 'Click Restart to continue with a new order'
export const SESSION_EXPIRED_RESTART_BUTTON = 'Restart'
export const SESSION_EXPIRED_MESSAGE = "Let's start a new order by hitting send"
export const INVALID_ROUTE_TITLE = '404!'
export const INVALID_ROUTE_SUBTITLE = 'Sorry, the page you visited does not exist.'
export const CHAIN_SHUT_DOWN_TITLE = 'Our chain is closed for now'
export const CHAIN_SHUT_DOWN_SUBTITLE = 'We appreciate your patience and hope to serve you again soon!'

export const ErrorPageContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--clear);
	flex-direction: column;
`

export const ErrorPageOneButtonHeader = styled.div`
	width: 100%;
	height: 80px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
	box-shadow: var(--defaultShadow1);
	background-color: var(--clear);
	padding: 10px 120px 5px 120px;

	@media (max-width: 959px) {
		padding: 10px 40px 5px 40px;
	}

	@media (max-width: 576px) {
		padding: 10px 10px 5px 10px;
	}
`

export const ErrorPageHeaderButton = styled(ButtonBase)`
	min-width: 100px;
	max-width: 305px;
	border: none;
	border-radius: 5px;
`

export const ErrorPageContent = styled.div`
	margin-top: 15px;
	width: 700px;
	text-align: center;
	min-height: calc(100vh - 80px);

	@media (max-width: 576px) {
		width: 526px;
		margin: 15px 20px 0px;
	}
`

export const ErrorPageContentImage = styled.img`
	max-width: 250px;
`

export const ErrorPageContentTitle = styled(TypographyPro)`
	margin-top: 20px;
	color: var(--brandTitle);

	@media (max-width: 959px) {
		margin-top: 15px;
	}
`

export const SessionExpiredPageTitle = styled.h1`
	margin-top: 15px;
	color: var(--brandTitle);
	margin-bottom: 0px;

	@media (max-width: 959px) {
		margin-top: 0px;
	}
`

export const ErrorPageContentSubTitle = styled(TypographyPro)`
	color: var(--fonts);
	margin-top: 30px;
	padding: 20px;
`

export const SessionExpiredSubTitle = styled(TypographyPro)`
	color: var(--fonts);
	margin: 10px 20px 0px;
`

export const ErrorPageContentDescription = styled(TypographyPro)`
	margin-top: 30px;
	color: var(--fonts);
`

export const SessionExpiredPageDescription = styled(TypographyPro)`
	margin-top: 5px;
	color: var(--fonts);
`

export const ErrorPageContentButton = styled(ButtonBase)`
	margin: 60px 0px;
	width: 220px;

	@media (max-width: 576px) {
		margin: 40px 0px;
	}
`

export const SessionExpiredPageButton = styled(ButtonBase)`
	margin: 50px 0px;
	width: 220px;

	@media (max-width: 576px) {
		margin: 50px 0px;
	}
`

export const ErrorPageNoButtonHeader = styled.div`
	width: 100%;
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	box-shadow: var(--defaultShadow1);
	background-color: var(--clear);
	padding: 10px 120px 5px 120px;
	gap: 364px;
`

export const redirectToChat = (targetChatUrl, appId, setIsValidReturnButton) => {
	const redirectURL = getTargetChatUrl(targetChatUrl, appId)
	if (redirectURL) {
		window.location.href = getTargetChatUrl(targetChatUrl, appId)
	} else {
		return setIsValidReturnButton(false)
	}
}
