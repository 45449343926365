export interface Graph {
	title?: string
	description?: string
	image?: string
}

interface Props {
	graph: Graph
	currentPageURL?: string
	headerKeys: Record<string, string>
}

const OpenGraph = ({ graph, currentPageURL, headerKeys }: Props) => (
	<>
		{graph.title && (
			<>
				<meta property="og:title" content={graph.title} key={headerKeys.OGTitle} />
				<meta name="twitter:title" content={graph.title} key={headerKeys.twitterTitle} />
			</>
		)}
		{graph.image && (
			<>
				<meta property="og:image" content={graph.image} key={headerKeys.OGImage} />
				<meta name="twitter:image" content={graph.image} key={headerKeys.twitterImage} />
			</>
		)}
		{currentPageURL && (
			<>
				<meta property="og:url" content={currentPageURL} key={headerKeys.OG_URL} />
				<meta name="twitter:url" content={currentPageURL} key={headerKeys.twitterURL} />
			</>
		)}
		{graph.description && (
			<>
				<meta property="og:description" content={graph.description} key={headerKeys.OGDescription} />
				<meta name="twitter:description" content={graph.description} key={headerKeys.twitterDescription} />
			</>
		)}
	</>
)

export default OpenGraph
