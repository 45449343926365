import { useRef, useEffect } from 'react'

function usePrevious(value: string) {
	const ref = useRef<string>()
	useEffect(() => {
		ref.current = value
	}, [value])
	return ref.current
}

export function useCompare(val: string) {
	const prevVal = usePrevious(val)
	return prevVal !== val
}
