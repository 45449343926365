import React from 'react'
import { isMobile } from 'utils/utils'
import styled from 'styled-components/macro'
import IconButton from '@material-ui/core/IconButton'
import IconComponent from 'themes/IconComponent'

const _isMobile = isMobile()

const CloseIcon = (props: any) => <IconComponent asset="/icons/close.svg" {...props} />

const StyledCloseIcon = styled(CloseIcon)`
	cursor: pointer;
	transform: scale(1.5);
	border-radius: 10px;
	font-size: ${_isMobile ? '32px' : '40px'};
`

const StyledCloseIconButton = styled(IconButton)`
	&:hover {
		background-color: transparent;
	}
`

const CloseMenuItem = (props: any) => (
	<StyledCloseIconButton edge="start" color="inherit" size="small" onClick={props.closeItem} data-testid="menu-item-close">
		<StyledCloseIcon />
	</StyledCloseIconButton>
)

export default CloseMenuItem
