import { injectExternalScript } from '../../utils'

/**
 *  SmartLook SDK will be added to the <head> and it creates events itself. It does not receive any enhance ecommerce or
 *  custom events.
 *
 * @param token
 * @param region eg 'eu'
 */
export const init = (token: string, region: string) => {
	injectExternalScript(
		'text',
		`window.smartlook||(function(d) {\n` +
			'\n' +
			"    var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];\n" +
			'\n' +
			"    var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';\n" +
			'\n' +
			"    c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);\n" +
			'\n' +
			'    })(document);\n' +
			'\n' +
			`    smartlook(\'init\', \'${token}\', { region: \'${region}\' });`
	)
}
