import React from 'react'
import type { TypographyProps, StyleRulesCallback } from '@material-ui/core'
import type { Theme as MuiTheme, TypographyVariant } from '@material-ui/core/styles'
import { Typography as MuiTypography, withStyles } from '@material-ui/core'
import clsx from 'clsx'
import type { CSSFontStyling, Theme, TypographyVariants } from 'utils/theme/GlobalStyle'
import type { ClassValue } from 'clsx'

// const theme = useTheme()

const ignoreDefaultTypography = [
	'h1',
	'h2',
	'h3',
	'h4',
	'h5',
	'h6',
	'body1',
	'body2',
	'caption',
	'overline',
	'subtitle1',
	'subtitle2',
	'button',
	// 'fontSize',
]
const ignoreDefaultTypographySet = new Set(ignoreDefaultTypography)

/**
 * MUI v4.x has only 13 default Typography classes (see above)
 *
 * To add more we need to manually add them here. The brand's non-default typo classes were added in the App.jsx via
 * buildTheme() but they cannot be used directly via <Typography> so we ibject them in this extended class
 * below.
 *
 * Disaadvantge - this extension runs every time the <TypographyPro> tag is used
 * Advantage - we can extend the MUI theme to include any fonts we need AND still use all of the component overrides
 * in the mui-component-theme.js
 *
 *
 * @param theme
 */
const style = (theme: Theme) => {
	const brandTypographyKeysToAdd: TypographyVariants[] = Object.keys(theme.typography).filter(
		(_key) => !ignoreDefaultTypographySet.has(_key)
	) as TypographyVariants[]

	const brandTypographyMap: Partial<Record<TypographyVariants, CSSFontStyling>> = {}
	brandTypographyKeysToAdd.map((_brandTypoKey) => {
		if (typeof theme.typography[_brandTypoKey] === 'object') {
			// add the brand's non-default typo. classes
			brandTypographyMap[_brandTypoKey] = theme.typography[_brandTypoKey]
		}
	})

	return brandTypographyMap
}

type ClassesObject = Record<TypographyVariants, ClassValue>

interface Props extends Omit<TypographyProps, 'classes' | 'variant'> {
	variant: TypographyVariants
	classes: ClassesObject
	className?: string
	component?: React.ElementType
}

/**
 * Overrides default material UI typography with some additional variants from the style variable above
 *
 * We only pass the 'variant' prop to the parent MUI component if we are using a default MUI Typography font group. If
 * we use a non-default font group then we apply the styling below and don't pass the 'variant' prop to the parent component.
 *
 * We ALWAYS pass the 'component' prop
 */

// "data-typography" prop is mostly for the qa to verify the variant type

const TypographyPro = ({ variant, component, classes, className, ...props }: Props, ref: React.Ref<HTMLInputElement>) => {
	component ||= !['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(variant as string) ? 'p' : component
	const isCustom = Object.keys(classes).includes(variant)

	return (
		<MuiTypography
			innerRef={ref}
			className={isCustom ? clsx(classes![variant] as ClassValue, className) : className}
			variant={isCustom ? undefined : (variant as TypographyVariant)}
			{...props}
			component={component!}
			data-typography={variant}
		/>
	)
}

export default withStyles(style as unknown as StyleRulesCallback<MuiTheme, Props, TypographyVariants>)(React.forwardRef(TypographyPro)) as React.FC<
	Omit<Props, 'classes'>
>
