import React from 'react'
import styled from 'styled-components'
import { CONSTANTS } from 'utils/constants'
import Link from '../../themes/TypographyPro'
import reactNativeComms from 'utils/reactNativeComms'
import { redirect } from '../../utils/utils'

import dynamic from 'next/dynamic'
import { isNextJS } from '../../../utils/nextUtils'
import type { NextRouter } from 'next/router'
import { useRouter } from 'next/router'

const NextLink = isNextJS() && dynamic(() => import('next/link'))

const StyledLink = styled(Link)`
	text-decoration: none;
`

type Props = React.PropsWithChildren<{
	sectionItem: any
	isGeneratedStatically: boolean
	openInSameBrowserTab: boolean
	callbackClick?: (event?: React.MouseEvent | React.TouchEvent) => void
	id?: string
	testId: string
	style?: object
}>

/**
 * This is used for header and footer links.
 *
 * There are 4 types of link as detailed by the constants used below.
 *
 * If the SmartLink is used inside of the web-app then internal and toolGenerated links are rendered via the React
 * router.
 *
 * If the SmartLink is used to create a static page via Sergey's tool, then the link is to open in a new tab.
 */
const SmartLink = (props: Props) => {
	const { children, sectionItem, isGeneratedStatically, openInSameBrowserTab, callbackClick, id } = props
	const router = useRouter()

	const { ITEM_TYPES } = CONSTANTS.FOOTER

	return (
		<>
			{isGeneratedStatically && (sectionItem.itemType === ITEM_TYPES.EXTERNAL || sectionItem.itemType === ITEM_TYPES.DOWNLOAD) && (
				<StyledLink
					id={id}
					variant="BodySmall"
					component="a"
					href={`${sectionItem.path}`}
					target={openInSameBrowserTab ? '_self' : `static-page-${sectionItem.name}`}
					style={props.style}
					onClick={() => {
						if (callbackClick) {
							callbackClick()
						}
					}}
					data-testid={props.testId}
				>
					{children}
				</StyledLink>
			)}

			{isGeneratedStatically && (sectionItem.itemType === ITEM_TYPES.TOOL_GENERATED || sectionItem.itemType === ITEM_TYPES.INTERNAL) && (
				<StyledLink
					id={id}
					variant="BodySmall"
					component={isNextJS() ? NextLink : 'a'}
					href={isNextJS() ? sectionItem.path : `${sectionItem.path}.html`}
					target={openInSameBrowserTab ? '_self' : `static-page-${sectionItem.name}`}
					style={props.style}
					onClick={(e: React.MouseEvent<HTMLSpanElement> | React.TouchEvent<HTMLSpanElement>) => {
						if (callbackClick) {
							callbackClick(e)
						}
					}}
					data-testid={props.testId}
				>
					{children}
				</StyledLink>
			)}

			{!isGeneratedStatically &&
				(sectionItem.itemType === ITEM_TYPES.INTERNAL ||
					sectionItem.itemType === ITEM_TYPES.TOOL_GENERATED ||
					sectionItem.itemType === ITEM_TYPES.EXTERNAL) &&
				sectionItem.path && (
					<StyledLink
						id={id}
						style={props.style}
						variant="BodySmall"
						component={isNextJS() ? NextLink : 'a'}
						href={isNextJS() ? sectionItem.path : `#`}
						onClick={(e: React.MouseEvent<HTMLSpanElement> | React.TouchEvent<HTMLSpanElement>) => {
							if (callbackClick) {
								callbackClick()
							}
							if (sectionItem.itemType === ITEM_TYPES.EXTERNAL) {
								e.preventDefault()
								return reactNativeComms.sendMessage.openNativeBrowser(sectionItem.path)
							}
							if (isNextJS()) {
								return
							}
							SmartLink.route(sectionItem.path, router, false)
							e.preventDefault()
						}}
						data-testid={props.testId}
					>
						{children}
					</StyledLink>
				)}
		</>
	)
}

SmartLink.route = (pathname: string, router: NextRouter, isGeneratedStatically = false, extention = '.html') => {
	if (isNextJS()) {
		redirect(`${pathname}`)
	} else if (isGeneratedStatically) {
		// load the link by redirecting to it so the page will blink as all the resources are loaded (ok for a google bot navigating our static pages loaded by their unique URLs)
		redirect(`${pathname}${extention}`)
	} else {
		// load the link using the React Router so there's no page blink (this is used once the user is navigating our web-app)
		router.push(`${pathname}${window.location.search || ''}`)
	}
}

export default SmartLink
