import { CONSTANTS } from '../utils/constants'

type ChannelType = typeof CONSTANTS.CHANNEL_TYPE[keyof typeof CONSTANTS.CHANNEL_TYPE]

function isChannelTypeValid(input: unknown): input is ChannelType {
	if (typeof input !== 'string') {
		return false
	}
	return [...Object.values(CONSTANTS.CHANNEL_TYPE)].includes(input as ChannelType)
}

export { type ChannelType, isChannelTypeValid }
