import { groupBy } from 'lodash-es'
import { action, observable } from 'mobx'
import type { PrbCoupon } from './types'
import { CouponFilter, type Filter, type CouponsDependencies } from './types'
import type { Coupon, IndividualCoupon, LocalizedCoupon, StrippedIndividualCoupon, OrderType } from 'types/Coupons'
import { CouponSource, CouponStatus, isIndividual } from 'types/Coupons'
import type { LanguageLocale } from 'utils/language'
import { CheckCouponOn } from 'mobx/CouponFlow'
import CouponError, { ErrorCode } from './errors'
import type { Menu } from 'types/Menu'
import type GetGTResponse from 'types/GetGTResponse'
import { minutesToMilliseconds } from 'date-fns'

export default class CouponsStore {
	private readonly dependencies: CouponsDependencies

	constructor(dependencies: CouponsDependencies) {
		this.dependencies = dependencies
	}

	@observable coupons: Coupon[] = []

	/**
	 * When not null, opens a pop-up of a coupon modal with details about a single coupon
	 */
	@observable couponModal: Coupon | null = null

	@observable private readonly inStoreCouponsCache = new Map<string, PrbCoupon & { timestamp: Date }>()

	@action setCouponModal(coupon: Coupon | null) {
		this.couponModal = coupon
	}

	private mergeCoupon(coupon: Coupon, appliedDiscountsOnCart?: string[]): void {
		const cachedCoupon = this.getCachedCoupon(coupon.code)

		const appliedOnCart = appliedDiscountsOnCart?.some((v) => v === coupon.code || v === (coupon as StrippedIndividualCoupon).parentCode)
		coupon.flags.applied = { value: !!cachedCoupon?.flags.applied?.value || !!appliedOnCart }
	}

	@action
	async fetchCoupons(menu?: Menu | null): Promise<void> {
		const coupons = await this.dependencies.getMyCoupons()
		let gt: GetGTResponse | null = null

		if (menu) {
			gt = await this.dependencies.getGT(menu)
		}

		coupons.forEach((coupon) => this.mergeCoupon(coupon, gt?.response?.appliedDiscounts))
		console.log('coupons', coupons)

		this.coupons = this.coupons.filter(({ code }) => coupons.every(({ code: mergedCode }) => code !== mergedCode)).concat(coupons)

		const ignoreOrderTypes = this.dependencies.getIgnoreOrderTypes()
		this.coupons = this.coupons.flatMap(({ orderTypes, ...rest }) => {
			const newOrderTypes = orderTypes.filter((f) => !ignoreOrderTypes.includes(f))
			if (!newOrderTypes.length) {
				return []
			}

			return { ...rest, orderTypes: newOrderTypes }
		})

		console.log('FETCH COUPONS', this.coupons)
	}

	private static hasCacheExpired(coupon: Pick<Coupon, 'timestamp'>, expiry = minutesToMilliseconds(5)): boolean {
		return coupon.timestamp.getTime() <= new Date().getTime() - expiry
	}

	private getCachedCoupon(code: string): Coupon | null {
		const coupon = this.coupons.find(({ code: id }) => id === code) ?? null

		if (!coupon || CouponsStore.hasCacheExpired(coupon)) {
			return null
		}

		return coupon
	}

	@action
	clearCoupons(): void {
		this.coupons = []
	}

	@action
	async getCoupon(code: string, checkCouponOn: CheckCouponOn = CheckCouponOn.CHAIN): Promise<Coupon> {
		console.log('getCoupon code', code)
		const cachedCoupon = this.getCachedCoupon(code)
		console.log('cachedCoupon', cachedCoupon)
		if (cachedCoupon) {
			return cachedCoupon
		}

		const coupon = await this.dependencies.getCoupon(code, checkCouponOn)
		console.log('getCoupon coupon', coupon)
		this.mergeCoupon(coupon)
		console.trace('GET COUPON', coupon)
		this.coupons.push(coupon)

		return coupon
	}

	private static isCouponExpired(coupon: Coupon): boolean {
		return coupon.status === CouponStatus.EXPIRED || (coupon.expiration?.getTime() ?? Infinity) < new Date().getTime()
	}

	static validateCoupon(coupon: Coupon): void {
		if (CouponsStore.isCouponExpired(coupon)) {
			throw new CouponError('Coupon is expired', ErrorCode.COUPON_EXPIRED)
		}

		console.log(`Coupon ${coupon.code} status is ${coupon.status}`)

		if (coupon.status === CouponStatus.REDEEMED) {
			throw new CouponError('Coupon is redeemed', ErrorCode.COUPON_REDEEMED)
		}
	}

	private async normaliseAddToWalletError(code: string, error: CouponError): Promise<CouponError> {
		console.log(`Normalising ${error.code} for ${code}`)

		const valdiateAndReturn = async (): Promise<CouponError> => {
			const coupon = await this.getCoupon(code)

			try {
				CouponsStore.validateCoupon(coupon)
				return error
			} catch (err) {
				return err as CouponError
			}
		}

		switch (error.code) {
			case ErrorCode.USERS_COUPON_INDIVIDUAL_COUPON_NOT_AVAILABLE: {
				return valdiateAndReturn()
			}

			case ErrorCode.USERS_COUPON_INDIVIDUAL_COUPON_EXISTS:
			case ErrorCode.USERS_COUPON_INDIVIDUAL_PARENT_COUPON_EXISTS: {
				const newErr = await valdiateAndReturn()
				console.log(`newErr is ${newErr.code}`)
				return new CouponError(error.message, error.code, newErr === error)
			}

			default:
				return error
		}
	}

	@action async addCouponToWallet(code: string): Promise<IndividualCoupon> {
		const partialCoupon = await this.dependencies.addCouponToWallet(code).catch(async (error: CouponError) => {
			throw await this.normaliseAddToWalletError(code, error)
		})

		const cachedParent = this.getCachedCoupon(partialCoupon.parentCode)

		if (!cachedParent) {
			return this.getCoupon(code) as Promise<IndividualCoupon>
		}

		const { parentCode, id, usesLeft, totalUses } = partialCoupon
		// const result = addVirtualFields({
		const result = {
			...cachedParent,
			code: partialCoupon.code,
			expiration: new Date(partialCoupon.expiration),
			source: CouponSource.INDIVIDUAL,
			status: CouponStatus.AVAILABLE,
			parentCode,
			id,
			usesLeft,
			totalUses,
		} as IndividualCoupon

		this.coupons.push(result)

		return result
	}

	private readonly filterMap: Partial<Record<Filter, (coupon: Coupon) => boolean>> = {
		[CouponFilter.ALL]: () => true,
		[CouponFilter.MY_COUPONS]: ({ flags: { wallet }, source }) => source === CouponSource.INDIVIDUAL && !!wallet?.value,
	}

	filterCoupons(filter: Filter): Coupon[] {
		const defaultFilter = ({ orderTypes }: Coupon) => orderTypes.includes(filter as OrderType)

		return this.coupons.filter(
			(coupon) =>
				coupon.flags.wallet?.value &&
				(this.filterMap[filter] ?? defaultFilter)(coupon) &&
				(!isIndividual(coupon) || coupon.status === CouponStatus.AVAILABLE || coupon.flags.applied?.value) &&
				!CouponsStore.isCouponExpired(coupon)
		)
	}

	getGroupedCoupons(filter: Filter): Record<Filter, Coupon[]> {
		const grouped = groupBy(this.filterCoupons(filter), ({ orderTypes: type }) => (filter === CouponFilter.ALL ? type.join() : filter)) as Record<
			Filter,
			Coupon[]
		>

		;(Object.keys(grouped) as (keyof typeof grouped)[]).forEach((key) =>
			grouped[key].sort((a, b) => {
				const aDate: number = (isIndividual(a) && a.attachedAt?.getTime()) || Infinity
				const bDate: number = (isIndividual(b) && b.attachedAt?.getTime()) || Infinity

				return bDate - aDate
			})
		)

		return grouped
	}

	static localizeCoupon(coupon: Coupon, locale: LanguageLocale): LocalizedCoupon {
		// return addVirtualFields({ ...coupon, title: coupon.title[locale], description: coupon.description[locale] })
		return { ...coupon, title: coupon.title[locale], description: coupon.description[locale] }
	}

	async getPrbCode(id: string): Promise<PrbCoupon> {
		const cachedCoupon = this.inStoreCouponsCache.get(id)
		const EXPIRY = minutesToMilliseconds(1)

		if (cachedCoupon && !CouponsStore.hasCacheExpired(cachedCoupon, EXPIRY)) {
			return cachedCoupon
		}

		return this.dependencies.getPrbCode(id).then((result) => {
			this.inStoreCouponsCache.set(id, { ...result, timestamp: new Date() })
			return result
		})
	}
}
