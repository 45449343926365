// @ts-nocheck
import React, { useCallback, useRef, useEffect } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import type { ListOnScrollProps } from 'react-window'
import { VariableSizeList as List } from 'react-window'

export type SetSize = (index: number, size: number | undefined) => void

interface RenderListProps {
	Comp: (ref: React.RefObject<HTMLInputElement>) => React.ReactElement
	setSize: SetSize
	index: number
}

const RenderList = ({ Comp, setSize, index }: RenderListProps) => {
	const innerRef = useRef<HTMLInputElement>(null)

	useEffect(() => {
		setSize(index, innerRef?.current?.getBoundingClientRect()?.height)
	}, [setSize, index])

	return Comp(innerRef)
}

type RenderProps = (data: any, index: number, setSize: SetSize, style: {} | undefined, ref: React.RefObject<HTMLInputElement>) => React.ReactElement
interface VirtualizedListProps {
	data: any[]
	gapSize: number
	cardSize: number
	listContainer?: string
	renderProps: RenderProps
	onScroll: (data: ListOnScrollProps) => void
}

/**
 * VirtualizedList is a generic component that renders virtualized list with gaps
 * @param data - the array of your list
 * @param gapSize the gap size between the items (vertical)
 * @param cardSize the default height of an item in px
 * @param listContainer a container of the items (li tag for example)
 * @param onScroll an event that fires when scrolling the list
 * @param renderProps a callback that renders the list item in each iteration
 * @constructor
 */
const VirtualizedList = ({ data, gapSize, cardSize, listContainer, onScroll, renderProps }: VirtualizedListProps) => {
	const sizeMap = useRef<Record<string, number>>({})
	const listRef = useRef({ resetAfterIndex: (index: number) => {} })
	const setSize = useCallback((index: number, size: number | undefined) => {
		sizeMap.current = { ...sizeMap.current, [index]: size }
		listRef.current?.resetAfterIndex(index)
	}, [])
	const getSize = (index: number) => sizeMap.current[index] + gapSize || cardSize

	return (
		<AutoSizer>
			{({ width = 0, height = 0 }) => (
				<List
					// @ts-ignore
					ref={listRef}
					width={width}
					height={height}
					itemSize={getSize}
					itemData={data}
					itemCount={data.length}
					innerElementType={listContainer}
					onScroll={onScroll}
				>
					{({ data, index, style }: { data: any; index: number; style: {} | undefined }) => (
						<RenderList index={index} setSize={setSize} Comp={(ref) => renderProps(data, index, setSize, style, ref)} />
					)}
				</List>
			)}
		</AutoSizer>
	)
}

export default VirtualizedList
