// @ts-nocheck
import React, { useState } from 'react'
import { getTranslatedTextByKey } from 'utils/utils'
import styled from 'styled-components/macro'
import TypographyPro from 'themes/TypographyPro'
import { inject, observer } from 'mobx-react'
import LanguageIcon from '@material-ui/icons/Language'
import PropTypes from 'prop-types'
import Link from 'components/common/Link'

const StyledContainer = styled.div`
	padding-top: 5px;
	overflow-x: auto;
`

const StyledOpener = styled(TypographyPro)`
	color: var(--primary);
	display: inline-flex;
`

const StyledContent = styled.div`
	display: ${(props) => (props.shown ? 'block' : 'none')};
`

const StyledIframeContent = styled.div`
	overflow-y: hidden;
	height: 420px;
`

const StyledIframe = styled.iframe`
	border: none;
	width: 550px;
	height: 520px;
	margin-inline-start: -20px;
`

const StyledLangsLinkContent = styled.span`
	color: var(--inactive);
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
`

const StyledLanguageIcon = styled(LanguageIcon)`
	width: 14px;
	height: 14px;
`

const Nutritionix = inject('Infra')(
	observer(({ value, Infra }) => {
		const [iframeDisplay, setIframeDisplay] = useState({
			rendered: false,
			shown: false,
		})

		return (
			<StyledContainer>
				<StyledOpener variant="BodySmallClickable" onClick={() => setIframeDisplay({ rendered: true, shown: !iframeDisplay.shown })}>
					{iframeDisplay.shown
						? getTranslatedTextByKey('webviewFlow.nutrition.hide', 'Hide nutritional information')
						: getTranslatedTextByKey('webviewFlow.nutrition.show', 'Show nutritional information')}
				</StyledOpener>
				{iframeDisplay.rendered && (
					<StyledContent shown={iframeDisplay.shown}>
						<StyledIframeContent>
							<StyledIframe src={`${Infra.appParams?.nutritionix?.iframeBaseUrl}/${value}`} />
						</StyledIframeContent>
						<StyledContainer>
							<Link href={`${Infra.appParams?.nutritionix?.langsBaseUrl}/${value}?lang=fr`}>
								<StyledLangsLinkContent>
									<StyledLanguageIcon />
									<TypographyPro variant="BodySmallClickable">
										{getTranslatedTextByKey('webviewFlow.nutrition.otherLanguages', 'Other languages')}
									</TypographyPro>
								</StyledLangsLinkContent>
							</Link>
						</StyledContainer>
					</StyledContent>
				)}
			</StyledContainer>
		)
	})
)

Nutritionix.propTypes = {
	value: PropTypes.string.isRequired,
}

export default Nutritionix
