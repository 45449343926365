import type React from 'react'
import { useCallback, useContext, useEffect, useRef } from 'react'
import { useCurrentUrlInfo } from 'hooks/useCurrentUrlInfo'
import { useStores } from 'hooks/useStores'
import { inject, observer } from 'mobx-react'
import { readCouponDetailsFromUrl } from 'mobx/CouponFlow/utils'
import { CheckCouponOn } from 'mobx/CouponFlow'
import { CONSTANTS } from 'utils/constants'
import type _Infra from 'mobx/Infra'
import type _Cart from 'mobx/Cart'
import type _User from 'mobx/User'
import type _Home from 'mobx/Home'
import type _AddressManager from 'mobx/AddressManager'
import { StoreContext } from 'contexts/StoreContext'
import { useRouter } from 'next/router'
import { OrderType } from 'types/Coupons'
import LocalizationError from 'mobx/AddressManager/errors'

const UrlActionHandler: React.FC<{
	Infra?: typeof _Infra
	Cart?: typeof _Cart
	User?: typeof _User
	AddressManager?: typeof _AddressManager
	Home?: typeof _Home
}> = ({ Infra, Cart, User, AddressManager, Home }) => {
	const { pathname, query, hash, removeHash } = useCurrentUrlInfo()
	const router = useRouter()
	// const [ref, setRef] = useState<string>('')

	const handledHashes = useRef<Set<string>>(new Set())
	const forceLocalizationProcess = useRef<boolean>(false)

	const { couponFlowStore } = useStores()
	const { setStore } = useContext(StoreContext)

	const handleExternalCouponFromUrlLegacy = useCallback(async () => {
		if (couponFlowStore.couponToApply || !hash || (hash && handledHashes.current.has(hash))) {
			return
		}

		const couponDetails = readCouponDetailsFromUrl({ query, hash })

		if (couponDetails) {
			handledHashes.current.add(hash)
			removeHash()
			console.log('couponFlowStore.start', couponDetails)
			await couponFlowStore.start({
				code: couponDetails.code,
				orderTypeToForce: couponDetails.orderTypeToForce,
				openModal: true,
			})
		}
	}, [couponFlowStore, query, hash, removeHash])

	const handleForceLocalization = useCallback(
		async (storeId: string) => {
			if (!storeId || !AddressManager || !User || !Cart || !Home || !Infra) {
				return
			}
			console.log('handleForceLocalization')
			await AddressManager.handleForceLocalization(storeId, User, Cart, Home, Infra, router, setStore)
		},
		[AddressManager, User, Cart, Home, Infra, router, setStore]
	)

	const showError = useCallback(
		(message: string) => {
			Infra?.setNotification?.({
				open: true,
				title: message,
				message: '',
				onClose: () => {
					Infra?.closeNotification()
				},
				okAction: () => {
					Infra?.closeNotification()
				},
			})
		},
		[Infra]
	)

	useEffect(() => {
		const handleRouteChange = async () => {
			if (hash?.startsWith(CONSTANTS.URL_QUERY_HASH_KEY.ITEM) || hash?.startsWith(CONSTANTS.URL_QUERY_HASH_KEY.DISCOUNT)) {
				await handleExternalCouponFromUrlLegacy()
				return
			}

			if (query.has('forceLocalization') && query.get('forceLocalization') === 'true' && !forceLocalizationProcess.current) {
				try {
					forceLocalizationProcess.current = true

					const excludedKeys = new Set(['forceLocalization', 'storeId', 'couponCode'])
					const modifiedQuery: Record<string, string | string[]> = {}

					const storeId = query.get('storeId')
					const couponCode = query.get('couponCode')

					query.forEach((value, key) => {
						if (!excludedKeys.has(key)) {
							if (modifiedQuery[key]) {
								if (Array.isArray(modifiedQuery[key])) {
									;(modifiedQuery[key] as string[]).push(value)
								} else {
									modifiedQuery[key] = [modifiedQuery[key] as string, value]
								}
							} else {
								modifiedQuery[key] = value
							}
						}
					})

					router.replace(
						{
							pathname,
							query: modifiedQuery,
						},
						undefined,
						{ shallow: true }
					)
					await handleForceLocalization(storeId ?? '')

					if (couponCode) {
						await couponFlowStore.start({
							code: couponCode,
							orderTypeToForce: OrderType.PICKUP,
							openModal: true,
							checkCouponOn: CheckCouponOn.STORE,
						})
					}
				} catch (error: unknown) {
					let message = ''
					if (error instanceof LocalizationError) {
						message = error.text()
					} else if (error instanceof Error) {
						message = error.message
					} else {
						message = 'Something went wrong, please try again.'
					}
					showError(message)
				} finally {
					forceLocalizationProcess.current = false
				}
			}
		}

		handleRouteChange()
	}, [pathname, query, hash, handleExternalCouponFromUrlLegacy, router, handleForceLocalization, couponFlowStore, showError])

	return null // This component does not render anything
}

export default inject('Infra', 'User', 'Cart', 'AddressManager', 'Home')(observer(UrlActionHandler))
