import React from 'react'
import { inject, observer } from 'mobx-react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import User from '../../mobx/User'
import { useForm, Controller } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import classnames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import TextField from 'components/common/TextField'
import { getTranslatedTextByKey } from 'utils/utils'

const useStyles = makeStyles((theme) => ({
	submitButtons: {
		padding: '4px 15px 1px 15px',
		backgroundColor: theme.palette.primary.main,
		borderColor: theme.palette.primary.main,
		borderWidth: '2px',
		borderRadius: '10px',
		color: 'white',
		marginTop: '10px',
		'&:hover': {
			// you want this to be the same as the backgroundColor above
			backgroundColor: 'white',
			borderWidth: '2px',
			color: theme.palette.primary.main,
		},
	},
	buttonLabel: {
		display: 'block',
		width: '130px',
	},
}))

const formValidationSchema = {
	name: z
		.string({ required_error: 'Cannot be empty' })
		.min(1, { message: 'Cannot be empty' })
		.min(2, { message: 'Must be more than 2 chars' })
		.max(20, { message: 'Must be less than 20 chars' })
		.trim()
		.regex(/^[0-9a-zA-Z ]+$/, { message: 'Only alphanumeric chars' }),
}

const ShareWithFriendForm = inject('User')(
	observer((props: { inviter: string; closeForm: (formData: { name: string }) => void; User: { session?: { nickname: string } } }) => {
		const classes = useStyles()
		const schema = z.object(formValidationSchema)

		const { handleSubmit, control } = useForm<{ name: string }>({
			resolver: zodResolver(schema),
			mode: 'onChange',
			defaultValues: {
				name: User.session?.nickname || '',
			},
		})

		const onSubmit = (formData: { name: string }) => {
			props.closeForm(formData)
		}

		return (
			<>
				<Typography variant="subtitle2" style={{ marginBottom: '13px', lineHeight: '13px' }}>
					{getTranslatedTextByKey('toLet')} {props.inviter} {getTranslatedTextByKey('knowWhoAddedTheseCourses')}
				</Typography>
				{/* <Typography variant='caption'>
					{User.userLocaleLanguage.toLet} {props.inviter} {User.userLocaleLanguage.knowWhoAddedTheseCourses}
				</Typography> */}
				<form id="hook-form-delivery" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
					<Controller
						control={control}
						name="name"
						render={({ field, fieldState: { invalid, error } }) => (
							<TextField
								label={getTranslatedTextByKey('html.addYourName')}
								autoComplete="new-password"
								errorMessage={error?.message}
								{...field}
								error={invalid}
							/>
						)}
					/>
					<Button
						type="submit"
						variant="outlined"
						color="primary"
						className={classnames(classes.submitButtons)}
						classes={{
							label: classes.buttonLabel, // override the 'MuiButton-label' styling
						}}
					>
						<Typography component="div" variant="subtitle1">
							Checkout
						</Typography>
					</Button>
				</form>
			</>
		)
	})
)

export default ShareWithFriendForm
