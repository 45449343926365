const LOCAL = 'LOCAL'
const DEV = 'DEV'
const STAGING = 'STAGING'
const TICTUK_TESTS = 'TICTUK_TESTS'
const PROD = 'PROD'

interface EnvironmentVariables {
	NEXT_PUBLIC_ENV: Env
	ECOMMERCE_SERVER: string
	NEXT_PUBLIC_LOCAL_THEME_URL: string
}

type Env = typeof LOCAL | typeof DEV | typeof STAGING | typeof TICTUK_TESTS | typeof PROD

function isEnvValid(input: unknown): input is Env {
	if (typeof input !== 'string') {
		return false
	}
	return [LOCAL, DEV, TICTUK_TESTS, STAGING, PROD].includes(input)
}

function isEcommerceServerValid(input: unknown): input is string {
	return typeof input === 'string' && input.length !== 0
}

function areEnvironmentVariablesValid(input: unknown): input is EnvironmentVariables {
	if (!input) {
		return false
	}
	if (Array.isArray(input)) {
		return false
	}
	// @ts-ignore
	return isEnvValid(input.NEXT_PUBLIC_ENV) && isEcommerceServerValid(input.ECOMMERCE_SERVER)
}

export { type Env, isEnvValid, areEnvironmentVariablesValid, LOCAL, DEV, STAGING, TICTUK_TESTS, PROD }
