// @ts-nocheck
import React from 'react'
import { PropTypes } from 'prop-types'

import infra from 'mobx/Infra'
import { getThemeUrl } from 'utils/theme/themeManager'

/**
 * AssetsIcon will be not recolored programmatically
 * Product took a responsibility to manage colors by themselves (for some icons),
 * by uploading correctly colored svg icons to assets
 *
 * The assets location examples:
 * https://cdn.tictuk.com/defaultThemes/generic/assets
 * https://cdn.tictuk.com/< chainId >/theme/assets
 * https://cdn.tictuk.com/defaultThemes/brands/< brand >/assets
 * < domain >/theme/assets
 */
const AssetsIcon = ({ asset, className, alt, testId }) => {
	const { theme, c, brand } = infra.appParams
	const iconPath = `${getThemeUrl(theme, c, brand)}/assets`

	return <img alt={alt || ''} src={`${iconPath}${asset}`} className={className} data-testid={testId} />
}

AssetsIcon.propTypes = {
	asset: PropTypes.string,
	className: PropTypes.string,
	alt: PropTypes.string,
	testId: PropTypes.string,
}

export default AssetsIcon
