// @ts-nocheck
import Supercluster from 'supercluster'

// This returns a Supercluster instance, that it loaded with GeoJSON Feature points (created from a passed stores array)
// see: https://www.leighhalliday.com/google-maps-clustering
// Also see this task: https://tictuk.atlassian.net/browse/EO-191

const DEFAULT_RADIUS = 100
const MAX_ZOOM = 20

export const onClusterClick = (cluster, superCluster, cb) => {
	const [longitude, latitude] = cluster.geometry.coordinates
	const latLng = { lat: latitude, lng: longitude }
	const toZoomLevel = superCluster.getClusterExpansionZoom(cluster.id)
	cb(toZoomLevel, latLng)
}

export const getSupercluster = (stores) => {
	// This converts the store geo data into an array of GeoJSON Feature objects, to be used by the supercluster library.
	// See: https://www.leighhalliday.com/google-maps-clustering
	const storePoints = stores.map?.((store) => ({
		type: 'Feature',
		properties: {
			cluster: false,
			store,
		},
		geometry: {
			type: 'Point',
			coordinates: [parseFloat(store.address.latLng.lng), parseFloat(store.address.latLng.lat)],
		},
	}))

	const supercluster = new Supercluster({
		radius: DEFAULT_RADIUS,
		maxZoom: MAX_ZOOM,
	})

	supercluster.load(storePoints ?? [])
	return supercluster
}
