import type JSObject from '../../types/JSObject'
import type { LocaleObject } from 'utils/language'
import { CONSTANTS } from '../constants'

export const isJSObject = (input: unknown): input is JSObject => {
	if (typeof input !== 'object') {
		return false
	}
	if (input === null) {
		return false
	}
	return !Array.isArray(input)
}

/**
 * checks validity of the locale key of params.json
 * @param localeObject: LocaleObject
 * @returns {boolean}
 */
export const isParamsLocaleValid = (localeObject: unknown): localeObject is LocaleObject => {
	if (!isJSObject(localeObject)) {
		return false
	}
	const validLocaleMsg = CONSTANTS.LOCALES.includes(localeObject.msg)
	const validRegion = CONSTANTS.REGIONS.includes(localeObject.region)
	const validKey = typeof localeObject.key === 'undefined' || typeof localeObject.key === 'string'
	return validKey && validLocaleMsg && validRegion
}
