// @ts-nocheck
import React, { useEffect, useMemo, useRef, useState } from 'react'
import GoogleMapReact from 'google-map-react'
import styled from 'styled-components/macro'
import { getCurrentLocation, getTranslatedTextByKey } from 'utils/utils'
import InputAdornment from '@material-ui/core/InputAdornment'
import GooglePlacesAutoCompleteNew from 'components/common/GooglePlacesAutoCompleteNew'
import Marker from './Marker'
import { inject, observer } from 'mobx-react'
import { CONSTANTS, ORDER_TYPES } from 'utils/constants'
import Divider from '@material-ui/core/Divider'
import ReactNativeComms from 'utils/reactNativeComms'
import { useLocationMobileApp } from 'hooks/useLocationMobileApp'
import TextFieldBase from 'components/common/TextField'
import { isEmpty } from 'lodash-es'
import { webFlowAddress } from 'utils/api/webFlowAddress'
import IconComponent from 'themes/IconComponent'
import { getSupercluster, onClusterClick } from 'utils/mapUtils'

const Container = styled.div`
	width: 600px;
	position: relative;

	@media (max-width: 1024px) {
		width: unset;
		height: 222px;
	}
`

const StyledDivider = styled(Divider)`
	height: 30px;
`

const TextField = styled(TextFieldBase)`
	position: absolute;
	top: 20px;
	width: 80%;
	left: 10%;
	filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.2));

	.MuiInputBase-root {
		height: 45px;
	}

	.MuiInputBase-root {
		padding-inline-end: 0;
	}

	fieldset {
		${({ error }) => (error ? '' : 'border: 0')};
	}

	@media (max-width: 600px) {
		left: unset;
		width: 100%;
		padding: 0 10px;
	}
`

const FindMyLocationIconContainer = styled.div`
	width: 55px;
	height: 45px;
	background-color: var(--primary);
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border-top-right-radius: var(--borderRadiusInput);
	border-bottom-right-radius: var(--borderRadiusInput);
`

const mapZoomSizes = {
	deepZoom: 13,
	generalZoom: 7,
}

let markerMap
const MAP_DRAGGING_TIMEOUT_IN_MS = 2000

const StoresMap = inject(
	'Home',
	'Infra',
	'Application',
	'MobileApplication',
	'User',
	'AddressManager'
)(
	observer(
		({
			marker,
			selectStoreOnMap,
			MobileApplication,
			Home,
			Infra,
			Application,
			AddressManager,
			selectedStoreOnMap,
			selectClusterOnMap,
			selectedOrderType,
			resetStoreOnMap,
			shouldFilterByAvailability,
			setFilterByAvailability,
			User,
		}) => {
			const { locale } = Home
			const { isMobileApp } = Application
			const { addressPopup, setAddressPopup } = AddressManager
			const { setValue } = addressPopup
			const [center, setCenter] = useState({})
			const [isDialogOpen, setDialogOpen] = useState(false)
			const { userLocation, userLocationPermissionStatus, setLocationPermissionsStatus } = MobileApplication
			const [searchAddressValue, setSearchAddress] = useState(Home?.currentLocation?.address || '')
			const [zoom, setZoom] = useState(mapZoomSizes.generalZoom)
			const [bounds, setBounds] = useState([0, 0, 0, 0])
			const [map, setMap] = useState(null)
			const [markers, setMarkers] = useState([])
			const mapDragTimerRef = useRef(null)

			const stores = useMemo(() => {
				// There is an alternative: create in Stores.jsx states for filter truthy and another state filter of nearby = false and merge those 2 and pass to StoresMap.jsx
				const storesListNearby = Home.stores
					.filter((store) => store.availability?.[selectedOrderType]?.availableForAddress)
					.map((store, index) => ({ ...store, storeRank: index }))
				const storesListNonNearby = Home.stores.filter(
					(store) => isEmpty(store.availability[selectedOrderType]) || !store.availability[selectedOrderType]?.availableForAddress
				)
				return [...storesListNearby, ...storesListNonNearby]
			}, [Home.stores, selectedOrderType, zoom])

			const googlePlacesCallback = async (googlePlaceData = {}) => {
				if (!isEmpty(googlePlaceData) && Object.hasOwn(googlePlaceData, 'name')) {
					googlePlaceData = await getLatLngByAddress(googlePlaceData.name)
				}

				const { l: lang, c: chainId } = Infra.appParams

				let formatted = googlePlaceData.formatted || googlePlaceData?.formatted_address
				const lat = googlePlaceData.lat || googlePlaceData?.geometry?.location.lat()
				const lng = googlePlaceData.lng || googlePlaceData?.geometry?.location.lng()

				try {
					const res = await webFlowAddress.getBranchesList({
						formatted,
						lat,
						lng,
						chainId,
						lang,
						orderType: selectedOrderType,
					})
					if (!res.error) {
						let geoCodeResult = null

						if (!formatted) {
							geoCodeResult = await geoCode(
								{
									lat,
									lng,
								},
								true
							)
							formatted = geoCodeResult?.formatted_address || ''
						}
						resetStoreOnMap()
						Home.setCurrentLocation({ address: formatted, lat, lng })
						Home.setStores(res.msg || [])

						if (!Infra?.appParams?.features?.useNewAddressBox) {
							const addressObject = geoCodeResult || googlePlaceData
							setValue('addressObject', addressObject)
						}
						setFilterByAvailability(true)
					}
				} catch (err) {
					console.log(err)
				} finally {
					markerMap.setPosition({
						lat,
						lng,
					})
					setSearchAddress(formatted)
					setAddressPopup({ ...addressPopup, mapButtonClicked: false })
				}
			}

			const onLocationPermissionGranted = async (location) => {
				setCenter({ lat: location.lat, lng: location.long })
				markerMap.setPosition({ lat: location.lat, lng: location.long })
				googlePlacesCallback({ lat: location.lat, lng: location.long })
				Infra.setLoading(false)
			}

			const onLocationPermissionDenied = async () => {
				console.warn('enable location permission')
				Infra.setLoading(false)
				Infra.setNotification({
					title: `${getTranslatedTextByKey('webviewFlow.locationPermission')}`,
					open: isDialogOpen,
					message: <span>{getTranslatedTextByKey('webviewFlow.pleaseAllowLocationPermission')}&nbsp;</span>,
					okAction: () => setDialogOpen(false),
					okText: `${getTranslatedTextByKey('webviewFlow.ok')}`,
				})
			}

			if (isMobileApp) {
				useLocationMobileApp(onLocationPermissionGranted, onLocationPermissionDenied, userLocationPermissionStatus, userLocation)
			}

			useEffect(
				() => () => {
					clearTimeout(mapDragTimerRef.current)
				},
				[]
			)

			useEffect(() => {
				setSearchAddress(Home.currentLocation.address)
				setCenter({ lat: Home.currentLocation.lat, lng: Home.currentLocation.lng })
				if (markerMap) {
					markerMap.setPosition({ lat: Home.currentLocation.lat, lng: Home.currentLocation.lng })
				}
			}, [Home.currentLocation.lat, Home.currentLocation.lng])

			useEffect(() => {
				if (AddressManager.addressPopup?.mapButtonClicked || !shouldFilterByAvailability) {
					setZoom(mapZoomSizes.generalZoom)
				} else {
					// setZoom(mapZoomSizes.deepZoom)
				}
			}, [AddressManager.addressPopup?.mapButtonClicked, shouldFilterByAvailability])

			const supercluster = getSupercluster(stores)

			const handleClusterClick = (cluster) =>
				onClusterClick(cluster, supercluster, (zoomLevel, latLng) => {
					setZoom(zoomLevel)
					setCenter(latLng)
					map?.panTo(latLng)
					selectClusterOnMap(cluster, supercluster)
				})

			const getMarkers = (mapBounds, mapZoom) => {
				const clusters = supercluster.getClusters(mapBounds, mapZoom)
				return clusters.map((cluster) => {
					const [longitude, latitude] = cluster.geometry.coordinates
					const { cluster: isCluster, point_count: pointCount, store } = cluster.properties

					if (isCluster) {
						return (
							<Marker
								key={cluster.id}
								lat={latitude}
								lng={longitude}
								isCluster
								cluster={cluster}
								pointCount={pointCount}
								onClusterClick={() => handleClusterClick(cluster)}
							/>
						)
					}
					return (
						<Marker
							key={store.id}
							onSelectStore={() => selectStoreOnMap(store, store.storeRank + 1)}
							lat={latitude}
							lng={longitude}
							marker={marker}
							distance={store.distance}
							formatted={store.address.formatted}
							rank={store.storeRank + 1}
							isSelected={store.id === selectedStoreOnMap.id}
							isNearby={!!store.availability?.[selectedOrderType]?.availableForAddress}
							isCluster={false}
						/>
					)
				})
			}

			useEffect(() => {
				setMarkers(getMarkers(bounds, zoom))
			}, [selectedStoreOnMap])

			const onGoogleApiLoaded = (map, maps) => {
				setMap(map)
				markerMap = new maps.Marker({
					map,
					position: {
						lat: Home.currentLocation.lat,
						lng: Home.currentLocation.lng,
					},
				})

				const tempFormattedAddress = AddressManager.getTempFormattedAddressByOrderType(
					User.getOrderType() === CONSTANTS.DELIVERY_METHODS.DELIVERY ? ORDER_TYPES.DELIVERY : ORDER_TYPES.PICKUP
				)

				Home.setCurrentLocation({
					address: tempFormattedAddress || Home.currentLocation.address,
					lat: Home.currentLocation.lat,
					lng: Home.currentLocation.lng,
				})
			}

			const onDrag = (map) => {
				markerMap.setPosition(map.center.toJSON())
			}

			const onDragEnd = () => {
				if (mapDragTimerRef.current) {
					clearTimeout(mapDragTimerRef.current)
				}

				mapDragTimerRef.current = setTimeout(() => {
					const markerLocation = markerMap.getPosition().toJSON()
					googlePlacesCallback(markerLocation)
					setMarkers(getMarkers(bounds, zoom))
				}, MAP_DRAGGING_TIMEOUT_IN_MS)
			}

			const geoCode = (latLng, getFullResponse = false) => {
				const geocoder = new window.google.maps.Geocoder()
				return new Promise((resolve) => {
					geocoder.geocode({ latLng }, (responses) => {
						if (getFullResponse) {
							resolve(responses?.[0])
						} else {
							resolve(responses?.[0]?.formatted_address)
						}
					})
				})
			}

			const getLatLngByAddress = (address) => {
				const geocoder = new window.google.maps.Geocoder()
				return new Promise((resolve) => {
					geocoder.geocode(
						{
							address,
							componentRestrictions: {
								country: locale.region,
							},
						},
						(responses) => {
							resolve(responses?.[0])
						}
					)
				})
			}

			const requestMyLocation = () => {
				Infra.setLoading(true)
				if (isMobileApp) {
					setLocationPermissionsStatus(CONSTANTS.MOBILE_APP.PERMISSIONS.LOCATION.NOT_ASKED)
					ReactNativeComms.sendMessage.askForLocation()
				} else {
					getCurrentLocation(onLocationPermissionGranted, onLocationPermissionDenied)
				}
			}

			const onChangeAddress = (e) => {
				setSearchAddress(e.target.value)
			}
			const onMapChange = ({ zoom, bounds }) => {
				setZoom(zoom)
				const mapBounds = [bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]
				setBounds(mapBounds)
				setMarkers(getMarkers(mapBounds, zoom))
			}

			return (
				<Container data-testid="map-container">
					{!isEmpty(locale) && (
						<GoogleMapReact
							yesIWantToUseGoogleMapApiInternals
							onGoogleApiLoaded={({ map, maps }) => onGoogleApiLoaded(map, maps)}
							options={{
								clickableIcons: false,
								fullscreenControl: false,
								gestureHandling: 'greedy',
							}}
							bootstrapURLKeys={{ key: locale.key || 'AIzaSyBnea1oOS9Wif_l-zV6WfxjA4sZs5kpt-k' }}
							defaultCenter={{ lat: 0, lng: 0 }}
							center={center}
							defaultZoom={mapZoomSizes.deepZoom}
							zoom={zoom}
							onDrag={onDrag}
							onDragEnd={onDragEnd}
							onChange={onMapChange}
						>
							{markers}
						</GoogleMapReact>
					)}

					{/* google-map-react checks if the base apiPayments is already loaded, and if so, uses it, so it won't load a
                    second copy of the library. So the console warning saying "Google maps already loaded" does not
                    request the API from Google's CDN, it's just a warning that this plugin checked that the Google
                    API has already loaded.
                */}

					<GooglePlacesAutoCompleteNew
						{...locale}
						apiKey={locale.key}
						onPlaceSelected={googlePlacesCallback}
						renderTextField={(ref) => (
							<TextField
								placeholder={getTranslatedTextByKey('webviewFlow.localization.onTheMap.textFieldPlaceholder')}
								inputRef={ref}
								id="localization-page-type-location"
								value={searchAddressValue}
								onChange={onChangeAddress}
								error={selectedOrderType === ORDER_TYPES.DELIVERY && Home.currentLocation?.address === ''}
								onResetValue={(value) => {
									setSearchAddress(value)
									Home.setCurrentLocation({ ...Home.currentLocation, address: '' })
								}}
								inputProps={{ 'data-testid': 'map-dialog-input' }}
								startAdornment={
									<InputAdornment position="start">
										<IconComponent asset="/icons/find.svg" />
									</InputAdornment>
								}
								endAdornment={
									<InputAdornment position="end">
										<StyledDivider orientation="vertical" />
										<FindMyLocationIconContainer
											onClick={() => requestMyLocation()}
											id="localization-page-localization-icon"
											data-testid="use-user-location-button"
											role="button"
										>
											<IconComponent asset="/icons/useMyLocation.svg" />
										</FindMyLocationIconContainer>
									</InputAdornment>
								}
							/>
						)}
					/>
				</Container>
			)
		}
	)
)

export default StoresMap
