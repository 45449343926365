import React from 'react'
import styled from 'styled-components'

const ClusterSVGCircle = styled.circle`
	fill: var(--localizationActive);
`

const ClusterSVGText = styled.text`
	fill: var(--clear);
	${({ theme }) => theme.typography.BodySmallHighlighted};
`

interface ClusterMarkerProps {
	pointCount: number
	onClusterClick: (cluster: any) => void
	className?: string
}

/* This marker represents a "group" of store markers.
 * It displays a number representing how many stores are grouped within in.
 * Clicking on it will "expand" the group, into possibly specific store markers or "smaller"
 * cluster markers (containing a lower amount of grouped stores) - this is dependent on the zoom level.
 * Currently clicking zooms-in one "level" of zoom, and expands the group accordingly
 */
const GoogleMapsClusterMarker = ({ pointCount, onClusterClick, className }: ClusterMarkerProps) => (
	<svg
		onClick={onClusterClick}
		width="45"
		height="45"
		viewBox="0 0 45 45"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
		data-testid="cluster"
	>
		<ClusterSVGCircle cx="22.5" cy="22.5" r="22.5" />
		<ClusterSVGText x="22.5" y="27" textAnchor="middle">
			{pointCount}
		</ClusterSVGText>
	</svg>
)

export default GoogleMapsClusterMarker
