import { Page } from './constants'

const routeToPage = (url: string, ignoreStaticPages = false): Page | null => {
	if (url === '/') {
		return Page.HOME
	}

	const parts = url.split('/')

	if (parts[1] === 'empty-cart') {
		return Page.ORDER_SUMMARY
	}

	const sanitizeRoute = (route: string): Page => route.replace('.html', '') as Page

	if (ignoreStaticPages) {
		return Object.values(Page).find((page) => parts[1] === page) ?? null
	}

	return parts.map(sanitizeRoute).find((part): part is Page => Object.values(Page).includes(sanitizeRoute(part))) ?? null
}

export default routeToPage
