import { Grid } from '@material-ui/core'

const Nutrient = ({ data }) => (
	<Grid key={data.name} container>
		<Grid item xs={9}>
			{data.displayName}
		</Grid>
		<Grid item xs={3}>
			{`${data.value} ${data.unit || 'n/a'}`}
		</Grid>
	</Grid>
)

export default Nutrient
