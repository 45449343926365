import React, { useState } from 'react'
import type { ImageProps } from 'next/image'
import Image from 'next/image'
import { inject, observer } from 'mobx-react'
import type _Infra from 'mobx/Infra'

interface Props extends ImageProps {
	fallbackSrc?: string
	Infra?: typeof _Infra
}

const ImageNext = ({ fallbackSrc, src, onError, Infra, ...imageProps }: Props) => {
	const fallback = fallbackSrc ?? (Infra?.appParams?.logo as string)
	const [imgSrc, setImgSrc] = useState(src || fallback)

	const handleError: React.ReactEventHandler<HTMLImageElement> = (event) => {
		if (imgSrc === src) {
			setImgSrc(fallback)
		} else {
			onError?.(event)
		}
	}

	return <Image {...imageProps} src={imgSrc ?? fallback} onError={handleError} loader={({ src: requestedSrc }) => requestedSrc} unoptimized />
}

export default inject('Infra')(observer(ImageNext))
