export const SIGN_UP_STEPS = {
	// HOME: 'HOME',
	EDIT_PHONE_NUMBER: 'EDIT_PHONE_NUMBER',
	EDIT_CODE: 'EDIT_CODE',
	EXTEND_USER_DETAILS: 'EXTEND_USER_DETAILS',
	SIGNED_OUT: 'SIGNED_OUT',
	DELETED: 'DELETED',
}

export const AUTHENTICATION_TYPE = {
	PHONE_VERIFICATION: 'phoneVerification',
	GUEST: 'guest',
}

export const ECOMMERCE_USER = 'eCommerceUser'
