// @ts-nocheck
import { useEffect } from 'react'
import { CONSTANTS } from 'utils/constants'

// This hook should be dependent on MobileApplication mobx userLocationPermissionStatus param, that every call for user location we change the status of
export const useLocationMobileApp = (onSuccess, onError, userLocationPermissionStatus, userLocation) => {
	useEffect(() => {
		const handleUserLocationPermissionResponse = async () => {
			if (userLocationPermissionStatus === CONSTANTS.MOBILE_APP.PERMISSIONS.LOCATION.DENIED) {
				onError()
			} else if (userLocationPermissionStatus === CONSTANTS.MOBILE_APP.PERMISSIONS.LOCATION.GRANTED) {
				onSuccess({ lat: userLocation.latitude, long: userLocation.longitude })
			}
		}
		if (userLocationPermissionStatus !== CONSTANTS.MOBILE_APP.PERMISSIONS.LOCATION.NOT_ASKED) {
			handleUserLocationPermissionResponse()
		}
	}, [userLocationPermissionStatus])
}
