// @ts-nocheck
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import reactNativeComms from '../../utils/reactNativeComms'

const Wrapper = styled.a`
	color: var(--footerAndDarkBackgrounds);
	${({ theme }) => theme.typography.BodyRegularClickable};
	text-decoration-line: underline;
	cursor: pointer;

	&:hover,
	&:active {
		color: var(--primary);
	}
`

const Link = inject('Application')(
	observer((props) => {
		const { Application, isRouterLink = false, isPhoneNumber = false, href, children, isExternal = true, target = '_blank' } = props

		if (isPhoneNumber) {
			return <Wrapper href={`tel:${href}`}>{children}</Wrapper>
		}
		if (Application.isMobileApp && isExternal) {
			return <Wrapper onClick={() => reactNativeComms.sendMessage.openNativeBrowser(href)}>{children}</Wrapper>
		}
		if (isRouterLink) {
			return <Wrapper to={href}>{children}</Wrapper>
		}
		return (
			<Wrapper href={href} target={target}>
				{children}
			</Wrapper>
		)
	})
)
Link.propTypes = {
	isRouterLink: PropTypes.bool,
	isPhoneNumber: PropTypes.bool,
	isExternal: PropTypes.bool,
	href: PropTypes.string,
	target: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

export default Link
