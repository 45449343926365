import { getTranslatedTextByKey } from 'utils/language'
import { z } from 'zod'

export enum ErrorCode {
	PICKUP_DISABLED_FOR_STORE = 'PICKUP_DISABLED_FOR_STORE',
	STORE_NOT_FOUND = 'STORE_NOT_FOUND',
	STORE_CLOSED = 'STORE_CLOSED',
	DEFAULT = 'DEFAULT',
}

// TODO: Add keys to conversationText in core and add translations
const errorTranslations: Record<ErrorCode, string> = {
	[ErrorCode.PICKUP_DISABLED_FOR_STORE]: 'eCommerce.localization.error.pickupDisabledForStore',
	[ErrorCode.STORE_NOT_FOUND]: 'eCommerce.coupons.error.storeNotFound',
	[ErrorCode.DEFAULT]: 'eCommerce.coupons.error.default',
	[ErrorCode.STORE_CLOSED]: 'eCommerce.coupons.error.storeClosed',
}

const defaultMessage = 'Something went wrong, please try again.'

const defaultErrorTranslations: Record<ErrorCode, string> = {
	[ErrorCode.PICKUP_DISABLED_FOR_STORE]: 'Pickup is disabled for store',
	[ErrorCode.STORE_NOT_FOUND]: defaultMessage,
	[ErrorCode.DEFAULT]: defaultMessage,
	[ErrorCode.STORE_CLOSED]: 'Store is closed',
}

const schema = z.object({ error: z.object({ message: z.string(), code: z.string() }) })

export interface Translation {
	key: string
	fallback: string
}

export const translate = (code: ErrorCode): Translation => ({
	key: errorTranslations[code],
	fallback: defaultErrorTranslations[code],
})

export default class LocalizationError extends Error {
	constructor(message: string, public code: ErrorCode) {
		super(message)
		this.code = code
	}

	translate(): Translation {
		return translate(this.code)
	}

	text(): string {
		return getTranslatedTextByKey(this.translate().key, this.translate().fallback)
	}

	static isLocalizationError(maybeError: unknown): maybeError is z.infer<typeof schema> {
		return schema.safeParse(maybeError).success
	}
}
