import type CouponsStore from 'mobx/Coupons'
import type CouponFlowStore from 'mobx/CouponFlow'
import { createContext } from 'react'

export interface Stores {
	couponsStore: CouponsStore
	couponFlowStore: CouponFlowStore
}

const StoresContext = createContext<Stores | undefined>(undefined)

export default StoresContext
