import { isMobile, isMobileApp } from 'utils/utils'

const pushHistoryState = (newUrl: string, unused: string) => {
	window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, '', newUrl)
	window.history.pushState('object or string', unused, newUrl)
}

const replaceHistoryState = () => {
	setTimeout(() => {
		const newUrl = `${window.location.pathname}${window.location.search}`
		window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, '', newUrl)
	}, 50)
}

export default { isMobileApp, isMobile, replaceHistoryState, pushHistoryState }
