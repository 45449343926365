// @ts-nocheck
import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import AddCircleIconOutlined from '@material-ui/icons/AddCircleOutline'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import PropTypes from 'prop-types'
import { formatPrice } from '../../../utils/utils'
import { isNil } from 'lodash-es'
import styled from 'styled-components/macro'
import TypographyPro from '../../../themes/TypographyPro'

const StyledRoot = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	line-height: 42px;

	@media (min-width: 1280px) {
		justify-content: flex-start;
		margin-inline-start: ${(props) => (props.$showLabel ? '0' : '-10px')};
		line-height: 48px;
	}
`

const LabelWrapper = styled.div`
	flex-grow: 1;
`

const StyledTitle = styled(TypographyPro)`
	color: var(--footerAndDarkBackgrounds);
	line-height: 15px;

	@media (min-width: 1280px) {
		line-height: 17px;
	}

	.invalid & {
		color: var(--err);
	}
`

const StyledQuantityInHeader = styled(TypographyPro)`
	text-align: center;
	color: var(--footerAndDarkBackgrounds);
	line-height: 23px;
	width: 23px;

	@media (min-width: 1280px) {
		width: 46px;
	}
`

const StyledQuantity = styled(TypographyPro)`
	text-align: center;
	color: var(--footerAndDarkBackgrounds);
	line-height: 3px;
	width: 24px;

	&:after {
		font-size: 8px;
		font-weight: 400;
		content: '\\a${(props) => props.word}';
		white-space: pre;
	}
`

const StyledAddCircleIcon = styled(AddCircleIcon)`
	font-size: ${({ $showLabel }) => ($showLabel ? '32px' : '35px')};

	@media (min-width: 1280px) {
		font-size: ${({ $showLabel }) => ($showLabel ? '35px' : '58px')};
	}
`

const StyledAddCircleIconOutlined = styled(AddCircleIconOutlined)`
	font-size: ${({ $showLabel }) => ($showLabel ? '32px' : '35px')};

	@media (min-width: 1280px) {
		font-size: ${({ $showLabel }) => ($showLabel ? '35px' : '58px')};
	}
`

const StyledRemoveCircleOutlineIcon = styled(RemoveCircleOutlineIcon)`
	font-size: ${({ $showLabel }) => ($showLabel ? '32px' : '35px')};

	@media (min-width: 1280px) {
		font-size: ${({ $showLabel }) => ($showLabel ? '35px' : '58px')};
	}
`

const StyledTypographyPrice = styled(TypographyPro)`
	color: var(--disable);
`

/**
 * This is a renewed version of the old ItemQuantity
 * in order to avoid bugs and unnecessary problems I made a copy of the old file
 * And added customize changes to it according to the requirements
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ItemQuantityForNewDataFormat = ({
	quantity,
	max,
	manageQuantities,
	variations,
	price,
	id,
	label,
	showLabel,
	currency,
	countryCode,
	isDecDisabled,
	isIncDisabled,
}) => {
	const handleIncrement = () => {
		if (max === undefined || quantity < max) {
			setUpdateBy(1)
		}
	}

	const handleDecrement = () => {
		if (quantity > 0) {
			setUpdateBy(-1)
		}
	}

	const setUpdateBy = (increment) => {
		if (manageQuantities) {
			if (variations) {
				const [nonDefaultVariationId] = variations[0].itemIds
				const price = price || variations[0].prices[nonDefaultVariationId]
				manageQuantities({ id, quantity: increment, name: label, price })
			} else {
				// used in MenuItemPageHeader
				manageQuantities(increment)
			}
		}
	}

	return (
		<StyledRoot $showLabel={showLabel}>
			{showLabel && (
				<LabelWrapper>
					<StyledTitle variant="BodyRegular" component="span">
						{label}
					</StyledTitle>
					{!isNil(price) && price > 0 && (
						<StyledTypographyPrice variant="BodySmall" component="span">
							&nbsp;{formatPrice(price, currency, countryCode)}
						</StyledTypographyPrice>
					)}
				</LabelWrapper>
			)}

			{showLabel && (
				<>
					<IconButton onClick={handleDecrement} size="small" data-testid="item-quantity-dec-btn" disabled={isDecDisabled}>
						<StyledRemoveCircleOutlineIcon $showLabel={showLabel} />
					</IconButton>
					<StyledQuantity component="p" variant="BodyRegular">
						{quantity}
					</StyledQuantity>
					<IconButton onClick={handleIncrement} size="small" data-testid="item-quantity-add-btn" disabled={isIncDisabled}>
						<StyledAddCircleIconOutlined $showLabel={showLabel} />
					</IconButton>
				</>
			)}

			{!showLabel && (
				<>
					<IconButton onClick={handleDecrement} size="small" data-testid="item-quantity-dec-btn" disabled={isDecDisabled}>
						<StyledRemoveCircleOutlineIcon $showLabel={showLabel} />
					</IconButton>
					<StyledQuantityInHeader component="div" variant="Subhead">
						{quantity}
					</StyledQuantityInHeader>
					<IconButton onClick={handleIncrement} color="primary" size="small" data-testid="item-quantity-add-btn" disabled={isIncDisabled}>
						<StyledAddCircleIcon $showLabel={showLabel} />
					</IconButton>
				</>
			)}
		</StyledRoot>
	)
}

export default ItemQuantityForNewDataFormat

ItemQuantityForNewDataFormat.propTypes = {
	max: PropTypes.number,
	label: PropTypes.string,
	manageQuantities: PropTypes.func,
	showLabel: PropTypes.bool.isRequired,
	defaultValue: PropTypes.number,
}
