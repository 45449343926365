// @ts-nocheck
/*
 * This is a mechanism that makes sure only a single request from a given sets of requests is active at one point in time to prevent certain
 * race conditions from occuring with certain types of requests
 */
import Infra from 'mobx/Infra'

const requestsQueue = [] // A queue of "arguments objects" for a sendRequest function (plus resolve/reject callbacks of a pending Promise)
let currentRequestData = null
let requestPending = false

/*
 * The response can either be a JSON data string or an Error object (these are the two things that can be returned from sendRequest)
 */
const onRequestDone = (sendRequest) => (response) => {
	if (!currentRequestData) {
		// This condition should NEVER happen. It is here for a testing period.
		console.warn('Queueable request onRequestDone callback called, and no currentRequestData found!')
	}

	console.log(`[RequestQueue] Response from: ${currentRequestData.url}`)
	// If we are here - we have a pending Promise that is waiting to be resolved/rejected
	if (response.constructor.name === 'Error') {
		currentRequestData.reject(response)
	} else {
		currentRequestData.resolve(response)
	}

	currentRequestData = null
	requestPending = false
	if (!requestsQueue.length) {
		Infra.setIsRequestsQueueFree(true)
		return
	}
	// Send next request in queue
	sendQueueableRequest(sendRequest)(requestsQueue.shift())
}

const enqueueRequest = (requestArgs) => {
	console.log(`[RequestQueue] Enqueing request for: ${requestArgs.url}`)
	requestsQueue.push(requestArgs)
}

const sendQueueableRequest = (sendRequest) => (requestArgs) => {
	requestPending = true
	currentRequestData = requestArgs
	const { showLoader, url, httpMethod, postDataJSON, extraheaders, stopLoading, timeout } = currentRequestData
	console.log(`[RequestQueue] Sending request: ${url}`)
	sendRequest(false, url, httpMethod, postDataJSON, extraheaders, stopLoading, timeout, onRequestDone(sendRequest))
}

export const queueableSendRequest =
	(sendRequest) =>
	(showLoader, url, httpMethod, postDataJSON?, extraheaders?, stopLoading? = true, timeout? = 30000) => {
		Infra.setIsRequestsQueueFree(false)
		return new Promise((resolve, reject) => {
			const requestArgs = { showLoader, url, httpMethod, postDataJSON, extraheaders, stopLoading, timeout, resolve, reject }
			if (requestPending) {
				enqueueRequest(requestArgs)
			} else {
				sendQueueableRequest(sendRequest)(requestArgs)
			}
		})
	}
