import type { NextRouter } from 'next/router'
import type { Channel, Coupon, OrderType } from 'types/Coupons'

export interface CouponToApply {
	coupon: Coupon
	orderTypeToForce: OrderType | null
	ready: boolean
}

export enum CheckCouponOn {
	CHAIN = 'chain',
	STORE = 'store',
}

export interface StartCouponFlowProps {
	code: string
	orderTypeToForce: OrderType | null
	openModal?: boolean
	checkCouponOn?: CheckCouponOn
}

export interface CouponFlowDependencies {
	router: NextRouter | null
	getCoupon(code: string, checkCouponOn: CheckCouponOn): Promise<Coupon>
	isLocalized(): boolean
	resetLocalization(): void
	isPageLegal(currentPath: string): boolean
	openLocalizationModal(couponToApply: CouponToApply): void
	isStoreClosed(): Promise<boolean>
	setCouponModal(coupon: Coupon | null): void
	showError(message: string, coupon: Coupon | null): void
	showConfirm(message: string, okAction: () => void, cancelAction?: () => void): void
	isChainClosed(): boolean
	isCouponForChannel(couponChannels: Channel[]): boolean
	isUserLoggedIn(): boolean
	getTranslatedTextByKey(path: string, fallback?: string): string
	getAndUpdateSession(): Promise<
		| {
				menuRes: any
				storeMetaData: any
				serverSession: any
				menuUrl: any
		  }
		| undefined
	>
}
