// @ts-nocheck
import queryString from 'query-string'
import { CONSTANTS } from 'utils/constants'
import { codeToLocale } from 'utils/utils'
import { getTranslatedTextByKey } from './language'

export const getQueryStringAndCouponCode = (urlQs = '') => {
	const isCouponCodeIncludedInQs = urlQs.includes('#cc_') || urlQs.includes('#item_')
	let queryParamsString = urlQs
	let couponCode = ''

	if (isCouponCodeIncludedInQs) {
		const indexOfLastHash = urlQs.lastIndexOf('#')
		queryParamsString = urlQs.slice(0, indexOfLastHash)
		couponCode = urlQs.slice(indexOfLastHash)
	}
	return { queryParamsString, couponCode }
}

export const getDealRedirectQueryString = (queryParamsString, couponCode) => {
	// Sometimes urlQs (comes from server) includes the coupon code at the end like ('...CINNAMON TWISTS#cc_4b266952')

	const queryParams = queryString.parse(queryParamsString)
	const { logo: img, title, ...restQueryParams } = queryParams
	const newQueryParams = img
		? {
				img,
				title: title || getTranslatedTextByKey('webviewFlow.specialOffer', 'Special Offer!'),
				...restQueryParams,
		  }
		: { title: title || getTranslatedTextByKey('webviewFlow.specialOffer', 'Special Offer!'), ...restQueryParams }
	const newQueryParamsPath = new URLSearchParams(newQueryParams).toString()
	return `${newQueryParamsPath}${couponCode ? `&${couponCode}` : ''}`
}
