import WithGuard from 'HOCs/withGuard'
import ButtonBase from 'components/common/ButtonBase'
import { useStores } from 'hooks/useStores'
import { inject, observer } from 'mobx-react'
import type _Account from 'mobx/Account'
import { CheckCouponOn } from 'mobx/CouponFlow'
import CouponError, { ErrorCode } from 'mobx/Coupons/errors'
import type _Infra from 'mobx/Infra'
import type _AddressManager from 'mobx/AddressManager'
import { Guard } from 'mobx/Infra/Infra.type'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import { isInStore, OrderType, type Coupon } from 'types/Coupons'
import type { JustOneProp } from 'utils/types'
import { getTranslatedTextByKey } from 'utils/utils'

interface ElementProps {
	disabled?: boolean
	onClick?: React.MouseEventHandler<HTMLButtonElement>
}

type ElementType = React.FC<React.PropsWithChildren<ElementProps>>

export enum ButtonType {
	UNAPPLY = 'unapply',
	APPLY = 'apply',
	VIEW = 'view',
	SAVE_AND_APPLY = 'saveAndApply',
	SIGN_IN_SAVE_AND_APPLY = 'saveSignInAndApply',
	SAVE_AND_CLOSE = 'saveAndClose',
	SIGN_IN_AND_APPLY = 'signInToApply',
	SIGN_IN_AND_VIEW = 'signInToView',
	NONE = 'none',
}

export enum Origin {
	CARD,
	MODAL,
}

interface Stores {
	Account?: typeof _Account
	Infra?: typeof _Infra
	AddressManager?: typeof _AddressManager
}

interface BaseProps extends Stores {
	coupon: Coupon
	disabled?: boolean
	Element?: ElementType
	onError: (error: CouponError, actions: Record<ButtonType, () => Promise<void>>) => void
	onApply?: (coupon: Coupon) => void
}

type MutuallyExclusiveProps = JustOneProp<{
	origin: Origin
	variant: ButtonType
}>

interface ButtonDescriptor {
	text: string
	callback: (event: React.MouseEvent<HTMLButtonElement>) => Promise<unknown>
}

type Props = BaseProps & MutuallyExclusiveProps

const Button = styled(ButtonBase)`
	display: flex;

	padding: 0.5rem 3rem;
`

const translationKey = 'eCommerce.coupons.modal'

const CouponButton: React.FC<Props> = ({
	coupon,
	onError,
	onApply,
	disabled = false,
	Element = Button,
	Infra,
	Account,
	AddressManager,
	...variantOrOrigin
}) => {
	const walletEnabled = Infra?.hasFeatureFlag('wallet')
	const { couponsStore, couponFlowStore } = useStores()

	const addToWallet = useCallback(async (): Promise<Coupon> => {
		if (!walletEnabled) {
			console.debug(`[${addToWallet.name}] Exiting early because feature flag is disabled`)
			return coupon
		}

		console.debug(`[${addToWallet.name}] Proceeding to add ${coupon.code} to wallet`)

		const couponToApply = await couponsStore.addCouponToWallet(coupon.code)
		Infra?.showSnackbar({
			status: 'success',
			message: getTranslatedTextByKey('eCommerce.coupons.snackbar.addedToWallet', 'The coupon was added to your wallet'),
			key: 'coupon-added-to-wallet',
			snackId: 'coupon-added-to-wallet',
			isAttachedToElement: false,
		})

		console.debug(`[${addToWallet.name}] Successfully added ${coupon.code} to wallet`)

		return couponToApply
	}, [Infra, coupon, couponsStore, walletEnabled])

	const apply = async (couponToApply: Coupon): Promise<boolean> => {
		onApply?.(couponToApply)

		return variantOrOrigin.origin === Origin.CARD
			? couponFlowStore.start({
					code: couponToApply.code,
					openModal: false,
					checkCouponOn: AddressManager?.isUserLocalized() ? CheckCouponOn.STORE : CheckCouponOn.CHAIN,
					orderTypeToForce: null,
			  })
			: couponFlowStore.applyFromCouponModal(couponToApply)
	}

	const openModal = () => Promise.resolve(couponsStore.setCouponModal(coupon))

	const buttonsMap: Record<ButtonType, ButtonDescriptor> = {
		[ButtonType.UNAPPLY]: {
			text: 'Unapply',
			callback: async () => {
				await couponFlowStore.unapply(coupon)
				Infra?.showSnackbar({
					status: 'success',
					message: getTranslatedTextByKey('eCommerce.coupons.snackbar.unapplied', 'The coupon was removed from your cart'),
					key: 'coupon-unapplied',
					snackId: 'coupon-unapplied',
					isAttachedToElement: false,
				})
			},
		},
		[ButtonType.APPLY]: { text: 'Apply', callback: () => apply(coupon) },
		[ButtonType.VIEW]: { text: 'View', callback: openModal },
		[ButtonType.SAVE_AND_APPLY]: { text: 'Save & Apply', callback: () => addToWallet().then(apply) },
		[ButtonType.SAVE_AND_CLOSE]: {
			text: 'Save & Close',
			callback: async () => {
				couponFlowStore.clearCouponToApply()
				await addToWallet()
				return couponsStore.setCouponModal(null)
			},
		},
		[ButtonType.SIGN_IN_AND_VIEW]: {
			text: 'Sign In to View',
			callback: openModal,
		},
		[ButtonType.SIGN_IN_AND_APPLY]: { text: 'Sign In to Apply', callback: () => apply(coupon) },
		[ButtonType.SIGN_IN_SAVE_AND_APPLY]: {
			text: 'Sign In to Save & Apply',
			callback: () => addToWallet().then(apply),
		},
		[ButtonType.NONE]: { text: '', callback: async () => {} },
	}

	const variant = 'variant' in variantOrOrigin ? variantOrOrigin.variant : null
	const origin = 'origin' in variantOrOrigin ? variantOrOrigin.origin : null

	const getButtonType = (): ButtonType => {
		const signIn = !Account?.isAuthenticated() && coupon.flags.requireLogin?.value
		const save = walletEnabled && !coupon.flags.wallet?.value
		const view = isInStore(coupon)
		const modal = origin === Origin.MODAL
		const unapply = coupon.flags.applied?.value

		if (unapply) {
			return ButtonType.UNAPPLY
		}

		if (view) {
			// is in store coupon – view only; we don't know if it's modal or card
			if (signIn) {
				// if require sign in;
				if (save) {
					// not in wallet - can only be modal
					return ButtonType.SIGN_IN_AND_VIEW
				}

				// in wallet - can be both
				return modal ? ButtonType.SIGN_IN_AND_VIEW : ButtonType.VIEW
			}

			if (save) {
				// if not requires sign in
				// not in wallet - can only be modal
				// we don't need to display any button becaues coupon is already viewd
				return ButtonType.NONE
			}
			// in wallet - can be both
			return modal ? ButtonType.NONE : ButtonType.VIEW
		}

		if (signIn) {
			// is not in store coupon - apply only; we don't know if it's modal or card
			// requires sign in
			if (save) {
				// not in wallet - can only be modal
				return ButtonType.SIGN_IN_SAVE_AND_APPLY
			}

			// in wallet - can be both. In card, it's just Apply, in Modal it's Sign In & Apply
			return modal ? ButtonType.SIGN_IN_AND_APPLY : ButtonType.APPLY
		}

		if (save) {
			// doesn't require sign in
			// not in wallet - can only be modal
			return ButtonType.SAVE_AND_APPLY
		}
		// in wallet - can be both, but since doesn't require sign in or save, can only be apply
		return ButtonType.APPLY
	}

	const buttonType: ButtonType = variant ?? getButtonType()

	const renderButton = (type: ButtonType) =>
		type === ButtonType.NONE ? null : (
			<Element
				disabled={disabled}
				onClick={(event) => {
					event.stopPropagation()
					const { callback } = buttonsMap[type]
					callback(event)
						.then((success) => {
							if (!success) {
								throw new CouponError('An error occured during apply', ErrorCode.DEFAULT)
							}
						})
						.catch((error) =>
							onError(
								error,
								// Actions to be executed from parent
								(Object.keys(buttonsMap) as ButtonType[]).reduce(
									(acc, curr) => ({ ...acc, [curr]: buttonsMap[curr].callback }),
									{} as Record<ButtonType, () => Promise<void>>
								)
							)
						)
				}}
				data-testid="coupon-apply-button"
			>
				{getTranslatedTextByKey(`${translationKey}.${type}`, buttonsMap[type].text)}
			</Element>
		)

	return coupon.flags.requireLogin?.value ? (
		<WithGuard context={Guard.Context.wallet} type={Guard.Type.event} name={Guard.Name.applyCoupon}>
			{renderButton(buttonType)}
		</WithGuard>
	) : (
		renderButton(buttonType)
	)
}

type ExportedProps = Omit<BaseProps, keyof Stores> & MutuallyExclusiveProps

export default inject('Infra', 'Account', 'AddressManager')(observer(CouponButton)) as React.FC<ExportedProps>
